import { ReactNode } from 'react';

import { PlaneoDashboardNavbar } from '../navigation-bar';
import { PlaneoDashboardSideMenu } from '../side-menu';
import { PlaneoDashboardContent } from '../content';
import { useHook } from './hook';

import './styles.scss';

export const PlaneoDashboard = ({ children }: { children: ReactNode }): JSX.Element => {
	const hook = useHook();
	return (
		<div id='planeo-dashboard'>
			{hook.submenuVisible && <div className='planeo-dashboard-overlap' onClick={hook.toogleSubmenu}></div>}
			<PlaneoDashboardNavbar
				isMenuIconVisible={hook.isMenuVisible}
				isSubmenuVisible={hook.submenuVisible}
				setIsSubmenuVisible={hook.setSubmenuVisible}
				onMenuIconClicked={hook.toogleSidemenu}></PlaneoDashboardNavbar>

			<div className='planeo-dashboard-main-content'>
				<PlaneoDashboardSideMenu isHidden={!hook.isMenuVisible} isExpanded={hook.isMenuExpanded} />
				<PlaneoDashboardContent>{children}</PlaneoDashboardContent>
			</div>
		</div>
	);
};
