import fbase from 'firebase/app';

export const setUserLoginRetries = (firebase: fbase.app.App) => {
	return async (userEmail: string, count: number): Promise<void> => {
		const userSnapshot = await firebase.firestore().collection('users').where('email', '==', userEmail).get();

		if (userSnapshot.size === 0) return;

		await firebase.firestore().collection('users').doc(userSnapshot.docs[0].id).set(
			{
				loginRetries: count,
			},
			{ merge: true }
		);
	};
};
