import * as yup from 'yup';

export interface SignUpFormModel {
	companyName: string;
	nit: number;
	dv: number;
	phoneNumber: number;
	companyEmail: string;
	password: string;
	passwordConfirm: string;

	files: any[];

	termsAndConds: boolean;
	habeasData: boolean;
}

export const SignUpFormSchema = yup.object<Record<keyof SignUpFormModel, yup.AnySchema>>({
	companyName: yup.string().trim().required('Campo obligatorio').min(3, 'Ingresa un nombre válido'),

	nit: yup.number().required('Campo obligatorio').typeError('NIT campo obligatorio').min(100000000, 'NIT no válido').max(999999999, 'NIT no válido'),

	dv: yup.number().required('Campo obligatorio').typeError('DV campo obligatorio').min(0, 'DV no válido').max(9, 'DV no válido'),

	phoneNumber: yup
		.number()
		.required('Campo obligatorio')
		.typeError('El teléfono debe ser un valor numérico')
		.min(1000000, 'Teléfono no válido')
		.max(10000000000, 'Teléfono no válido'),

	companyEmail: yup.string().email('Ingresa un correo válido').required('Campo obligatorio'),

	password: yup
		.string()
		.required('Campo obligatorio')
		.min(8, 'La contraseña es demasiado corta')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, 'la contraseña no es válida'),

	passwordConfirm: yup.string().test('passwords-match', 'Las contraseñas no coinciden', function (value) {
		return this.parent.password === value;
	}),

	termsAndConds: yup.boolean().oneOf([true], 'Debes aceptar términos y condiciones').required('Campo obligatorio'),

	habeasData: yup.boolean().oneOf([true], 'Debes aceptar el tratamiento de datos').required('Campo obligatorio'),

	files: yup
		.array()
		.transform((x) => x.filter((y) => !y.errors || y.errors.length === 0))
		.min(1, 'Debes seleccionar al menos 1 archivo válido'),
});
