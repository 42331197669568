import { Suspense } from 'react';
import firebase from 'firebase';
import ReactDOM from 'react-dom';
import { FirebaseAppProvider } from 'reactfire';
import reportWebVitals from './reportWebVitals';

import environments from '@planeo-environments';
import App from './application';

// Common and Global Styling
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-datasheet-grid/dist/style.css';
import './Styles/index.scss';
import './Styles/global.scss';
import 'react-toastify/dist/ReactToastify.css';

const app = firebase.initializeApp(environments.firebaseConfig);

if (environments.emulators.functions.enabled) {
	app.functions().useEmulator(environments.emulators.functions.host, environments.emulators.functions.port);
}

if (environments.emulators.storage.enabled) {
	app.storage().useEmulator(environments.emulators.storage.host, environments.emulators.storage.port);
	app
		.storage('gs://' + environments.firebaseConfig.payrollBucket + '/')
		.useEmulator(environments.emulators.storage.host, environments.emulators.storage.port);
}

if (environments.emulators.auth.enabled) {
	app.auth().useEmulator(environments.emulators.auth.url);
}

if (environments.emulators.firestore.enabled) {
	app.firestore().settings({
		host: `${environments.emulators.firestore.host}:${environments.emulators.firestore.port}`,
		ssl: false,
	});
}

ReactDOM.render(
	<FirebaseAppProvider firebaseConfig={environments.firebaseConfig}>
		<Suspense fallback={'Conectando a la App...'}>
			<App />
		</Suspense>
	</FirebaseAppProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
