import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DefaultImage from '@planeo-media/img_AnalisRapido.svg';

import './styles.scss';

export const CompanyNotVerifiedPage = (props): JSX.Element => {
	return (
		<div className='full-page-container'>
			<div className='NotValidate-Container'>
				<img className='not-validate-Image' src={props.image ?? DefaultImage} alt='Upload Logo' />
				<h2 className=' not-validate-Title'>{!!props.title ? props.title : '!Cuenta no aprobada!'}</h2>
				<p className='not-validate-Text'>{props.text ?? ''} </p>
				<Link to='/' className='link'>
					<Button className='Button-Primary' variant='outline-primary ' type='submit'>
						{props.buttonText ?? 'VOLVER AL HOME'}
					</Button>
				</Link>
			</div>
		</div>
	);
};
