import { PlaneoLoader } from '@planeo-components/loader';
import { ChangePasswordPartial } from '../partials/change-password';
import { VerifyEmailPartial } from '../partials/verify-email';
import { useHook } from './hook';

export const PassWordLandingPage = (): JSX.Element => {
	const hook = useHook();

	return (
		<>
			{hook.viewMode === null && <PlaneoLoader />}

			{hook.viewMode === 'verify' && <VerifyEmailPartial />}

			{hook.viewMode === 'recovery' && <ChangePasswordPartial />}
		</>
	);
};
