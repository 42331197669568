import { useFirebaseApp, useUser } from 'reactfire';
import { useEffect, useState } from 'react';
import firebase from 'firebase';

import { StorageService, FirebaseStorageService } from '@planeo-services/storage';

export const useHook = () => {
	const { data: user } = useUser();
	const app = useFirebaseApp();
	const [finalTime, setFinalTime] = useState(0);

	StorageService.configure(FirebaseStorageService, app);

	useEffect(() => {
		setFinalTime(Number(localStorage.getItem('sessionTime')));
	}, []);

	useEffect(() => {
		function detectMouse() {
			setFinalTime(Number(localStorage.getItem('sessionTime')));
		}

		window.addEventListener('mousemove', detectMouse);

		return () => {
			window.removeEventListener('mousemove', detectMouse);
		};
	}, []);

	useEffect(() => {
		if (finalTime >= 0) {
			const timeout = setTimeout(() => {
				setFinalTime((prevState) => prevState - 1);

				if (finalTime < 1) {
					localStorage.removeItem('TokenAPI');
					firebase.auth().signOut().catch(()=>{});
				}
			}, 60000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [finalTime]);

	return {
		user,
	};
};
