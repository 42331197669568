import fbase from 'firebase/app';
import { getUserLoginRetries } from './get-user-login-retries';
import { setUserLoginRetries } from './set-user-login-retries';

export interface UsersLogic {
	getUserLoginRetries: (userEmail: string) => Promise<number>;
	setUserLoginRetries: (userEmail: string, count: number) => Promise<void>;
}

export const useUsersLogic = (firebase: fbase.app.App): UsersLogic => {
	return {
		getUserLoginRetries: getUserLoginRetries(firebase),
		setUserLoginRetries: setUserLoginRetries(firebase),
	};
};
