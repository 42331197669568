//#region Dependencias
import { AlertDialogService } from '@planeo-components/alerts-dialog';
//#endregion

export const deleteAccountConfirm = async () => {
	const isConfirmed = await AlertDialogService.confirm({
		title: 'Confirmación eliminar cuenta',
		message: (
			<>
				<span>Tu cuenta se eliminá permanentemente</span>
				<br />
				<span>¿Deseas continuar?</span>
			</>
		),
	});

	if (!isConfirmed) return false;

    return true;
};