import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { logo_white_small, login_background } from '@planeo-assets';
import { useHook } from './hook';

import './styles.scss';

export type PasswordRecoveryModalProps = {
	visible: boolean;
	onHide: (showLogin?: boolean) => void;
};

export const PasswordRecoveryModal = (props: PasswordRecoveryModalProps): JSX.Element => {
	const hook = useHook(props);

	return (
		<Modal show={props.visible} centered id='planeo-password-recovery-modal' onHide={() => props.onHide(false)}>
			<div className='main-logo' style={{ backgroundImage: `url(${login_background})` }}>
				<img src={logo_white_small} alt='Planeo logo' />
			</div>

			<Form onSubmit={hook.handleSubmit(hook.onSubmit)} className='recovery-form'>
				<Form.Group className='text-centered'>
					<span className='font-20 title'>¿Olvidaste tu contraseña?</span>

					<p className='font-15'>
						Ingresa el correo electrónico con el que estás registrado, te enviaremos instrucciones para restablecer tu contraseña.
					</p>
				</Form.Group>

				<Form.Group>
					<Form.Label>Correo empresarial *</Form.Label>
					<Form.Control {...hook.register('email')} className={hook.errors.email && 'validation-error'} type='text' placeholder='' />
					<Form.Text>{hook.errors.email?.message}</Form.Text>
				</Form.Group>

				<Form.Group className='buttons-group'>
					<Button className='btn-outline' type='submit' disabled={!hook.isValid}>
						{hook.isSubmiting ? <Spinner className='spinner' animation='border' role='status' /> : 'ENVIAR'}
					</Button>

					<Button className='btn-basic' type='button' onClick={() => props.onHide(true)}>
						Cancelar
					</Button>
				</Form.Group>
			</Form>
		</Modal>
	);
};
