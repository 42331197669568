import { IEnvironments } from './interface-env';

export const envsBase = function (firebaseConfig?: any): IEnvironments {
	return {
		isProduction: true,
		firebaseConfig: {
			apiKey: firebaseConfig?.apiKey ?? process.env.REACT_APP_FIREBASE_APIKEY,
			authDomain: firebaseConfig?.authDomain ?? process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
			databaseURL: firebaseConfig?.databaseURL ?? process.env.REACT_APP_FIREBASE_DATABASEURL,
			projectId: firebaseConfig?.projectId ?? process.env.REACT_APP_FIREBASE_PROJECTID,
			storageBucket: firebaseConfig?.storageBucket ?? process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
			messagingSenderId: firebaseConfig?.messagingSenderId ?? process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
			appId: firebaseConfig?.appId ?? process.env.REACT_APP_FIREBASE_APPID,
			measurementId: firebaseConfig?.measurementId ?? process.env.REACT_APP_FIREBASE_MEASUREMENTID,
			payrollBucket: firebaseConfig?.payrollBucket ?? process.env.REACT_APP_FIREBASE_PAYROLLBUCKET,
			captcha: firebaseConfig?.captcha ?? process.env.REACT_APP_CAPTCHA,
			amplitude_api_key: firebaseConfig?.amplitude_api_key ?? process.env.AMPLITUDE_API_KEY,
			push_notifications: firebaseConfig?.push_notifications ?? process.env.PUSH_NOTIFICATIONS,
			urlPlaneoRedireccion : firebaseConfig?.urlPlaneoRedireccion ?? process.env.URLPLANEOREDIRECCION,
			urlPlaneoApiNewDocuments : firebaseConfig?.urlPlaneoApiNewDocuments ?? process.env.URLPLANEO_NEW_DOCUMENTS,
			urlPlaneoApiTags : firebaseConfig?.urlPlaneoApiTags ?? process.env.URLPLANEOTAGS,
			urlPlaneoApiUsersManagement : firebaseConfig?.urlPlaneoApiUsersManagement ?? process.env.URLPLANEOUSERSMANAGEMENT,
			urlPlaneoApiUsers : firebaseConfig?.urlPlaneoApiUsers ?? process.env.URLPLANEOUSERS
		},

		emulators: {
			functions: {
				enabled: false,
				host: 'localhost',
				port: 5001,
			},
			storage: {
				enabled: false,
				host: 'localhost',
				port: 9199,
			},
			auth: {
				enabled: false,
				url: 'http://localhost:9099',
			},
			database: {
				enabled: false,
				host: 'localhost',
				port: 9000,
			},
			firestore: {
				enabled: false,
				host: 'localhost',
				port: 8080,
			},
		},
	};
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { ...envsBase() } as IEnvironments;
