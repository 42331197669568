//#region Dependencias
import { useStateValue } from '@planeo-providers/Context/store';
import NotificationsAPI from '@planeo-services/api/functions/NotificationsAPI';
import { ModalVisorNotificationsFilesDto } from '@planeo-services/api/models/notificationsInterface';
import { Button, Col, Container, Form, Image, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useEffect, useState } from 'react';
import { GestionEstadoNotasModel } from '@planeo-services/api/models/documentsModelsInterface';
import { useCompanyContext } from '@planeo-providers/company-provider';
import DocumentsAPI from '@planeo-services/api/functions/DocumentsAPI';
import { useFirebaseApp } from 'reactfire';
import { useHistory } from 'react-router-dom';
import { track, setUserId } from '@amplitude/analytics-browser';
import { AlertDialogService } from '@planeo-components/alerts-dialog';
import RefactoringAPI from '@planeo-services/api/functions/RefactoringAPI';
//#endregion

const ModalVisorArchivos = () => {
    //#region Hooks
	const {state,dispatch} = useStateValue();
    const {modalVisorArchivosNotifications} = state?.sesionNotifications || {};
    const {abrir,fileName, urlFile, fileId, optionalNote, stateFile} = modalVisorArchivosNotifications || {};
    //#endregion

    //#region Hooks
	const firebase = useFirebaseApp();
	const history = useHistory();
	//#endregion

    //#region Reducers
    const { company } = useCompanyContext();
    //#endregion

    //#region useState
    const [notaOpcional, setNotaOpcional] = useState(optionalNote);
    const [loadingModal, setLoadingModal] = useState(false);
    //#endregion

    useEffect(() => {
        if(optionalNote){
            setNotaOpcional(optionalNote);
        }
    }, [abrir]);
    
    //#region Funciones
    const FnCerrarModal = () => {
        let body : ModalVisorNotificationsFilesDto = {
            abrir : false,
            fileName : '',
            urlFile : '',
            fileId : '',
            optionalNote : '',
            stateFile : 0
        }

        NotificationsAPI.Fn_AbrirVisorArchivos(body, dispatch);
    }

    const FnGestionDocumento = async (stateFile: number) => {
        setLoadingModal(true);

		let body : GestionEstadoNotasModel = {
			id : fileId!,
			optionalNote : notaOpcional,
			reviewer : company?.adminUser.email,
			stateFile
		}

		const resultado = await DocumentsAPI.FnActualizarEstadoArchivo(body, firebase, history, dispatch);
		if(resultado){
            setTimeout(async () => {
                await AlertDialogService.alert({
                    title: 'Notificación del sistema',
                    message: 'El estado del archivo ha sido modificado correctamente'
                });
            }, 1200);

            NotificationsAPI.Fn_ListarNotificaciones(company.id, 20, 1, dispatch, firebase, history, company.adminUser.email, true);
			track('MODAL REVISION DOCUMENTO - ' + (stateFile === 1 ? 'APROBAR DOCUMENTO' : 'RECHAZAR DOCUMENTO'));
			setUserId(company?.adminUser?.email);
        }

        FnCerrarModal();
        setLoadingModal(false);
    }
    //#endregion

    //#region Render
    return (
        <Modal 
            show={!!abrir}
            centered 
            dialogClassName="custom-modal3"
            onHide={() => FnCerrarModal()}
        >
            <Modal.Header
                closeButton
            >
                <Modal.Title style={{display: 'flex', gap: 10}}>
                    Visor documentos - {fileName}
                    {
                        stateFile > 0
                        &&
                        <div style={{background: stateFile === 1 ? 'green' : '#D60000', height: 20, marginTop: 10, width: 100, paddingTop: 1, paddingBottom: 1, paddingLeft: 5, paddingRight: 5, textAlign: 'center',color: '#FFF',fontSize: 13, borderRadius: 10}}>
                            {stateFile === 1 ? 'Aprobado' : 'Rechazado'}
                        </div>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='document-preview'>
                {
                    RefactoringAPI.Fn_ObtenerExtension(fileName) === 'jpg' || RefactoringAPI.Fn_ObtenerExtension(fileName) === 'png' || RefactoringAPI.Fn_ObtenerExtension(fileName) === 'jpeg' || RefactoringAPI.Fn_ObtenerExtension(fileName) === 'tiff'
                    ?
                    <Image
                        src={urlFile}
                        style={{objectFit: 'scale-down', height: '100%', width: '100%'}}
                        alt='Imagen Iframe'
                        rounded
                        fluid
                    />
                    :
                    <iframe
                        width={'100%'}
                        height={'100%'}
                        className='frame'
                        title='Previsualización de documentos'
                        src={urlFile}
                    ></iframe>
                }
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col md={8}>
                            <Form.Control
                                id='notaOpcional'
                                type='text'
                                as='textarea'
                                rows={2}
                                maxLength={200}
                                autoComplete='nope'
                                style={{
                                    border: '1px solid #0000001F'
                                }}
                                placeholder='Nota (opcional)'
                                defaultValue={optionalNote}
                                onChange={(e)=> setNotaOpcional(e.currentTarget.value)}
                            />
                        </Col>
                        <Col md={4}>
                            {
                                loadingModal
                                ?
                                <div className='spinner-container2' style={{marginTop: 15}}>
                                    <Spinner color='primary' animation='border' className='spinner2' role='status' />
                                </div>
                                :
                                <>
                                    <Button className='btn-outline-danger' style={{marginRight: 15}} onClick={()=> FnGestionDocumento(2)}>Rechazar</Button>
                                    <Button className='btn-outline-success' onClick={()=> FnGestionDocumento(1)}>Aprobar</Button>	
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    )
    //#endregion
}

export default ModalVisorArchivos;