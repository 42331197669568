const RefactoringAPI = {
    //#region Fn_ObtenerExtension
    Fn_ObtenerExtension : (nombreArchivo: string) => {
        if(nombreArchivo?.length > 0){
            return nombreArchivo?.split('.')?.pop()?.toLowerCase();
        }
        return '';
    },
    //#endregion
}

export default RefactoringAPI;