//#region Dependencias
import { NotificationsList } from "@planeo-services/api/models/notificationsInterface";
import { Action_Notifications } from "../ActionTypes/NotificacionesActions";
//#endregion

const sesionNotificationsReducer = (state: NotificationsList, action: Action_Notifications) : NotificationsList => {
    switch(action.type){
        case "LIST_NOTIFICATIONS":
            return {
                ...state,
                listNotifications : action.payload
            };
        case "MODAL_NOTIFICATIONS":
            return {
                ...state,
                modalNotificaciones : action.payload
            };
        case "COUNT_NOTIFICATIONS":
            return {
                ...state,
                countNotifications : action.payload
            };
        case "LOADING_NOTIFICATIONS":
            return {
                ...state,
                loadingNotifications : action.payload
            };
        case "COUNT_NOTIFICATIONS_TOTAL":
            return {
                ...state,
                countNotificationsTotal : action.payload
            };
        case "MODAL_FILES_VISOR_NOTIFICATIONS":
            return {
                ...state,
                modalVisorArchivosNotifications : action.payload
            };
        case "LIST_NOTIFICATIONS_HISTORY":
            return {
                ...state,
                listNotificationsHistory : action.payload
            };
        case "LOADING_NOTIFICATIONS_HISTORY":
            return {
                ...state,
                loadingNotificationsHistory : action.payload
            };
        default:
            return state;
    }
}

export default sesionNotificationsReducer;