import { useState } from 'react';

export const useHook = () => {
	const [needMailValidation, setneedMailValidation] = useState<boolean>(false);

	return {
		needMailValidation,
		setneedMailValidation,
	};
};
