import { Button, Form, Spinner } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

import { FileUploaderField, UploaderFilesInfoList } from '@planeo-components/file-uploader';
import usePasswordToggle from '@planeo-components/usePassWordToggle';
import PopOverInfo from '@planeo-components/PopOverInfo';

import { useHook } from './hook';

import './styles.scss';

export type FormSectionProps = {
	onTermsAndCondsClicked: () => void;
	onHabeasDataClicked: () => void;
};

export const FormSection = (props: FormSectionProps) => {
	const acceptedFileTypes = ['image/*', '.pdf'];
	const [InfoIcon] = PopOverInfo('Crear una contraseña segura de 8 caracteres como mínimo, con combinación entre mayúsculas, minúsculas y números');
	const [InputType, IconType] = usePasswordToggle();

	const hook = useHook();

	return (
		<div className='planeo-signup-page-form'>
			<h2>
				Registro <strong>Empresarial</strong>
			</h2>

			<div>
				<p>
					<strong className='font-18'>Diligencia el siguiente formulario para crear tu cuenta y tener acceso a todos los servicios de Planeo</strong>
				</p>
			</div>

			<hr />

			<Form onSubmit={hook.handleSubmit(hook.onSubmit)}>
				<Form.Group>
					<Form.Label>Nombre de la empresa *</Form.Label>
					<Form.Control {...hook.register('companyName')} className={hook.errors.companyName && 'validation-error'} type='text' placeholder='' />
					<Form.Text>{hook.errors.companyName?.message}</Form.Text>
				</Form.Group>

				<Form.Group>
					<div className='company-document-field'>
						<div>
							<Form.Label>NIT *</Form.Label>
							<Form.Control {...hook.register('nit')} className={hook.errors.nit && 'validation-error'} type='number' placeholder='' />
						</div>

						<div>
							<Form.Label>DV *</Form.Label>
							<Form.Control
								{...hook.register('dv')}
								className={hook.errors.dv && 'validation-error'}
								type='number'
								placeholder=''
								min='0'
								max='9'
							/>
						</div>
					</div>
					<Form.Text>{hook.errors.nit ? hook.errors.nit?.message : hook.errors.dv?.message}</Form.Text>
				</Form.Group>

				<Form.Group>
					<Form.Label>Teléfono de contacto *</Form.Label>
					<Form.Control {...hook.register('phoneNumber')} type='number' className={hook.errors.phoneNumber && 'validation-error'} placeholder='' />
					<Form.Text>{hook.errors.phoneNumber?.message}</Form.Text>
				</Form.Group>

				<Form.Group>
					<Form.Label>Correo empresarial *</Form.Label>
					<Form.Control {...hook.register('companyEmail')} type='text' className={hook.errors.companyEmail && 'validation-error'} placeholder='' />
					<Form.Text>{hook.errors.companyEmail?.message}</Form.Text>
				</Form.Group>

				<Form.Group className='input-icon'>
					<Form.Label>Contraseña *</Form.Label>
					<Form.Control
						{...hook.register('password')}
						type={String(InputType)}
						className={hook.errors.password && 'validation-error'}
						placeholder=''
						autoComplete='off'
					/>
					<span className='icon'>{InfoIcon}</span>
					<Form.Text>{hook.errors.password?.message}</Form.Text>
				</Form.Group>

				<Form.Group className='input-icon'>
					<Form.Label>Confirmar contraseña *</Form.Label>
					<Form.Control
						{...hook.register('passwordConfirm')}
						type={String(InputType)}
						className={hook.errors.passwordConfirm && 'validation-error'}
						placeholder=''
						autoComplete='off'
					/>
					<span className={'icon'}>{IconType}</span>
					<Form.Text>{hook.errors.passwordConfirm?.message}</Form.Text>
				</Form.Group>

				<Form.Group className='field-fullwidth'>
					<p>
						Para <strong>Planeo</strong> la seguridad de tu información es muy importante. Para validar la identidad de tu empresa debes
						adjuntar la <strong>cámara de comercio y la cédula del representante legal.</strong>
					</p>
				</Form.Group>

				<Form.Group className='field-fullwidth'>
					<Controller
						control={hook.control}
						name='files'
						render={({ field: { onChange } }) => (
							<FileUploaderField
								name='signature-field'
								filesType={acceptedFileTypes}
								onValueChange={onChange}
								default={hook.watch().files}
								multiple
								fromProvider='form-hook'
								isIconVisible>
								<FileUploaderField.Message>
									Suelta aquí tus documentos o <span className='link'>haz clic</span> para buscar en tu explorador
								</FileUploaderField.Message>

								<UploaderFilesInfoList />
							</FileUploaderField>
						)}></Controller>

					<Form.Text>{(hook.errors.files as any)?.message}</Form.Text>
				</Form.Group>

				<Form.Group className='field-fullwidth submit-field'>
					<div>
						<Form.Check type='checkbox' placeholder=''>
							<Form.Check.Input type='checkbox' {...hook.register('termsAndConds')} />
							<Form.Check.Label>
								Acepto{' '}
								<span onClick={props.onTermsAndCondsClicked} className='link'>
									términos y condiciones
								</span>
							</Form.Check.Label>
						</Form.Check>
						<Form.Text>{hook.errors.termsAndConds?.message}</Form.Text>

						<Form.Check type='checkbox' placeholder=''>
							<Form.Check.Input type='checkbox' {...hook.register('habeasData')} />
							<Form.Check.Label>
								Autorizo el{' '}
								<span onClick={props.onHabeasDataClicked} className='link'>
									tratamiento de datos personales
								</span>
							</Form.Check.Label>
						</Form.Check>

						<Form.Text>{hook.errors.habeasData?.message}</Form.Text>
					</div>
					<Button className='btn-outline' type='submit' disabled={hook.isSubmiting}>
						{hook.isSubmiting ? <Spinner animation='border' role='status' size='sm' /> : 'CREAR CUENTA'}
					</Button>
				</Form.Group>
			</Form>
		</div>
	);
};
