//#region Dependencias
import { Form } from 'react-bootstrap';
//#endregion

const CheckBoxDesign = ({
    titulo,
    id,
    onChange,
    valor,
    tamanio
}) => {
    //#region Render
    return (
        <Form.Group className="mb-2">
            <Form.Check
                type="checkbox"
                label={titulo}
                id={id}
                style={{
                    color: '#777777',
                    fontSize: tamanio
                }}
                onChange={onChange}
                value={valor}
                checked={valor}
            />
        </Form.Group>
    )
    //#endregion
}

export default CheckBoxDesign;