//#region Dependencias
import { ArrowBack } from '@material-ui/icons';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
//#endregion

const BtnVolver = () => {
    //#region Hooks
    const history = useHistory();
    //#endregion
    
    //#region Render
    return (
        <Button
            style={{
                fontWeight: 'bold',
                fontSize: "14px",
                height: '42px',
                width: '15%',
                marginTop: 20,
                backgroundColor: 'transparent',
                color: '#777777',
                border: 0
            }}
            onClick={()=>history.push('/')}
        >
            <ArrowBack className='arrow-back' style={{cursor: 'pointer', fontSize: '18px', marginRight: '5px'}} />
            Volver
        </Button>
    )
    //#endregion
}

export default BtnVolver;