//#region Dependencias
import { ActionsGeneral } from "@planeo-providers/Context/ActionTypes";
import {Dispatch} from 'react';
import { GetAssignTagByFolder_Post, GetAssignTagByUser_Post, GetListCategoriesByCompany_Get, GetListTagsByCategoriesAndCompanyUsers_Post, GetListTagsByCategoriesAndCompany_Post, GetListTagsByUser_Post, GetUnassignTagByFolder_Post, GetUnassignTagByUser_Post } from "../EndPoints/TagsEndPoints";
import { CategoryByCompanyDto, DocumentsListFoldersAndFilesSubfoldersMiniDto, TagsAsignarCategoriesModel, TagsAsignarUsuarioModel, TagsByCategoriesCompanyListModel, TagsByCategoriesDto, TagsSearchModel } from "../models/documentsModelsInterface";
import { modalTagsByFolder, tagsByCategoriesListDto } from "@planeo-providers/Context/Inicializadores/InitializerDocuments";
//#endregion

const TagsAPI = {
    //#region FnListarCategoriasPorEmpresa
    FnListarCategoriasPorEmpresa : (idEmpresa: string, dispatch: Dispatch<ActionsGeneral>, folder: DocumentsListFoldersAndFilesSubfoldersMiniDto | null, firebase: any, history: any, tipo : number) => {
        GetListCategoriesByCompany_Get(idEmpresa)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                const respuestaPeticion : CategoryByCompanyDto[] = data;
                dispatch({
                    type: 'LIST_CATEGORIES_BY_COMPANY',
                    payload: respuestaPeticion
                });

                dispatch({
                    type: 'OPEN_MODAL_CATEGORIAS_TAGS',
                    payload : {
                        open : true,
                        folderId: folder?.id!,
                        folderName: folder?.name!,
                        tipo
                    }
                });
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'OPEN_MODAL_CATEGORIAS_TAGS',
                payload : modalTagsByFolder
            });

            dispatch({
                type: 'LIST_CATEGORIES_BY_COMPANY',
                payload: []
            });

            dispatch({
                type: 'LOADING',
                payload : {
                    loading : false
                }
            });
            
            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }
        });
    },
    //#endregion
    //#region FnCatchTagsByCategory
    FnCatchTagsByCategory : async (err: any, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        dispatch({
            type: 'OPEN_MODAL_CATEGORIAS_TAGS',
            payload : modalTagsByFolder
        });

        dispatch({
            type: 'LIST_TAGS_BY_CATEGORY',
            payload: tagsByCategoriesListDto
        });

        dispatch({
            type: 'LOADING',
            payload : {
                loading : false
            }
        });
        
        if(err?.status === 401){
            await firebase.auth().signOut();
            localStorage.removeItem('verificationNotify');
            localStorage.removeItem('TokenAPI');
            history.push('/');
        }
    },
    //#endregion
    //#region FnListarTagsPorCategoriasFolders
    FnListarTagsPorCategoriasFolders : (body: TagsByCategoriesCompanyListModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        GetListTagsByCategoriesAndCompany_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                const respuestaPeticion : TagsByCategoriesDto[] = data;
                TagsAPI.FnListarTagsCategoriasRefactor(body, respuestaPeticion, dispatch);
            }
        })
        .catch(async (err) => {
            await TagsAPI.FnCatchTagsByCategory(err, dispatch, firebase, history);
        });
    },
    //#endregion
    //#region FnListarTagsCategoriasRefactor
    FnListarTagsCategoriasRefactor : (body: TagsByCategoriesCompanyListModel, respuestaPeticion: TagsByCategoriesDto[], dispatch: Dispatch<ActionsGeneral>) => {
        dispatch({
            type: 'LIST_TAGS_BY_CATEGORY',
            payload: {
                name : body?.name,
                items : respuestaPeticion
            }
        });
    },
    //#endregion
    //#region FnListarTagsPorCategoriasUsers
    FnListarTagsPorCategoriasUsers : (body: TagsByCategoriesCompanyListModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        GetListTagsByCategoriesAndCompanyUsers_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                const respuestaPeticion : TagsByCategoriesDto[] = data;
                TagsAPI.FnListarTagsCategoriasRefactor(body, respuestaPeticion, dispatch);
            }
        })
        .catch(async (err) => {
            await TagsAPI.FnCatchTagsByCategory(err, dispatch, firebase, history);
        });
    },
    //#endregion
    //#region FnAsignarTagByCarpeta
    FnAsignarTagByCarpeta : async (body: TagsAsignarCategoriesModel, firebase: any, history: any) => {
        return await GetAssignTagByFolder_Post(body)
        .then((respuesta) => {
            const {status} = respuesta;
            if(status === 200){
                return true;
            }
        })
        .catch(async (err) => {
            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }

            return false;
        })
    },
    //#endregion
    //#region FnDesasignarTagByCarpeta
    FnDesasignarTagByCarpeta : async (body: TagsAsignarCategoriesModel, firebase: any, history: any) => {
        return await GetUnassignTagByFolder_Post(body)
        .then((respuesta) => {
            const {status} = respuesta;
            if(status === 200){
                return true;
            }
        })
        .catch(async (err) => {
            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }

            return false;
        })
    },
    //#endregion
    //#region FnAsignarTagByUser
    FnAsignarTagByUser : async (body: TagsAsignarUsuarioModel, firebase: any, history: any) => {
        return await GetAssignTagByUser_Post(body)
        .then((respuesta) => {
            const {status} = respuesta;
            if(status === 200){
                return true;
            }
        })
        .catch(async (err) => {
            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }

            return false;
        })
    },
    //#endregion
    //#region FnDesasignarTagByUser
    FnDesasignarTagByUser : async (body: TagsAsignarUsuarioModel, firebase: any, history: any) => {
        return await GetUnassignTagByUser_Post(body)
        .then((respuesta) => {
            const {status} = respuesta;
            if(status === 200){
                return true;
            }
        })
        .catch(async (err) => {
            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }

            return false;
        })
    },
    //#endregion
    //#region FnListarTagsPorUsuario
    FnListarTagsPorUsuario : (body: TagsSearchModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        GetListTagsByUser_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                const respuestaPeticion : TagsByCategoriesDto[] = data;
                dispatch({
                    type: 'LIST_TAGS_BY_CATEGORY',
                    payload: {
                        name : body?.categoryName,
                        items : respuestaPeticion
                    }
                });
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'OPEN_MODAL_CATEGORIAS_TAGS',
                payload : modalTagsByFolder
            });

            dispatch({
                type: 'LIST_TAGS_BY_CATEGORY',
                payload: tagsByCategoriesListDto
            });

            dispatch({
                type: 'LOADING',
                payload : {
                    loading : false
                }
            });
            
            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }
        });
    },
    //#endregion
}

export default TagsAPI;