//#region Dependencias
import {useState} from 'react';
import LayoutLogin from '../Components/LayoutLogin';
import LoginCombinado from '../Components/LoginCombinado';
import RecuperarClaveSubScreen from '../Components/RecuperarClaveSubScreen';
//#endregion

const LoginScreen = () => {
    //#region useState
    const [recuperarContrasenaScreen, setrecuperarContrasenaScreen] = useState(false);
    //#endregion

    //#region Render
    return (
        <LayoutLogin
            componenteHijo={!recuperarContrasenaScreen ?<LoginCombinado setrecuperarContrasenaScreen={setrecuperarContrasenaScreen} /> : <RecuperarClaveSubScreen setrecuperarContrasenaScreen={setrecuperarContrasenaScreen} />}
        />
    )
    //#endregion
}

export default LoginScreen;