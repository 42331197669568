import { Dispatch, SetStateAction } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ImgAlert from '@planeo-media/Modal/Img_Alerta.svg';

import './styles.scss';

type LoginModalRetriesProps = {
	visible: boolean;
	setVisible: Dispatch<SetStateAction<boolean>>;
	titulo : string;
	subtitulo : string;
	accion : ()=> void;
	boton : string;
};

export const LoginModalRetries = (props: LoginModalRetriesProps): JSX.Element => {
	return (
		<Modal
			show={props.visible}
			centered
			className='modal-alert-message'
			onHide={() => {
				props.setVisible(false);
			}}>
			<Modal.Body>
				<div>
					<img src={ImgAlert} alt='Mail Logo' />
				</div>

				<div>
					<strong className='title'>{props.titulo}</strong>
					<p>{props.subtitulo}</p>
				</div>

				<div className='d-flex justify-content-center align-items-center '>
					<Button
						variant='outline-primary'
						type='button'
						className='btn Button-Primary h4'
						style={{
							fontWeight: 'bold',
							fontSize: "16px"
						}}
						onClick={() => {
							props.accion()
						}}>
						{props.boton}
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};
