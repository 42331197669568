//#region Dependencias
import {Form} from 'react-bootstrap';
//#endregion

const TextBoxDesign = ({
    titulo,
    id,
    cantidad,
    onChange,
    tipo,
    error,
    defaultValue
}) => {
    //#region Render
    return (
        <Form.Group>
            <Form.Label style={{fontSize:'12px', marginTop: 28, marginBottom:7}}>{titulo}</Form.Label>
            <Form.Control
                id={id}
                type={tipo}
                maxLength={cantidad}
                isInvalid={error ? true : false}
                onChange={onChange}
                autoComplete='nope'
                style={{
                    border: '1px solid #0000001F'
                }}
                defaultValue={defaultValue}
            />
            {
                error
                &&
                <Form.Control.Feedback type='invalid'>
                    {error}
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
  //#endregion
}

export default TextBoxDesign;