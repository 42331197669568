import { Col } from 'react-bootstrap';
import React, { Children, ReactNode } from 'react';
import { file_load_sgv } from '@planeo-assets';

import { useHookFormik } from './hook-formik';
import { FileUploaderProps } from './../defs';
import { UploaderFilesInfoBase, UploaderFilesProps } from './files-info-container';

import './styles.scss';
import { useHook } from './hook';

export const FileUploaderField = (props: FileUploaderProps): JSX.Element => {
	const formProvider = props.fromProvider ?? 'formik';
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const hook = formProvider === 'form-hook' ? useHook(props) : useHookFormik(props);
	const isIconVisible = props.isIconVisible ?? false;

	let _messageContent: any = undefined;
	let _filesContainer: any = undefined;

	Children.forEach(props.children, (child: any) => {
		if (child.type === FileUploaderField.Message) {
			_messageContent = child;
		}

		if (child.type.prototype instanceof UploaderFilesInfoBase) {
			_filesContainer = React.cloneElement<UploaderFilesProps>(child, {
				files: hook.files,
				onDeleteFile: hook.deleteFile,
				onDeleteAll: hook.deleteAllFiles,
			});
		}
	});

	return (
		<Col className='file-uploader-component'>
			<div className='file-uploader-dropzone-container' {...hook.getDropZoneRootProps()}>
				<input {...hook.getDropZoneInputProps()} />

				{isIconVisible && (
					<div>
						<img src={file_load_sgv} alt='Payroll upload icon' />
					</div>
				)}

				{_messageContent && <div>{_messageContent}</div>}
			</div>

			{_filesContainer && hook.files.length > 0 && <div className='file-uploader-info-container'>{_filesContainer}</div>}
		</Col>
	);
};

FileUploaderField.Message = (props: { children: ReactNode }) => <p>{props.children}</p>;
