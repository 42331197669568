const TextoAlternativoNegrilla = ({children, view, viewGlobal}) => {
    const parts = children?.replace('{{names}}', '')?.replace('.', '. ')?.replace(':', ': ')?.replace('{{peopleName}}', '')?.replace('**',' ')?.replace('* *', '* ').split('*');

    //#region Render
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer'
            }}
        >
            <div
                style={{
                    backgroundColor: view ? 'transparent':'red',
                    width: viewGlobal ? 5 : 20,
                    height: 5,
                    marginTop: 7,
                    marginLeft: -3,
                    marginRight: 3,
                    borderRadius: 100
                }}
            >
            </div>
            <label>
                {parts.map((part: string, index: number) => {
                    if (index % 2 === 0) {
                    return <label key={index} style={{color: '#404040', fontSize: 14, cursor: 'pointer'}}>{part}</label>;
                    } else {
                    return <b key={index} style={{color: '#404040', fontSize: 14, cursor: 'pointer'}}>{part}</b>;
                    }
                })}
            </label>
        </div>
    );
    //#endregion
}

export default TextoAlternativoNegrilla;