import { useState } from 'react';
import { BannerSection } from '../partials/banner-section';
import { FormSection } from '../partials/form-section';

import { LoginModal } from '../modals/login';
import { PasswordRecoveryModal } from '../modals/password-recovery';
import { ModalTermsAndConds } from '@planeo-components/modal-terms-and-conds';
import { ModalHabeasData } from '@planeo-components/modal-habeas-data';

import './styles.scss';

export const SignUpPage = (): JSX.Element => {
	const [loginVisible, setLoginVisible] = useState<boolean>(false);
	const [passwordRecoveryVisible, setPasswordRecoveryVisible] = useState<boolean>(false);

	const [termsAndCondsVisible, setTermsAndCondsVisible] = useState<boolean>(false);
	const [habeasDataVisible, setHabeasDataVisible] = useState<boolean>(false);

	return (
		<>
			<LoginModal
				visible={loginVisible}
				onHide={() => setLoginVisible(false)}
				onclickRecovery={() => {
					setLoginVisible(false);
					setPasswordRecoveryVisible(true);
				}}
			/>
			<PasswordRecoveryModal
				visible={passwordRecoveryVisible}
				onHide={(showLogin) => {
					setPasswordRecoveryVisible(false);
					setLoginVisible(showLogin ?? false);
				}}
			/>

			<ModalTermsAndConds visible={termsAndCondsVisible} onHide={() => setTermsAndCondsVisible(false)} />
			<ModalHabeasData visible={habeasDataVisible} onHide={() => setHabeasDataVisible(false)} />

			<div className='planeo-signup-page'>
				<BannerSection onLoginClicked={() => setLoginVisible(true)} />
				<FormSection onTermsAndCondsClicked={() => setTermsAndCondsVisible(true)} onHabeasDataClicked={() => setHabeasDataVisible(true)} />
			</div>
		</>
	);
};
