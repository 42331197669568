import { Button, Modal } from 'react-bootstrap';

import './styles.scss';

export type ModalHabeasDataProps = {
	visible: boolean;
	onHide: () => void;
};

export const ModalHabeasData = (props: ModalHabeasDataProps): JSX.Element => {
	const closeModal = () => {
		props.onHide();
	};

	return (
		<Modal id='planeo-habeas-data-modal' show={props.visible} centered size='lg' onHide={closeModal}>
			<Modal.Header>
				<Modal.Title>Tratamiento de datos personales</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<strong>POLÍTICA DE TRATAMIENTO DE DATOS DE ZAJANA S.A.S</strong>
				<br />
				<br />
				<strong>INTRODUCCIÓN</strong>
				<p>
					Zajana S.A.S (en adelante “Planeo”) sociedad legalmente constituida bajo las leyes de la República de Colombia, identificada con NIT. # 901.503.100-6, domiciliada en la ciudad de Bogotá D.C., y con dirección Calle 98 No. 22-64 Oficina 1016. En el marco de sus actividades, Planeo tiene el deber de dar cumplimiento a las normas de protección de datos que se describen a continuación.
				</p>
				<p>
					<ol>
						<li>
							La ley Estatutaria 1581 de 2012, por medio de la cual se dictan disposiciones generales con respecto a la protección de datos
							personales.
							<br /> Los principios y disposiciones de la ley 1581 de 2012 serán aplicables a los datos personales registrados en cualquier base de
							datos que los haga susceptibles de tratamiento por parte de Planeo.
							<br />
							El régimen de protección de datos personales contemplados se aplica al tratamiento de datos personales efectuado en el territorio
							colombiano.
						</li>
						<li>Constitución política de Colombia, Artículos 15, 20.</li>
						<li>
							Ley Estatutaria 1266 de 2008- Disposiciones generales del hábeas data y se regula el manejo de la información contenida en bases de
							datos personales, en especial la financiera, crediticia, comercial, de servicios y la proveniente de terceros países.
						</li>
						<li>Decreto 1377 de 2013- Reglamenta parcialmente la Ley 1581 de 2012.</li>
						<li>
							Decreto 886 de 2014- Registro Nacional de Bases de Datos.
							<ol type='I'>
								<br />
								<li>
									<strong>OBJETIVO</strong>
									<p>
										El presente documento tiene el objeto de establecer los criterios para la recolección, almacenamiento, uso, transferencia,
										circulación y supresión de los datos personales tratados por Planeo. En este sentido, Planeo ha dispuesto la presente política de
										tratamiento de datos personales (la “Política”) con el propósito de regular de manera clara y dar a conocer los aspectos
										relevantes con relación a los datos personales de la comunidad, incluyendo, pero sin limitarse a los empleados, proveedores,
										terceros y usuarios de su plataforma, quienes hayan otorgado autorización para el manejo de sus datos a Planeo.
									</p>
								</li>
								<br></br>
								<li>
									<strong>ALCANCE</strong>
									<p>
										Esta Política aplica para toda la información personal registrada en las bases de datos de Planeo, quien podrá actuar en calidad
										de responsable o encargado del tratamiento de los datos personales.
									</p>
								</li>
								<br></br>
								<li>
									<strong>OBLIGACIONES</strong>
									<p>Esta Política es de obligatorio y estricto cumplimiento para Planeo.</p>
								</li>
								<br></br>
								<li>
									<strong>DEFINICIONES</strong>
									<p>
										La Política tendrá las siguientes definiciones para su correcta interpretación y aplicación de acuerdo con la normatividad
										aplicable a la materia
									</p>
									<ol type='a'>
										<li>
											<strong>Autorización:</strong> Se refiere al consentimiento previo, expreso e informado del Titular para llevar a cabo el
											Tratamiento de datos personales.
										</li>
										<li>
											<strong>Autorización para consulta:</strong> La aceptación libre, informada e inequívoca de un USUARIO FINAL del tratamiento de sus datos personales para un propósito específico
										</li>
										<li>
											<strong>Consentimiento:</strong> Es la manifestación de la voluntad del titular de los datos personales mediante la cual puede realizar el tratamiento de sus datos personales, y que puede constar en soporte físico o digital en los términos de la Ley.
										</li>
										<li>
											<strong>Aviso de privacidad:</strong> Se refiere al documento físico, electrónico o en cualquier otro formato generado por
											Planeo que se pone a disposición del Titular para el tratamiento de sus datos personales. En el Aviso de Privacidad se comunica
											al Titular la información relativa a la existencia de las políticas de tratamiento de información que le serán aplicables, la
											forma de acceder a las mismas y la finalidad del tratamiento que se pretende dar a los datos personales
										</li>
										<li>
											<strong>Base de Datos:</strong> Se refiere al conjunto organizado de datos personales que sea objeto de Tratamiento;
										</li>
										<li>
											<strong>Datos De Menores De Edad:</strong> Se refiere al suministro de los datos personales de menores de edad es facultativo y
											debe realizarse con autorización de los padres de familia o representantes legales del menor, previo ejercicio del menor de su
											derecho a ser escuchado.
										</li>
										<li>
											<strong> Dato personal:</strong> Se refiere a cualquier información vinculada o que pueda asociarse a una o varias personas
											naturales determinadas o determinables;
										</li>
										<li>
											<strong>Dato público:</strong> Es el dato calificado como tal según los mandatos de la ley o de la Constitución Política y aquel
											que no sea semiprivado, privado o sensible. Son públicos, entre otros, los datos relativos al estado civil de las personas, a su
											profesión u oficio, a su calidad de comerciante o de servidor público y aquellos que puedan obtenerse sin reserva alguna. Por su
											naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y
											boletines oficiales;
										</li>
										<li>
											<strong>Dato privado:</strong> Es el dato que por su naturaleza íntima o reservada sólo es relevante para el titular;
										</li>
										<li>
											<strong>Datos sensibles:</strong> Se entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso
											indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política,
											las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que
											promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición,
											así como los datos relativos a la salud, a la vida sexual y los datos biométricos;
										</li>
										<li>
											<strong>Dato semiprivado:</strong> el dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no solo a su titular sino a cierto sector o grupo de personas o a la sociedad en general. Este tipo de datos requieren de autorización previa del titular para ser reportado (Ley 1266 de 2008, Art. 3°, Lit. “g” y Corte Constitucional, Sentencia C-1011 de 2008 (revisión de constitucionalidad de la Ley Estatutaria 1266 de 2008), punto “2.5)
										</li>
										<li>
											<strong>Encargado del Tratamiento:</strong> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con
											otros, realice el Tratamiento de datos personales por cuenta del Responsable del Tratamiento;
										</li>
										<li>
											<strong>Responsable del Tratamiento:</strong> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con
											otros, decida sobre la base de datos y/o el Tratamiento de los datos;
										</li>
										<li>
											<strong>Candidato/Usuario final:</strong> Es la persona a quién se le verifican los datos
										</li>
										<li>
											<strong>Titular:</strong> Persona natural cuyos datos personales sean objeto de Tratamiento;
										</li>
										<li>
											<strong>Tratamiento:</strong> Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección,
											almacenamiento, uso, circulación o supresión de los mismos.
										</li>
										<li>
											<strong>Identificadores:</strong> Son aquellos que permiten conocer el comportamiento de navegación de los visitantes y usuarios en las distintas versiones de Planeo, así como registrar los contenidos que ven y los que son de su interés. La finalidad del uso de estos identificadores es mejorar el servicio que se le ofrece a los usuarios
										</li>
									</ol>
									<p>
										Mediante avisos ubicados las sedes, se informa a trabajadores y a visitantes que están siendo grabados. El sistema es utilizado
										únicamente para garantizar la seguridad de los bienes, instalaciones y personas que se encuentren en éstas. Esta información puede
										ser empleada como prueba en cualquier tipo de proceso ante autoridades administrativas o judiciales con sujeción y cumplimiento de
										las normas aplicables
									</p>
								</li>
								<br></br>
								<li>
									<strong>RESPONSABLE DEL TRATAMIENTO</strong>
									<br />
									<br />
									<strong>5.1 Nombre o Razón Social:</strong>
									<p>Zajana S.A.S</p>
									<strong>5.2 Domicilio o Dirección:</strong>
									<p>Calle 98 No. 22-64 Oficina 1016</p>
									<strong>5.3 Correo Electrónico:</strong>
									<p> habeasdata@mareigua.co</p>
									<strong>5.4 Página Web:</strong>
									<p> planeo.mareigua.co</p>
									<strong>5.5 Teléfono:</strong>
									<p> 6221109</p>
								</li>
								<br></br>
								<li>
									<strong>POLÍTICA DE PRIVACIDAD</strong>
									<p>
										Si un dato personal es proporcionado, dicha información será utilizada sólo para los propósitos aquí señalados. Planeo no
										procederá a vender, licenciar, transmitir, o divulgar la misma, salvo que:
									</p>
									<ol type='i'>
										<li>Exista autorización expresa para hacerlo.</li>
										<li>Sea necesario para permitir a los contratistas o agentes prestar los servicios encomendados.</li>
										<li>Sea necesario con el fin de proveer nuestros servicios y/o productos.</li>
										<li>
											Se divulgue a las entidades que prestan servicios de marketing en nuestro nombre o a otras entidades con las cuales tenemos
											acuerdos de mercadeo conjunto.
										</li>
										<li>Tenga relación con una fusión, consolidación, adquisición, desinversión u otro proceso de restructuración corporativa.</li>
										<li>Sea requerido o permitido por la ley.</li>
									</ol>
									<p>
										Planeo, podrá subcontratar a terceros para el procesamiento de determinadas funciones o información. Cuando efectivamente Planeo
										subcontrate con terceros, al momento de la suministración de la información personal a terceros prestadores de servicios,
										advertimos a dichos terceros sobre la necesidad de proteger dicha información personal con medidas de seguridad apropiadas, les
										prohibimos el uso de su información personal para fines propios y les impedimos que divulguen su información personal a otros.
									</p>
									<p>En virtud del tratamiento de los datos personales, Planeo podrá compartir la información con:</p>
									<ol type='i'>
										<li>Personas jurídicas o naturales que ostenten la calidad de proveedores si aplica.</li>
										<li>
											Contratistas y/o terceros relacionados directa o indirectamente con Planeo, para adelantar gestiones de consultoría, cobranza,
											recaudo, publicidad, mercadeo o cualquiera otra actividad derivada y/o relacionada con el objeto social.
										</li>
									</ol>
									<p>Planeo, mediante la instalación de identificadores propios y/o de terceros y a través de herramientas para el análisis de cuenta, podrá realizar actividades de seguimiento a los usuarios que utilizan sus distintas versiones y páginas de promoción. En este seguimiento se recopila, almacena, analiza, usa y/o utiliza información personal del usuario y técnica de su dispositivo.</p>
									<p>Con la información recolectada, Planeo analizará el rendimiento de sus productos y los servicios en diferentes ubicaciones, lo cual, permitirá hacer estudios internos sobre los intereses, comportamientos y demografía de los usuarios, para comprender mejor sus necesidades e intereses, mejorar las iniciativas comerciales y promocionales</p>
									<p>Así mismo, será fuente de información para el monitoreo funcional de las distintas versiones de Planeo, con el fin de hacer un seguimiento y evaluación del servicio para ofrecer correctamente los servicios solicitados</p>
								</li>
								<br></br>
								<li>
									<strong>FINALIDAD GENERAL</strong>
									<p>Los datos personales son objeto de tratamiento por parte de Planeo con las siguientes finalidades:</p>
									<ul>
										<li>Para el envío de información a sus empleados, proveedores, terceros y usuarios de su plataforma.</li>
										<li>
											Ejecutar la relación contractual existente con sus empleados, proveedores, terceros y usuarios de su plataforma, incluida el
											pago de obligaciones contractuales.
										</li>
										<li>Proveer los servicios y/o los productos requeridos por sus usuarios.</li>
										<li>Informar sobre nuevos productos o servicios y/o sobre cambios en los mismos</li>
										<li>Desarrollar el proceso de selección, evaluación, y vinculación laboral</li>
										<li>Para la verificación de saldos de sus acreedores</li>
										<li>
											Registrar la información de sus empleados, proveedores, terceros y usuarios de su plataforma y el registro de base de datos de
											cada uno.
										</li>
										<li>
											Los indicados en la autorización otorgada por el Titular del dato o descritos en el aviso de privacidad respectivo, según sea el
											caso
										</li>
										<li>Envío de información a entidades gubernamentales o judiciales por solicitud expresa de la misma.</li>
										<li>Soporte en procesos de auditoría externa/interna.</li>
										<li>Con propósitos de seguridad o prevención de fraude</li>
										<li>
											Envío, recepción de mensajes de texto, correos electrónicos y/o otros con fines comunicativos, publicitarios y/o de atención al
											cliente, con el fin de mantener un contacto personalizado con nuestros usuarios.
										</li>
										<li>
											Reportar a los bancos de datos, directamente o por intermedio de las autoridades, los datos tratados o sin tratar, y la
											información financiera pertinente.
										</li>
										<li>Realizar invitaciones a eventos y ofrecer nuevos productos y servicios.</li>
										<li>Gestionar trámites (solicitudes, quejas, reclamos).</li>
										<li>Video con fines CCTV.</li>
										<li>Efectuar encuestas de satisfacción respecto de los bienes y servicios ofrecidos por Planeo.</li>
										<li>
											Suministrar información de contacto a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y
											cualquier tercero con el cual Planeo tenga un vínculo contractual para el desarrollo de actividades de ese tipo (investigación
											de mercados y telemercadeo, etc) para la ejecución de las mismas.
										</li>
										<li>
											Contactar al Titular a través de medios telefónicos para realizar encuestas, estudios y/o confirmación de datos personales
											necesarios para la ejecución de una relación contractual.
										</li>
										<li>
											Contactar al Titular a través de medios electrónicos – SMS o chat para el envío de noticias relacionadas con campañas de
											fidelización o mejora de servicio.
										</li>
										<li>
											Contactar al Titular a través de correo electrónico para el envío de extractos, estados de cuenta o facturas en relación con las
											obligaciones derivadas del contrato celebrado entre las partes.
										</li>
										<li>
											Ofrecer programas de bienestar corporativo y planificar actividades empresariales, para el titular y sus beneficiarios (hijos,
											cónyuge, compañero permanente).
										</li>
										<li>Prestar los servicios ofrecidos por Planeo y aceptados en el contrato suscrito.</li>
									</ul>
									<p>Sin perjuicio de la generalidad de lo anterior, se darán adicionalmente las siguientes finalidades</p>
									<br></br>
									<strong>7.1 FINALIDAD EN MATERIA LABORAL</strong>
									<p>
										Para fines pertinentes a la relación laboral (EPS, ARL, fondos de pensiones y cesantías, cajas de compensación familiar, etc.)
									</p>
									<p>
										Contabilización y pago de nómina. Planeo podrá tercerizar el pago de nómina y para el efecto transferir la información de
										empleados a terceros.
									</p>
									<p>Reclutar y seleccionar personal que ocuparán las vacantes.</p>
									<p>Procesar, confirmar y cumplir con las obligaciones laborales legales y extralegales derivadas del contrato laboral.</p>
									<p>Realizar transacciones.</p>
									<p>Pago de beneficios extralegales.</p>
									<p>Análisis estadísticos</p>
									<p>Mantener base de datos de candidatos</p>
									<p>Capacitación y formación</p>
									<p>
										Compartir los datos personales con entidades bancarias, empresas que ofrezcan beneficios a nuestros trabajadores activos, entre
										otros.
									</p>
									<br></br>
									<strong>7.2 FINALIDADES USUARIOS</strong>
									<p>Manejo de la facturación y recaudo de cartera</p>
									<p>Transferencia a terceros que puedan tener productos de interés</p>
									<p>Organización para los fines de la plataforma</p>
									<br></br>
									<strong>7.3 FINALIDAD PARA PROVEEDORES</strong>
									<p>Para fines comerciales y de prestación de servicios</p>
									<p>Contabilización e informes</p>
									<p>Disposiciones legales y fiscales</p>
									<p>Transferencias de fondos</p>
									<p>Facturación</p>
									<p>Convenios</p>
									<p>Contrataciones</p>
								</li>
								<br></br>
								<li>
									<strong>DERECHOS DE LOS TITULARES</strong>
									<p>Los Titulares de los datos personales tendrán derecho a:</p>
									<ul>
										<li>Acceder de forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento.</li>
										<li>
											Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a
											error, o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado.
										</li>
										<li>Solicitar prueba de la autorización otorgada.</li>
										<li>
											Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad
											vigente.
										</li>
										<li>
											Revocar la autorización y/o solicitar la supresión del dato, siempre que no exista un deber legal o contractual que impida
											eliminarlos.
										</li>
										<li>
											Abstenerse de responder las preguntas sobre datos sensibles. Tendrá carácter facultativo las respuestas que versen sobre datos
											sensibles o sobre datos de las niñas y niños y adolescentes.
										</li>
										<li>Ser informado por Planeo, previa solicitud, respecto del uso que ésta le ha dado a sus datos personales.</li>
									</ul>
								</li>
								<br></br>
								<li>
									<strong>LEGITIMACÍÓN PARA EJERCER LOS DERECHOS DE TITULARES</strong>
									<p>Los derechos de los titulares podrán ejercerse por las siguientes personas:</p>
									<ol type='a'>
										<li>
											Por el titular, quien deberá acreditar su identidad en forma suficiente por los distintos medios que le ponga a disposición
											Planeo.
										</li>
										<li>
											Por los causahabientes del titular (en los casos que este falte por muerte o incapacidad), quienes deberán acreditar tal
											calidad.
										</li>
										<li>Por el representante y/o apoderado del titular, previa acreditación de la representación o poder correspondiente.</li>
										<li>
											Por estipulación a favor de otro o para otro. Los derechos de los niños, niñas y adolescentes se ejercerán por las personas
											facultadas para representarlos legalmente.
										</li>
									</ol>
								</li>
								<br></br>
								<li>
									<strong>DEBERES RESPONSABLE Y ENCARGADOS</strong>
									<br />
									<strong>10.1 Deberes del Responsable del Tratamiento</strong>
									<ol type='a'>
										<li>
											Solicitar y conservar, en las condiciones previstas en esta Política, copia de la respectiva autorización otorgada por el
											titular.
										</li>
										<li>
											Informar de manera clara y suficiente al titular sobre la finalidad de la recolección y los derechos que le asisten por virtud
											de la autorización otorgada.
										</li>
										<li>Informar a solicitud del Titular sobre el uso dado a sus datos personales.</li>
										<li>Tramitar las consultas y reclamos formulados en los términos señalados en la presente Política.</li>
										<li>Procurar que los principios de veracidad, calidad, seguridad y confidencialidad.</li>
										<li>
											Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o
											acceso no autorizado o fraudulento.
										</li>
										<li>Actualizar la información cuando sea necesario.</li>
										<li>Rectificar los datos personales cuando ello sea procedente.</li>
									</ol>
									<br />
									<strong>10.2 Deberes de los Encargados del Tratamiento</strong>
									<ol type='a'>
										<li>
											Establecer que el Responsable del Tratamiento esté autorizado para suministrar los datos personales que tratará como Encargado.
										</li>
										<li>Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.</li>
										<li>
											Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o
											acceso no autorizado o fraudulento.
										</li>
										<li>Realizar oportunamente la actualización, rectificación o supresión de los datos.</li>
										<li>
											Actualizar la información reportada por los Responsables del tratamiento dentro de los cinco (5) días hábiles contados a partir
											de su recibo.
										</li>
										<li>Tramitar las consultas y los reclamos formulados por los titulares en los términos señalados en la presente política.</li>
										<li>Registrar en la base de datos la leyenda “reclamo en trámite” en la forma en que se establece en la presente política.</li>
										<li>
											Insertar en la base de datos la leyenda “información en discusión judicial” una vez notificado por parte de la autoridad
											competente sobre procesos judiciales relacionados con la calidad del dato personal.
										</li>
										<li>
											Abstenerse de circular información que esté siendo controvertida por el titular y cuyo bloqueo haya sido ordenado por la
											Superintendencia de Industria y Comercio.
										</li>
										<li>
											Permitir el acceso a la información únicamente a las personas autorizadas por el titular o facultadas por la ley para dicho
											efecto.
										</li>
										<li>
											Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan
											riesgos en la administración de la información de los titulares.
										</li>
										<li>Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</li>
									</ol>
								</li>
								<br></br>
								<li>
									<strong>AUTORIZACIÓN</strong>
									<p>
										Planeo debe solicitar autorización previa, expresa e informada a los Titulares de los Datos Personales sobre los que requiera
										realizar el Tratamiento.
									</p>
									<ol type='a'>
										<li>
											Autorización previa significa, que el consentimiento debe ser otorgado por el Titular, a más tardar en el momento de la
											recolección de los Datos Personales.
										</li>
										<li>
											<p>
												Autorización expresa quiere decir que el consentimiento del Titular debe ser explícito y concreto, no son válidas las
												autorizaciones abiertas y no específicas. Se requiere que el Titular manifieste su voluntad de autorizar que Planeo realice el
												Tratamiento de sus Datos Personales.
											</p>
											<p>
												Esta manifestación de voluntad del Titular puede darse a través de diferentes mecanismos puestos a disposición por Planeo,
												tales como:
											</p>
											<ul>
												<li>Por escrito.</li>
												<li>De forma oral, por ejemplo, en una conversación telefónica o en videoconferencia.</li>
												<li>
													Mediante conductas inequívocas que permitan concluir que otorgó su autorización, por ejemplo, a través de su aceptación
													expresa a los Términos y Condiciones de una actividad dentro de los cuales se requiera la autorización de los participantes
													para el Tratamiento de sus Datos Personales. En ningún caso el silencio del Titular se asimilará a una conducta inequívoca.
												</li>
												<li>
													Cualquiera que sea el mecanismo utilizado por Planeo, es necesario que la autorización se conserve para poder ser consultada
													con posterioridad.
												</li>
											</ul>
										</li>
										<li>
											<p>Autorización Informada significa que al momento de solicitar el consentimiento al Titular, debe informársele claramente:</p>
											<ul>
												<li>Los Datos Personales que serán recolectados.</li>
												<li>La identificación y datos de contacto del Responsable y del Encargado del Tratamiento.</li>
												<li>
													Las finalidades específicas del Tratamiento que se pretende realizar, es decir: cómo y para qué se va a hacer la
													recolección, el uso, la circulación de los Datos Personales.
												</li>
												<li>Cuáles son los derechos que tiene como Titular de los Datos Personales;</li>
												<li>
													El carácter facultativo de la respuesta a las preguntas que le sean hechas, cuando éstas versen sobre datos sensibles o
													sobre los datos de niñas, niños y adolescentes.
												</li>
											</ul>
										</li>
									</ol>
								</li>
								<br></br>
								<li>
									<strong>EVENTOS EN LOS CUALES NO ES NECESARIA LA AUTORIZACIÓN DEL TITULAR DE LOS DATOS PERSONALES</strong>
									<p>La autorización del titular de la información no será necesaria cuando se trate de:</p>
									<ol type='a'>
										<li>
											Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial.
										</li>
										<li>Datos de naturaleza pública.</li>
										<li>Casos de urgencia médica o sanitaria.</li>
										<li>Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos.</li>
										<li>Datos relacionados con el Registro Civil de las personas.</li>
									</ol>
								</li>
								<br></br>
								<li>
									<strong>DISPOSICIONES ESPECIALES PARA DATOS DE NATURALEZA SENSIBLE</strong>
									<p>
										De acuerdo con las normas aplicables, se consideran como datos de naturaleza sensible aquellos que afectan la intimidad o cuyo uso
										indebido puede generar discriminación. Por ejemplo a modo enunciativo, pero no limitante, son temas relacionados con:
									</p>
									<ul>
										<li>Origen racial o étnico.</li>
										<li>Orientación política.</li>
										<li>Convicciones religiosas / filosóficas.</li>
										<li>Pertenencia a sindicatos, a organizaciones sociales, a organizaciones de derechos humanos o a partidos políticos.</li>
										<li>Vida sexual.</li>
										<li>Datos biométricos (como la huella dactilar, la firma y la foto).</li>
									</ul>
									<p>
										El Tratamiento de los Datos Personales de naturaleza sensible está prohibido por la ley, salvo que se cuente con autorización
										expresa, previa e informada del Titular. En este caso, además de cumplir con los requisitos establecidos para la autorización
										Planeo deberá:
									</p>
									<ul>
										<li>Informar al Titular que por tratarse de datos sensibles no está obligado a autorizar su Tratamiento.</li>
										<li>Informar al Titular cuáles de los datos que serán objeto de Tratamiento son sensibles y la finalidad del Tratamiento.</li>
										<li>Informar que ninguna actividad está condicionada a que el Titular suministre Datos Personales sensibles.</li>
									</ul>
								</li>
								<br></br>
								<li>
									<strong>CONSIDERACIONES SOBRE CONSULTA A TERCEROS </strong>
									<p>
										PLANEO no es responsable de la calidad de la información entregada a sus clientes proveniente de las Fuentes EXTERNAS, ya que el sistema no administra la información de las fuentes esto corresponde a cada entidad o fuente de información quienes están obligadas a verificar que los datos que manejan son pertinentes, correctos y actualizados para los fines para los cuales los recabaron.
									</p>
									<p>
										PLANEO hace el mejor esfuerzo por presentar un servicio libre de errores, pero lo hace sin ningún tipo de garantía sobre la información entregada ya que es un procesador de información, por lo que cada persona física o moral debe evaluar según su necesidad, riesgo legal o debida diligencia los resultados obtenidos
									</p>
									<p>
										Para poder hacer consultas sobre terceros en PLANEO, siempre se debe tener el consentimiento expreso por el Candidato para realizar el tratamiento de sus datos. Previo a realizar la búsqueda en PLANEO, las personas o empresas, serán quienes deben darle a conocer a los Candidatos su aviso de privacidad e indicarle claramente las finalidades para las cuales están solicitándoles los datos personales. De esta forma, son las personas o empresas clientes de PLANEO quienes asumen responsabilidad total y garantizan que al usar PLANEO aceptan y garantizan que cuentan con los permisos de manejo de data.
									</p>
									<p>
										Planeo como centralizador de la consulta, no responde ni de forma contractual o extracontractual por el uso del reporte generado. Invitamos a nuestros clientes y usuarios a utilizar el servicio con responsabilidad. La toma de decisiones depende de cada empresa y persona como Controlador de la información. PLANEO, no se hace responsable por la información entregada ni por la calidad de la información que entregan las fuentes, así como tampoco de la decisión final tomada con base en el reporte emitido.
									</p>
									<p>
										Los consumidores que adquieran este servicio actuarán en calidad de Usuarios de la información conforme lo establece la Ley 1266 de 2008 y, por ende, deberán observar los deberes que dicha norma les impone. Esto es:
									</p>
									<ul>
										<li>Consultar la información suministrada por Verifica de conformidad con los parámetros establecidos en la Ley Hábeas Data y en la Resolución.</li>
										<li>Conservar prueba de la respectiva autorización de los Titulares de la Información, cuando sea requerida conforme lo previsto en la Ley 1266 de 2008 y la Resolución 76434 de 2012 de la Superintendencia de Industria y Comercio</li>
										<li>Guardar reserva sobre toda la información que les sea suministrada por PLANEO y en ninguna circunstancia, revelarla a terceros o hacerla pública, salvo orden judicial debidamente motivada.</li>
										<li>Informar a los Titulares, a su solicitud, sobre la utilización que le están dando a la información</li>
										<li>La consulta realizada no podrá ser guardada o almacenada</li>
										<li>Dar cumplimiento a las disposiciones Código de Conducta, el Procedimiento, la Ley 1266 de 2008 y la Resolución76434 de 2012 de la Superintendencia de Industria y Comercio. </li>
									</ul>
									<br></br>
									<li>
										<strong>AREA RESPONSABLE DE LA IMPLEMENTACIÓN DE LA POLÍTICA</strong>
										<p>
											Planeo ha designado como responsable de velar por el cumplimiento de esta Política al interior de la institución al área de Planeo
											quien estará disponible para la atención de peticiones, consultas y reclamos por parte de los titulares y para realizar cualquier
											actualización, rectificación y supresión de datos personales.
										</p>
										<p>
											Asimismo, el área de Planeo absolverá cualquier duda o inquietud que cualquiera de nuestros funcionarios tenga, a través del
											correo electrónico que se relaciona en el encabezado de este documento.
										</p>
									</li>
									<br></br>
									<li>
										<strong>PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA</strong>
										<p>
											En cumplimiento de las normas sobre protección de datos personales, Planeo presenta el procedimiento y requisitos mínimos para el
											ejercicio de sus derechos:
										</p>
										<p>Para la radicación y atención de su solicitud le solicitamos suministrar la siguiente información:</p>
										<ol type='a'>
											<li>Nombre completo y apellidos</li>
											<li>Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto),</li>
											<li>Medios para recibir respuesta a su solicitud,</li>
											<li>
												Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (conocer, actualizar,
												rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información) Firma (si aplica) y
												número de identificación.
											</li>
											<li>Copia de los documentos que se requiera hacer valer, en caso de aplicar.</li>
										</ol>
										<p>Copia de los documentos que se requiera hacer valer, en caso de aplicar.</p>
										<ol type='a'>
											<li>
												El reclamo se formulará mediante comunicación dirigida al área de PLANEO con la identificación del titular, la descripción de
												los hechos que dan lugar a la solicitud, la dirección y acompañado de los documentos que soporten la solicitud de ser aplicable.
											</li>
											<li>
												Si el reclamo resultare incompleto, el área de PLANEO, requerirá al titular dentro de los cinco (5) días hábiles siguientes a
												la recepción de la solicitud para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que
												el titular presente la información requerida, se entenderá que ha desistido del reclamo.
											</li>
											<li>
												Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga “reclamo en trámite” y el motivo del
												mismo, actividad que deberá realizarse en un término no superior a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta
												que el reclamo sea decidido.
											</li>
											<li>
												El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su
												recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora
												y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al
												vencimiento del primer término.
											</li>
										</ol>
										<p>
											Una vez cumplidos los términos señalados por esta Política y las demás normas aplicables, el Titular al que se deniegue, total o
											parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, supresión y revocación podrá poner su caso en
											conocimiento de la Superintendencia de Industria y Comercio –Delegatura para la Protección de Datos Personales-.
										</p>
										<p>
											Planeo ha dispuesto los siguientes medios para la recepción y atención de quejas y reclamos, todos los cuales permiten conservar
											prueba de su presentación:
										</p>
										<ul>
											<li>Solicitud presentada al correo electrónico:                         habeasdata@mareigua.co</li>
										</ul>
									</li>
									<br></br>
									<li>
										<strong>MODIFICACIÓN Y/O ACTUALIZACIÓN DE LA POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES Y MANEJO DE INFORMACIÓN</strong>
										<p>
											Cualquier cambio sustancial en las políticas de tratamiento, se comunicará de forma oportuna a los Titulares de los datos a través
											del correo electrónico institucional y/o a través de la página web de Planeo.
										</p>
									</li>
									<br></br>
									<li>
										<strong>VIGENCIA</strong>
										<p>La presente Política para el Tratamiento de Datos Personales rige a partir del 1 de enero de 2022.</p>
										<p>
											Las bases de datos en las que se registrarán los datos personales tendrán una vigencia igual al tiempo en que se mantenga y
											utilice la información para las finalidades descritas en esta política. Una vez se cumplan esas finalidades y siempre que no
											exista un deber legal o contractual de conservar su información, sus datos serán eliminados de nuestras bases de datos.
										</p>
										<p>Atentamente,</p>
										<strong>Zajana S.A.S</strong>
									</li>
								</li>
							</ol>
						</li>
					</ol>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={closeModal}>Cerrar</Button>
			</Modal.Footer>
		</Modal>
	);
};
