//#region Dependencias
import { CreateFolderSharedFormModelComplete } from "@planeo-features/documents/documents-manager/partials/form-create-folder-shared/defs";
import { HttpClienteConsumoAPI_Get, HttpClienteConsumoAPI_Post, HttpClienteConsumoCreateFileShared_Post } from "../HttpClient";
import { DocumentsContadorIndependienteArchivosNuevosDto, DocumentsContadorIndependienteArchivosNuevosModel, DocumentsCreateFileModel, DocumentsDeleteFolderModel, DocumentsDestinatariosActionsModel, DocumentsListFoldersAndFilesDto, DocumentsListFoldersAndFilesSubfoldersMiniDto, DocumentsListPeopleCompanyDto, DocumentsListadoDestinatarios, DocumentsUpdatePropertiesFileModel, DocumentsUpdatePropertiesModel, DocumentsViewFileModel, EmployeeListByFilterModel, GestionEstadoNotasModel, ListEmployeeByFilterDto, ReportViewerEmployees, ReportViewerFilesModel, ResponseGenericDto, UsersDto } from "../models/documentsModelsInterface";
import environments from '@planeo-environments';
import { NotificationsDto, NotificationsFindByIdentification } from "../models/notificationsInterface";
//#endregion

export const GetListPeopleMailBox_Get = (id : string) => HttpClienteConsumoAPI_Get<DocumentsListPeopleCompanyDto[]>(environments.firebaseConfig.urlPlaneoApiNewDocuments + 'getlistpeoplebycompany?id=' + id +'&uploadPeople=true');
export const GetListFoldersAndFiles_Post = (body: DocumentsDeleteFolderModel) => HttpClienteConsumoAPI_Post<DocumentsListFoldersAndFilesDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments + 'getfolders', body);
export const GetCreateFolderShared_Post = (body: CreateFolderSharedFormModelComplete) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments + 'createfolder', body);
export const GetCreateFileShared_Post = (body: DocumentsCreateFileModel) => HttpClienteConsumoCreateFileShared_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'createfile', body);
export const GetUpdatePropertiesFolderShared_Post = (body: DocumentsUpdatePropertiesModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'updatepropertiesfolder', body);
export const GetUpdatePropertiesFileShared_Post = (body: DocumentsUpdatePropertiesFileModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'updatepropertiesfile', body);
export const GetDeleteFolderShared_Post = (body: DocumentsDeleteFolderModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'deletefolder', body);
export const GetDeleteFileShared_Get = (body : DocumentsViewFileModel) => HttpClienteConsumoAPI_Get<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments + 'deleteFile?id=' + body.id+'&uploadPeople='+body.uploadPeople);
export const GetViewFileShared_Get = (body : DocumentsViewFileModel) => HttpClienteConsumoAPI_Get<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments + 'viewFile?id=' + body.id+'&uploadPeople='+body.uploadPeople);
export const GetListFoldersPrincipal_Get = (id : string, userId: string = '') => HttpClienteConsumoAPI_Get<DocumentsListFoldersAndFilesSubfoldersMiniDto[]>(environments.firebaseConfig.urlPlaneoApiNewDocuments + 'getlistfoldersprincipalbycompany?id=' + id+'&userId='+userId);
export const GetListReceiversByFolderId_Get = (id : string) => HttpClienteConsumoAPI_Get<DocumentsListadoDestinatarios[]>(environments.firebaseConfig.urlPlaneoApiNewDocuments + 'getlistsendersfolder?id=' + id);
export const GetListUsersByCompanyId_Get = (id : string) => HttpClienteConsumoAPI_Get<UsersDto[]>(environments.firebaseConfig.urlPlaneoApiNewDocuments + 'getlistusersbycompany?id=' + id);
export const GetAddSenderFolder_Post = (body: DocumentsDestinatariosActionsModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'addsenderfolderuser', body);
export const GetDeleteSenderFolder_Post = (body: DocumentsDestinatariosActionsModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'deletesenderfolderuser', body);
export const GetListReportFilesViewerEmployees_Post = (body: ReportViewerFilesModel) => HttpClienteConsumoAPI_Post<ReportViewerEmployees[]>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'getreportfilesviewerbyemployees', body);
export const GetListEmployeesByCompanyFilter_Post = (body: EmployeeListByFilterModel) => HttpClienteConsumoAPI_Post<ListEmployeeByFilterDto[]>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'getlistpeoplesimplebycompany', body);
export const GetUpdateStateFile_Post = (body: GestionEstadoNotasModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'updatestatefile', body);
//#region Notificaciones Azure
export const GetListNotificationsByIdentification_Post = (body: NotificationsFindByIdentification)=> HttpClienteConsumoAPI_Post<NotificationsDto[]>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'findnotificationexternal', body);
export const GetViwNotificationsById_Post = (body)=> HttpClienteConsumoAPI_Post<boolean>(environments.firebaseConfig.urlPlaneoApiNewDocuments+ 'viewnotificationexternal', body);
//#endregion
export const GetBellsFolders_Post = (body: DocumentsContadorIndependienteArchivosNuevosModel) => HttpClienteConsumoAPI_Post<DocumentsContadorIndependienteArchivosNuevosDto[]>(environments.firebaseConfig.urlPlaneoApiNewDocuments+'countfilesuniquefoldercompany', body);