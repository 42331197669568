import { useHistory } from 'react-router-dom';

export const useHook = () => {
	const history = useHistory();

	const handleGoToHome = () => {
		history.push('/');
	};

	return {
		handleGoToHome,
	};
};
