//#region Dependencias
import { track, setUserId } from '@amplitude/analytics-browser';
import { DeleteAccountModel } from '../Interfaces/DeleteAccountInterface';
import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import UsersAPI from '@planeo-services/api/functions/UsersAPI';
import { deleteAccountConfirm } from '../Components/DeleteAccountConfirm';
//#endregion

export const useHook = () => {
    //#region Validación datos
    const validationSchemaSignIn : yup.SchemaOf<DeleteAccountModel> = yup.object({
        email: yup.string()
            .required('Campo obligatorio')
            .email('Ingresa un correo v\u00E1lido')
            .typeError('Ingresa un correo v\u00E1lido'),
        document: yup.string()
            .required('El documento es requerido')
            .typeError('El documento no es v\u00E1lido'),
        documentType: yup.number()
            .required('Campo obligatorio')
            .typeError('Tipo documento es campo obligatorio')
            .min(1, 'Tipo documento no válido'),
        password: yup.string()
            .required('La contrase\u00F1a es requerida')
            .typeError('La contrase\u00F1a no es v\u00E1lida')
    });
    //#endregion

    //#region Formik
    const formik: FormikProps<DeleteAccountModel> = useFormik<DeleteAccountModel>({
        initialValues: {
            document : '',
            documentType : 0,
            email : '',
            password: ''
        },
        validationSchema: validationSchemaSignIn,
        validateOnChange: false,
        onSubmit: async (formikValue, {setSubmitting}) => {
            const {email} = formikValue;
            
            setSubmitting(true);
            track('ELIMINAR CUENTA FORM GOOGLE');
		    setUserId(email);

            const result = await deleteAccountConfirm();
            if(result){
                UsersAPI.FnEliminarCuentaFormGoogle(formikValue, formik);
                setSubmitting(false);
            }
        }
    });
    //#endregion

	return {
		formik
	};
};
