import React from 'react';
import { IUpLoadableFile } from './../../../defs';

export interface UploaderFilesProps {
	files?: IUpLoadableFile[];
	onDeleteFile?: (file: IUpLoadableFile) => void;
	onDeleteAll?: () => void;
	title?: string;
}

export class UploaderFilesInfoBase extends React.Component<UploaderFilesProps, any> {}
