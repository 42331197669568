import { useEffect, useState } from 'react';

import { AlertToastProps } from '../defs/props';
import { AlertService } from '../service';

export const useHook = (props: AlertToastProps) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');
	const [color, setColor] = useState<'error' | 'success' | 'info' | 'warn'>('error');

	const handleCloseClicked = () => {
		AlertService.clear(props.id);
	};

	useEffect(() => {
		AlertService.registerAlertComponent(props.id).subscribe((event) => {
			setVisible(event.visible);
			setMessage(event.message);

			event.color && setColor(event.color);
		});
	}, [message, props.id]);

	// Componente Will Unmount
	useEffect(() => {
		return () => {
			AlertService.unregisterAlertComponent(props.id);
		};
	}, []);

	return {
		visible,
		message,
		handleCloseClicked,
		color,
	};
};
