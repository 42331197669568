import { CompanyFolderInfo } from '@planeo-services/logics/models';
import fbase from 'firebase/app';
import { v4 as guid } from 'uuid';

export const createCompanyFolder = (firebase: fbase.app.App, companyId: string) => {
	return async (
		name: string,
		type: number,
		rootId: string | null = null,
		parentId: string | null = null,
		isDefault: boolean = false
	): Promise<string> => {
		const folderId = guid();

		const _folderData: Omit<CompanyFolderInfo, 'id' | 'filesCount'> = {
			name: name,
			type: Number(type),
			isDefault: isDefault,
			createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
			parentReference: parentId ? firebase.firestore().collection('companies').doc(companyId).collection('folders').doc(parentId) : null,
			rootReference: rootId ? firebase.firestore().collection('companies').doc(companyId).collection('folders').doc(rootId) : null
		};

		await firebase.firestore().collection('companies').doc(companyId).collection('folders').doc(folderId).set(_folderData);

		return folderId;
	};
};
