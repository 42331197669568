//#region Dependencias
import './styles.scss';
import { useStateValue } from '@planeo-providers/Context/store';
import { useCompanyContext } from '@planeo-providers/company-provider';
import { useHistory } from 'react-router-dom';
import { useFirebaseApp } from 'reactfire';
import {Modal, Button, Accordion, Card, Row, Col, Form} from 'react-bootstrap';
import { TagsAsignarCategoriesModel, TagsAsignarUsuarioModel, TagsByCategoriesCompanyListModel, TagsByCategoriesDto } from '@planeo-services/api/models/documentsModelsInterface';
import TagsAPI from '@planeo-services/api/functions/TagsAPI';
import Select from 'react-select';
import { useMemo } from 'react';
import { TypeCategoryTabs } from '@planeo-features/documents/documents-manager/defs';
import { track, setUserId } from '@amplitude/analytics-browser';
//#endregion

const CategoriasTagsModal = () => {
    //#region Hooks
    const firebase = useFirebaseApp();
    const history = useHistory();
    //#endregion

    //#region Reducers
    const { company } = useCompanyContext();
    const {state, dispatch} = useStateValue();
    const {listCategoriesByCompany, listTagsByCategory, modalTagsByFolder} = state?.sesionDocuments || {};
    const {open, folderId, folderName, tipo} = modalTagsByFolder || {};
    //#endregion

    //#region Funciones
    //#region FnCerrarModal
    const FnCerrarModal = () => {
        dispatch({
            type: 'OPEN_MODAL_CATEGORIAS_TAGS',
            payload : {
                folderId : '',
                folderName : '',
                open : false,
                tipo : 0
            }
        });

        dispatch({
            type: 'LIST_CATEGORIES_BY_COMPANY',
            payload : []
        });
    }
    //#endregion

    //#region FnSeleccionarTag
    const FnSeleccionarTag = (name: string) => {
        let bodyConsulta : TagsByCategoriesCompanyListModel = {
            companyId : company?.id,
            name,
            folderId
        }
        
        switch(tipo){
            case TypeCategoryTabs.folder:
                TagsAPI.FnListarTagsPorCategoriasFolders(bodyConsulta, dispatch, firebase, history);
            break;
            case TypeCategoryTabs.user:
                TagsAPI.FnListarTagsPorCategoriasUsers(bodyConsulta, dispatch, firebase, history);
            break;
        }

        track('MODAL ETIQUETAS - ABRIR CARD ETIQUETA');
        setUserId(company?.adminUser?.email);
    }
    //#endregion

    //#region FnConversionArray
    const FnConversionArray = useMemo(() => {
        return listTagsByCategory?.items?.filter(x => x.cod_conv !== '0' && x.cod_conv !== '53000895' && x.cod_conv !== '900526893')?.map((item) => {
            return {
                label: item.cod_conv,
                value: item.cod_conv
            } 
        })
    }, [listTagsByCategory?.items, listTagsByCategory?.name]);
    //#endregion

    //#region FnSeleccionandoTag
    const FnSeleccionandoTag = (e: any, name: string) => {
        if(e?.value){
            dispatch({
                type: 'LIST_TAGS_BY_CATEGORY',
                payload : {
                    items: listTagsByCategory?.items?.filter(x => x.cod_conv === e?.value),
                    name
                }
            })    
        }
        else FnSeleccionarTag(name);
    }
    //#endregion

    //#region FnDesasignarTagPorTipo
    const FnDesasignarTagPorTipo = async (body : TagsAsignarCategoriesModel, bodyUser : TagsAsignarUsuarioModel) => {
        let resultado : boolean | undefined = false;

        switch(tipo){
            case TypeCategoryTabs.folder:
                resultado = await TagsAPI.FnDesasignarTagByCarpeta(body, firebase, history);
            break;
            case TypeCategoryTabs.user:
                resultado = await TagsAPI.FnDesasignarTagByUser(bodyUser, firebase, history);
            break;
        }

        return resultado;
    }
    //#endregion

    //#region FnAsignarTagPorTipo
    const FnAsignarTagPorTipo = async (body : TagsAsignarCategoriesModel, bodyUser : TagsAsignarUsuarioModel) => { 
        let resultado : boolean | undefined = false;
        
        switch(tipo){
            case TypeCategoryTabs.folder:
                resultado = await TagsAPI.FnAsignarTagByCarpeta(body, firebase, history);
            break;
            case TypeCategoryTabs.user:
                resultado = await TagsAPI.FnAsignarTagByUser(bodyUser, firebase, history);
            break;
        }

        return resultado;
    }
    //#endregion

    //#region FnAsignacionesTag
    const FnAsignacionesTag = async (tag: TagsByCategoriesDto, categoryName: string, selected: boolean) => {
        let body : TagsAsignarCategoriesModel = {
            categoryName,
            tag : tag.cod_conv,
            companyId : company?.id,
            folderId : folderId
        };

        let bodyUser : TagsAsignarUsuarioModel = {
            categoryName,
            tag : tag.cod_conv,
            companyId : company?.id,
            userId : folderId
        }

        let resultado : boolean | undefined = false;

        if(selected){
            resultado = await FnDesasignarTagPorTipo(body, bodyUser);
        }
        else{
            resultado = await FnAsignarTagPorTipo(body, bodyUser);
        }

        if(resultado){
            FnSeleccionarTag(categoryName);
        }

        track('MODAL ETIQUETAS - SELECCIONAR TAG');
        setUserId(company?.adminUser?.email);
    }
    //#endregion
    //#endregion

    //#region Render
    return (
        <Modal
            show={open}
            onHide={()=>FnCerrarModal()}
            dialogClassName="custom-modal2"
        >
            <Modal.Header>
                <Modal.Title>Etiquetas {!!folderName && '-'} {folderName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    listCategoriesByCompany?.length === 0
                    ?
                    <h6 className='text-muted text-center' style={{marginTop: 50}}>No existe información relacionada.</h6>
                    :
                    <div style={{overflowY: 'auto', maxHeight: '60vh'}}>
                        {
                            listCategoriesByCompany?.map((registro) => {
                                const {name} = registro;
                                return(
                                    <>
                                        {
                                            <Accordion className='my-4'>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey={name} onClick={()=>FnSeleccionarTag(name)}>
                                                        <b style={{cursor: 'pointer'}}>+ {name}</b>
                                                    </Accordion.Toggle>
                                                    {
                                                        name === listTagsByCategory?.name
                                                        &&
                                                        <Accordion.Collapse eventKey={name}>
                                                            <Card.Body>
                                                                <Row style={{marginBottom: 20}}>
                                                                    <Col xs={12} md={6}>
                                                                        <Select
                                                                            options={FnConversionArray}
                                                                            placeholder='Escribe el tag que deseas buscar'
                                                                            loadingMessage={()=>'Buscando...'}
                                                                            isClearable
                                                                            onChange={(e: any) => FnSeleccionandoTag(e, name)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    {
                                                                        listTagsByCategory?.items?.filter(x => x.cod_conv !== '0' && x.cod_conv !== '53000895' && x.cod_conv !== '900526893')?.length > 0
                                                                        &&
                                                                        <Col xs={12} md={7}>
                                                                            <div style={{overflowY: 'scroll', maxHeight: 200}}>
                                                                                <hr style={{marginBottom: 10}} />
                                                                                {
                                                                                    listTagsByCategory?.items?.filter(x => x.cod_conv !== '0' && x.cod_conv !== '53000895' && x.cod_conv !== '900526893')?.map((tag) => {
                                                                                        const {cod_conv, selected} = tag;
                                                                                        return (
                                                                                            <Form.Check type='checkbox' key={cod_conv}>
                                                                                                <Form.Check.Input type='checkbox' checked={selected} onClick={()=>FnAsignacionesTag(tag, name, selected)} />
                                                                                                <Form.Check.Label style={{marginLeft: 10}}>{cod_conv}</Form.Check.Label>
                                                                                            </Form.Check>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    }
                                                                    {
                                                                        listTagsByCategory?.items?.filter(x => x.selected)?.length > 0
                                                                        &&
                                                                        <Col xs={12} md={5}>
                                                                            <Card>
                                                                                <Card.Body
                                                                                    style={{overflowY: 'scroll', flexWrap: 'wrap', display: 'flex', flexDirection: 'row', maxHeight: 200}}
                                                                                >
                                                                                    {
                                                                                        listTagsByCategory?.items?.filter(x => x.selected)?.map((item) => (
                                                                                            <div
                                                                                                style={{
                                                                                                    borderRadius: 5,
                                                                                                    backgroundColor: '#343BA7',
                                                                                                    textAlign: 'center',
                                                                                                    paddingInline: 10,
                                                                                                    paddingTop: 5,
                                                                                                    height: 30,
                                                                                                    marginTop: 10,
                                                                                                    marginInline : 2,
                                                                                                    columnGap: 5,
                                                                                                    rowGap: 5,
                                                                                                    borderBottom: '10px solid transparent'
                                                                                                }}
                                                                                                key={item.cod_conv}
                                                                                            >
                                                                                                <label style={{color: '#FFF'}}>{item.cod_conv}</label>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                    
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    }
                                                </Card>
                                            </Accordion>
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>FnCerrarModal()}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
    //#endregion
}

export default CategoriasTagsModal