import * as yup from 'yup';

export interface LoginFormModel {
	email: string;
	password: string;
	rememberMe: boolean;
	captcha: string | null;
}

export const LoginFormModelSchema = yup.object({
	email: yup.string().trim().required('Campo requerido').email('Email no válido'),
	password: yup.string().trim().required('Campo requerido'),
	rememberMe: yup.boolean().notRequired(),
	captcha: yup.string().trim().required('Campo requerido').nullable(true),
});
