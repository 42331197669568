//#region Dependencias
import axios, {AxiosResponse, AxiosRequestConfig, AxiosError} from 'axios';
import { DocumentsCreateFileModel } from './models/documentsModelsInterface';
//#endregion

axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const tokenSeguridad = localStorage.getItem('TokenAPI');
    if(tokenSeguridad){
        if(!config.headers){
            config.headers = {};
        }

        config.headers.Authorization = 'Bearer ' + tokenSeguridad;
    }
    
    return config;
});

const requestGenerico = {
    get  : <T, R>(url:string) => axios.get<T, AxiosResponse<R>>(url),
    post : <T, R>(url: string, body: any) => axios.post<T, AxiosResponse<R>>(url, body),
    postFiles : <T, R>(url: string, body: FormData) => axios.post<T, AxiosResponse<R>>(url, body, {headers: {'Content-Type':'multipart/form-data'}}),
    put  : <T, R>(url: string, body: any) => axios.put<T, AxiosResponse<R>>(url, body)
};

export const HttpClienteConsumoAPI_Get = <T>(url: string) => {
    return new Promise<AxiosResponse<T>>((resolve, reject) => {
        requestGenerico.get<null, T>(url)
        .then((response) => {
            resolve(response);
        })
        .catch((err: AxiosError) => {
            reject(err?.message === 'Network Error' ? err.message : err.response);
        });
    });
}

export const HttpClienteConsumoAPI_Post = <T>(url: string, body: object) => {
    return new Promise<AxiosResponse<T>>((resolve, reject) => {
        requestGenerico.post<null, T>(url, body)
        .then((response) => {
            resolve(response);
        })
        .catch((err: AxiosError) => {
            reject(err?.message === 'Network Error' ? err.message : err.response);
        });
    });
}

export const HttpClienteConsumoCreateFileShared_Post = <T>(url: string, body: DocumentsCreateFileModel) => {
    return new Promise<AxiosResponse<T>>((resolve, reject) => {
        let formData = new FormData();
        formData.append('document', body.document);
        formData.append('documentType', body.documentType?.toString());
        formData.append('blocked', String(body.blocked));
        formData.append('folderId', body.folderId);
        formData.append('uploadPeople', String(body.uploadPeople));
        for (const file of body.files) {
            formData.append('files', file, file.name);
        }
        
        requestGenerico.postFiles<null, T>(url, formData)
        .then((response) => {
            resolve(response);
        })
        .catch((err: AxiosError) => {
            reject(err?.message === 'Network Error' ? err.message : err.response);
        });
    });
}

export default requestGenerico;