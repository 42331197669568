//#region Dependencias
import {lazy} from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Employees from '@planeo-media/not-verify-icons/employees.svg';
import CompanyNotVerifiedPage from '@planeo-features/shared-pages/company-not-verified';
const NotificationsListPage = lazy(() => import('./list'));
//#endregion

export const NotificationsRoutes = ({ company }) : JSX.Element => {
    const { path } = useRouteMatch();

    //#region Render
    return (
        <>
            {!company.companyAccept ? (
				<Switch>
					<Route path={`${path}`}>
						<CompanyNotVerifiedPage
							image={Employees}
							title='Usuarios'
							text='Aún estamos verificando tu cuenta… Pronto podrás usar esta funcionalidad'
						/>
					</Route>
					<Redirect to={`${path}`} />
				</Switch>
			) : (
				<Switch>
					<Route exact path={`${path}`} component={NotificationsListPage} />
					<Redirect from='*' to={`/404`} />
				</Switch>
			)}
        </>
    )
    //#endregion
}