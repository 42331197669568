import { Spinner } from 'react-bootstrap';

import './styles.scss';

export const PlaneoLoader = (): JSX.Element => {
	return (
		<div className='spinner-container'>
			<Spinner className='spinner' animation='border' role='status' />
		</div>
	);
};
