import { yupResolver } from '@hookform/resolvers/yup';
import { AlertDialogService } from '@planeo-components/alerts-dialog';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFirebaseApp } from 'reactfire';
import { PasswordRecoveryFormModel, PasswordRecoveryFormModelSchema } from './form.model';

import ImgMail from '@planeo-media/Modal/Modale_mail_Image.svg';
import { PasswordRecoveryModalProps } from '.';

export const useHook = (props: PasswordRecoveryModalProps) => {
	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors, isValid },
	} = useForm<PasswordRecoveryFormModel>({
		resolver: yupResolver(PasswordRecoveryFormModelSchema),
		mode: 'onChange',
	});

	const [isSubmiting, setIsSubmiting] = useState(false);
	const firebase = useFirebaseApp();

	const onSubmit = async () => {
		setIsSubmiting(true);
		const formData = watch();

		try {
			props.onHide(false);

			await firebase
				.auth()
				.sendPasswordResetEmail(formData.email.trim())
				.finally(() => {
					setIsSubmiting(false);
				});

			await AlertDialogService.open({
				icon: ImgMail,
				title: 'Mensaje enviado',
				message: (
					<>
						Por favor revisa tu correo <span className='highlight'>{formData.email.trim()}</span> para continuar con el proceso.
					</>
				),
			});
		} catch (ex: unknown) {
			const error = ex as { code: string; message: string };
			let message = `Ocurrió un error inesperado: ${error.code}, por favor comunicate con el administrador.`;

			if (error.code === 'auth/invalid-email') message = 'Por favor ingresa una dirección de correo válida.';
			if (error.code === 'auth/user-not-found') message = `El correo ${formData.email.trim()} no se encuentra registrado.`;
			if (error.code === 'auth/too-many-requests') message = `Se han realizado demasiadas peticiones, por favor intenta mas tarde.`;

			await AlertDialogService.alert({
				title: 'Mensaje No enviado',
				message: message,
			});
		}
	};

	useEffect(() => {
		reset();
	}, [reset, props.visible]);

	return {
		register,
		errors,
		isValid,
		handleSubmit,
		onSubmit,
		isSubmiting,
	};
};
