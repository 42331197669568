import { AlertService } from '@planeo-components/alerts';
import { ErrorInfo } from 'react';
export class ErrorService {
	static info(message: string): void {
		console.info(message);
	}
	static debug(message: string): void {
		console.log(message);
	}
	static warning(message: string): void {
		console.log(message);
	}

	static error(message: string): void {
		console.error(message);
	}

	static errorException(error: Error, errorInfo?: ErrorInfo): void {
		console.error(error);
		console.error(errorInfo);
		AlertService.info(error.message, {});
	}
}
