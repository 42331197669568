import { IStorageService } from './interfaces/storage.interface';
import { BaseStorageService } from './managers';

export class StorageService extends BaseStorageService implements IStorageService {
	static instance: IStorageService = new BaseStorageService();

	static configure<T extends IStorageService>(type: new (...args: any[]) => T, ...args: any[]): IStorageService {
		StorageService.instance = new type(...args);
		return this.instance;
	}

	static upload(path: string, file: File, bucketName?: string): Promise<void> {
		const bucket = bucketName ? `gs://${bucketName}/` : undefined;
		return StorageService.instance.upload(path, file, bucket);
	}

	static delete(path: string, bucketName?: string): Promise<void> {
		const bucket = bucketName ? `gs://${bucketName}/` : undefined;
		return StorageService.instance.delete(path, bucket);
	}
}
