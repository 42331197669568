import { FileRejection, useDropzone } from 'react-dropzone';
import { useCallback, useEffect } from 'react';
import { useField } from 'formik';

import { IUpLoadableFile, FileUploaderProps } from '../defs';

export const useHookFormik = (props: FileUploaderProps) => {
	const fieldHook = useField<IUpLoadableFile[]>(props);
	const [field, helpers] = [fieldHook[0], fieldHook[2]];

	const setFieldValue = useCallback(
		(files: IUpLoadableFile[]) => {
			helpers.setValue(files);
			helpers.setTouched(true);
		},
		[helpers]
	);

	const onDropFiles = useCallback(
		(acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
			acceptedFiles = acceptedFiles.filter((x) => !field.value.some((y) => JSON.stringify(y.file) === JSON.stringify(x)));
			rejectedFiles = rejectedFiles.filter((x) => !field.value.some((y) => JSON.stringify(y.file) === JSON.stringify(x.file)));

			const mappedAcc = acceptedFiles.map((file) => ({ file, errors: [] }));
			setFieldValue([...field.value, ...mappedAcc, ...rejectedFiles]);
		},
		[field.value, setFieldValue]
	);

	const { getRootProps: getDropZoneRootProps, getInputProps: getDropZoneInputProps } = useDropzone({
		onDrop: onDropFiles,
		accept: props.filesType,
		maxSize: props.filesMaxSize ?? 2500 * 1024,
		maxFiles: props.maxFiles ?? undefined,
	});

	useEffect(() => {
		if (props.onValueChange) props.onValueChange(field.value);
	}, [field.value, props]);

	const deleteFile = (file: IUpLoadableFile) => {
		setFieldValue(field.value.filter((fw) => fw.file !== file.file));
	};

	const deleteAllFiles = () => {
		setFieldValue([]);
	};

	return {
		getDropZoneRootProps,
		getDropZoneInputProps,
		files: field.value,
		deleteFile,
		deleteAllFiles,
	};
};
