//#region Dependencias
import { createContext, useReducer, useContext, Dispatch, useMemo } from "react";
import { NewTypes } from "./ActionTypes/Actions_Interfaces";
import { ActionsGeneral } from "./ActionTypes";
import { initialState } from "./Inicializadores";
import { mainReducer } from "./Reducers";
//#endregion

export const StateContext = createContext<{
    state: NewTypes,
    dispatch : Dispatch<ActionsGeneral>
}>({
    state: initialState,
    dispatch : () => null
});

export const StateProvider = ({children}: any)=> {
    const [state, dispatch] = useReducer(mainReducer, initialState);
    const objMemo = useMemo(() => ({state, dispatch}), [state, dispatch]);

    //#region Render
    return (
        <StateContext.Provider value={objMemo}>
            {children}
        </StateContext.Provider>
    )
    //#endregion
}

export const useStateValue = () => useContext(StateContext);