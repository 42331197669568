import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { PasswordRecoveryForm, PasswordRecoveryFormSchema } from './form.model';
import queryString from 'query-string';
import { useUsersLogic } from '@planeo-services/logics/users';
import { useFirebaseApp } from 'reactfire';

import './styles.scss';
import { AlertDialogService } from '@planeo-components/alerts-dialog';

export const useHook = () => {
	const history = useHistory();
	const firebase = useFirebaseApp();
	const usersLogic = useUsersLogic(firebase);

	const { search } = useLocation();
	const values = queryString.parse(search);
	const code = values.oobCode != null ? values.oobCode.toString() : '';
	const userEmail = values.email?.toString() ?? '';

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isValid },
	} = useForm<PasswordRecoveryForm>({
		resolver: yupResolver(PasswordRecoveryFormSchema),
		mode: 'onChange',
	});

	const [visible, setVisible] = useState<boolean>(true);
	const [isSubmiting, setIsSubmiting] = useState(false);

	const onSubmit = async () => {
		setIsSubmiting(true);

		try {
			const formData = watch();
			await firebase.auth().confirmPasswordReset(code, formData.password);
			await usersLogic.setUserLoginRetries(userEmail, 0);

			await AlertDialogService.success({
				title: 'Se ha modificado la contraseña',
				message: 'Tus datos se han modificado con éxito, inicia sesión con estos nuevos datos.',
			});
		} catch {
			await AlertDialogService.alert({
				title: 'Error al modificar la contraseña',
				message: 'No logramos modificar tus datos, inténtalo nuevamente, si el problema persiste comunícate con nosotros.',
			});
		}

		handleCancelClicked();
		setVisible(false);
		setIsSubmiting(false);
	};

	const handleCancelClicked = () => {
		history.push('/');
	};

	return {
		register,
		handleSubmit,
		onSubmit,
		errors,
		isValid,
		visible,
		isSubmiting,
		handleCancelClicked,
	};
};
