//#region Dependencias
import { useStateValue } from '@planeo-providers/Context/store';
import './styles.scss';
import CardNotificacion from './CardNotificacion';
import { Spinner } from 'react-bootstrap';
import NotificationsAPI from '@planeo-services/api/functions/NotificationsAPI';
import { useCompanyContext } from '@planeo-providers/company-provider';
import { useHistory } from 'react-router-dom';
import { useFirebaseApp } from 'reactfire';
import { useCallback, useEffect } from 'react';
//#endregion

export interface ModalNotificacionProps {
    modalRef : React.RefObject<HTMLDivElement>,
    carga: any,
    setcarga : any
}

const ModalNotificacion = (props: ModalNotificacionProps) => {
    //#region Reducers
    const { company } = useCompanyContext();
    const {state, dispatch} = useStateValue();
    const {modalNotificaciones, countNotifications, listNotifications, loadingNotifications} = state?.sesionNotifications || {countNotifications: {countNotifications: 0}};
    const {visible} = modalNotificaciones || {visible: false};
    //#endregion

    //#region Hooks
	const firebase = useFirebaseApp();
    const history = useHistory();
    //#endregion

    const FnMarcarTodasLeidas = async () => {
        const resultado = await NotificationsAPI.Fn_ActivarVisualizacion(company.id, '', firebase, history, company.adminUser.email);
        if(resultado){
            NotificationsAPI.Fn_ListarNotificaciones(company.id, 20, 1, dispatch, firebase, history, company.adminUser.email);
            NotificationsAPI.Fn_ListarNotificacionesSinVer(company.id, dispatch, firebase, history, company.adminUser.email);
        }
    }

    const handleClickOutside = useCallback((event: any) => {
        if (props.modalRef.current && !props.modalRef.current.contains(event.target)) {
            dispatch({
                type: 'MODAL_NOTIFICATIONS',
                payload: {
                    visible :false
                }
            });
        }
    }, [dispatch, props.modalRef]);

    useEffect(() => {
        if (visible) {
          document.addEventListener('mousedown', handleClickOutside);
        }
        else{
          document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [visible, handleClickOutside]);


    const FnVerTodas = () => {
        dispatch({
            type: 'MODAL_NOTIFICATIONS',
            payload : {
                visible : false
            }
        });

        dispatch({
            type: 'LOADING_NOTIFICATIONS_HISTORY',
            payload : {
                loadingNotifications : true
            }
        });

        NotificationsAPI.Fn_ListarNotificacionesGeneral(company.id, dispatch, firebase, history, company.adminUser.email);
        history.push('/notificaciones');

        setTimeout(() => {
            dispatch({
                type: 'LOADING_NOTIFICATIONS_HISTORY',
                payload : {
                    loadingNotifications : false
                }
            });
        }, 1500);

        dispatch({
            type: 'LIST_NOTIFICATIONS_HISTORY',
            payload : listNotifications
        });
    }
    
    //#region Render
    return (
        <>
            {
                visible
                &&
                <div className='navbar-submenuNotificacion' ref={props.modalRef}>
                    <div className="modal-triangle"></div>
                    <div className='navbar-submenuNotificacionlista'>
                    {
                        loadingNotifications?.loadingNotifications
                        ?
                        <div className='spinner-container'>
                            <Spinner animation='border' className='spinner' role='status' style={{height: 50, width: 50}} />
                        </div>
                        :
                        <>
                            {
                                listNotifications?.length > 0
                                ?
                                listNotifications?.map((item) => <CardNotificacion key={item.id} item={item} />)
                                :
                                <h6 style={{marginTop: 90, paddingRight: 10, paddingLeft: 10, textAlign: 'center'}}>En este momento no cuentas con notificaciones disponibles</h6>
                            }
                        </>
                    }
                    </div>
                    <div className='fixed-footer'>
                        <div>
                            {
                                countNotifications?.countNotifications > 0
                                &&
                                <h6 
                                    style={{fontSize: 14, cursor: 'pointer'}}
                                    onClick={()=>FnMarcarTodasLeidas()}
                                >
                                    Marcar todas como leídas
                                </h6>
                            }
                        </div>
                        <div>
                            {
                                listNotifications?.length > 0
                                &&
                                <h6
                                    style={{fontSize: 14,cursor: 'pointer'}}
                                    onClick={()=>FnVerTodas()}
                                >
                                    Ver todas
                                </h6>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
    //#endregion
}

export default ModalNotificacion;