//#region Dependencias
import {Dispatch, SetStateAction} from 'react';
import AlternadorEmpresasPersonas from './AlternadorEmpresasPersonas'
import FormEmpresaLogin from './FormEmpresaLogin'
import LogoLogin from './LogoLogin'
//#endregion

export type LoginCombinadoProps = {
    setrecuperarContrasenaScreen : Dispatch<SetStateAction<boolean>>;
}

const LoginCombinado = (props : LoginCombinadoProps) => {
    //#region Render
    return (
        <>
            <LogoLogin />
            <AlternadorEmpresasPersonas />
            <FormEmpresaLogin setrecuperarContrasenaScreen={props.setrecuperarContrasenaScreen} />
        </>
    )
    //#endregion
}

export default LoginCombinado