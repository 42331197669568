//#region Dependencias
import {useState} from 'react';
import {Form} from 'react-bootstrap';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import '../Styles/styles.scss';
//#endregion

const TextBoxPassword = ({
    titulo,
    id,
    cantidad,
    onChange,
    tipo,
    error
}) => {
    //#region useState
    const [ver, setver] = useState(false);
    //#endregion

    //#region Functions
    const Eye=()=>{
        setver(prevState => !prevState);
    }
    //#endregion

    //#region Render
    return (
        <Form.Group style={{marginTop: 26}}>
            <Form.Label style={{fontSize:'12px', marginBottom: 7}}>{titulo}</Form.Label>
            <div className="input-text">
                <Form.Control
                    id={id}
                    type={!ver?tipo:'text'}
                    maxLength={cantidad}
                    isInvalid={error ? true : false}
                    onChange={onChange}
                    autoComplete='new-password'
                    style={{
                        border: '1px solid #0000001F'
                    }}
                />
                {
                    !ver
                    ?
                    <Visibility className='fa-eye' onClick={()=>Eye()} />
                    :
                    <VisibilityOff className='fa-eye-slash' onClick={()=>Eye()} />
                }
                {
                    error
                    &&
                    <Form.Control.Feedback type='invalid'>
                        {error}
                    </Form.Control.Feedback>
                }
            </div>
        </Form.Group>
    )
  //#endregion
}

export default TextBoxPassword;