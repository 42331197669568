import { IStorageService } from '../interfaces/storage.interface';
import { BaseStorageService } from './base-storage.service';
import firebase from 'firebase';

export class FirebaseStorageService extends BaseStorageService implements IStorageService {
	private firebaseApp: firebase.app.App;

	constructor(firebaseApp: firebase.app.App) {
		super();
		this.firebaseApp = firebaseApp;
	}

	async upload(path: string, file: File, bucketName?: string): Promise<void> {
		const storage = this.firebaseApp.storage(bucketName);
		let uploadTask = storage.ref(path);
		await uploadTask.put(file);
	}

	async delete(path: string, bucketName?: string): Promise<void> {
		const storage = this.firebaseApp.storage(bucketName);
		await storage.ref(path).delete();
	}
}
