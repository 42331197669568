import { yupResolver } from '@hookform/resolvers/yup';
import { useSettingsLogic } from '@planeo-services/logics/settings';
import { useUsersLogic } from '@planeo-services/logics/users';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFirebaseApp } from 'reactfire';
import { LoginFormModel, LoginFormModelSchema } from './form.model';
import environments from '@planeo-environments';
import { init, track, setUserId } from '@amplitude/analytics-browser';

init(environments.firebaseConfig.amplitude_api_key);

export const useHook = ({ onNeedMailValidation }: { onNeedMailValidation: () => void }) => {
	const firebase = useFirebaseApp();
	const usersLogic = useUsersLogic(firebase);
	const settingsLogic = useSettingsLogic(firebase);

	const {
		register,
		handleSubmit,
		watch,
		control,
		reset,
		formState: { errors, isValid },
	} = useForm<LoginFormModel>({
		resolver: yupResolver(LoginFormModelSchema),
		mode: 'onChange',
	});

	const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
	const [authError, setAuthError] = useState<string>('');
	const [visible, setVisible] =  useState(false);
	const onSubmit = useCallback(async () => {
		const form = watch();

		setIsSubmiting(true);
		track('INICIO SESION');
		setUserId(form.email);
		setAuthError('');

		const maxLoginRetries = await settingsLogic.getMaxLoginRetries();
		let userRetries = await usersLogic.getUserLoginRetries(form.email);

		if (userRetries >= maxLoginRetries) {
			onNeedMailValidation();
			setIsSubmiting(false);
			return;
		}
		const resp = await firebase
			.auth()
			.signInWithEmailAndPassword(form.email, form.password)
			.catch(async (e) => {
				setIsSubmiting(false);

				if (e.code === 'auth/wrong-password') {
					setVisible(true);
					userRetries = userRetries + 1;
					await usersLogic.setUserLoginRetries(form.email, userRetries);
				} else if (e.code === 'auth/too-many-requests') {
					setAuthError('Demasiados intentos fallidos');
					userRetries = maxLoginRetries;
					await usersLogic.setUserLoginRetries(form.email, maxLoginRetries);
				} else if ( e.code === 'auth/user-not-found' ) {
                	setAuthError('Verifica tu usuario y/o contraseña');
				}
			});

		if (resp) {
			await usersLogic.setUserLoginRetries(form.email, 0);
			if (!resp.user!.emailVerified) {
				setAuthError('El correo no ha sido confirmado');
				await firebase.auth().signOut();
				setIsSubmiting(false);
			} else {
				localStorage.setItem('rememberMe', String(form.rememberMe));
				localStorage.setItem('user', form.rememberMe ? form.email : '');
			}
		}
	}, [firebase, onNeedMailValidation, settingsLogic, usersLogic, watch]);

	useEffect(() => {
		if (localStorage.getItem('sessionTime')) {
			localStorage.removeItem('sessionTime');
		}

		localStorage.setItem('sessionTime', '20');
	}, []);

	useEffect(() => {
		const hasRemember = localStorage.getItem('rememberMe') === 'true';

		if (hasRemember) {
			const usermail = localStorage.getItem('user');
			reset({
				email: usermail ?? '',
				rememberMe: hasRemember,
			});
		}
	}, [reset]);

	return {
		register,
		handleSubmit,
		onSubmit,
		errors,
		control,
		isValid,
		isSubmiting,
		authError,
		visible,
		setVisible,
	};
};
