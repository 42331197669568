//#region Dependencias
import {Dispatch, SetStateAction} from 'react'
import ButtonDesign from './ButtonDesign'
import LogoLogin from './LogoLogin'
import TextBoxDesign from './TextBoxDesign'
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import { useFirebaseApp } from 'reactfire';
import {PasswordRecoveryFormModel} from '../../signup/modals/password-recovery/component/form.model'
import { AlertDialogService } from '@planeo-components/alerts-dialog';
import ImgMail from '@planeo-media/Modal/Modale_mail_Image.svg';
import { Col } from 'react-bootstrap';
//#endregion

export type RecuperarClaveSubScreenProps = {
    setrecuperarContrasenaScreen : Dispatch<SetStateAction<boolean>>;
}

const RecuperarClaveSubScreen = (props: RecuperarClaveSubScreenProps) => {
    const firebase = useFirebaseApp();
    
    //#region Validación datos
    const validationSchemaRecovery : yup.SchemaOf<PasswordRecoveryFormModel> = yup.object({
        email: yup.string()
            .required('Campo obligatorio')
            .email('Ingresa un correo v\u00E1lido')
            .typeError('Ingresa un correo v\u00E1lido')  
    });
    //#endregion

    //#region Formik
    const formik : FormikProps<PasswordRecoveryFormModel> = useFormik<PasswordRecoveryFormModel>({
        initialValues: {
            email: ''
        },
        validationSchema: validationSchemaRecovery,
        validateOnChange: false,
        onSubmit: async (formikValue, {setSubmitting}) => {
            const {email} = formikValue;
            let emailIn = email?.trim();
            setSubmitting(true);

            try {
                await firebase
                    .auth()
                    .sendPasswordResetEmail(emailIn)
                    .finally(() => {
                        setSubmitting(false);
                    });
    
                await AlertDialogService.open({
                    icon: ImgMail,
                    title: 'Mensaje enviado',
                    message: (
                        <>
                            Por favor revisa tu correo <span className='highlight'>{emailIn}</span> para continuar con el proceso.
                        </>
                    ),
                });

                props.setrecuperarContrasenaScreen(false);
            } catch (ex: unknown) {
                const error = ex as { code: string; message: string };
                let message = `Ocurrió un error inesperado: ${error.code}, por favor comunicate con el administrador.`;
    
                if (error.code === 'auth/invalid-email') message = 'Por favor ingresa una dirección de correo válida.';
                if (error.code === 'auth/user-not-found') message = `El correo ${emailIn} no se encuentra registrado.`;
                if (error.code === 'auth/too-many-requests') message = `Se han realizado demasiadas peticiones, por favor intenta mas tarde.`;
    
                await AlertDialogService.alert({
                    title: 'Mensaje No enviado',
                    message: message,
                });
            }
        }
    });
    //#endregion

    //#region Render
    return (
        <>
            <LogoLogin />
            <Col
                xs={9}
                sm={9}
                md={7}
                lg={7}
                xl={7}
            >
                <h5 style={{fontSize:20, marginTop: 20, color: '#343BA7', fontWeight: 'bold'}}>¿Olvidaste tu contraseña?</h5>
                <p style={{fontSize: 15, color: '#707070', marginBottom: 10}}>
                    Ingresa el correo electrónico con el que estás registrado, te enviaremos instrucciones para restablecer tu contraseña.
                </p>

                <TextBoxDesign
                    id='email'
                    cantidad={140}
                    titulo='Correo empresarial *'
                    key={'email'}
                    tipo='text'
                    error={formik.errors.email}
                    onChange={formik.handleChange}
                    defaultValue=''
                />

                <div
                    style={{
                        marginTop: 30,
                        textAlign: 'center',
                        alignSelf: 'center'
                    }}
                >
                    <ButtonDesign
                        variant='purple'
                        titulo='ENVIAR'
                        onClick={()=>formik.handleSubmit()}
                        cargando={formik.isSubmitting}
                        size='16px'
                    />

                    <h6 style={{color: '#343BA7', fontSize: 15, fontWeight: '700', marginTop: 15, cursor: 'pointer', marginBottom: 20}} onClick={()=>props.setrecuperarContrasenaScreen(false)}>Cancelar</h6>
                </div>
            </Col>
        </>
    )
    //#endregion
}

export default RecuperarClaveSubScreen