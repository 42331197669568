export interface CompanyContextProps {
	company: ICompany;
	fetchCompanyData: Function;
}

export interface ICompany {
	adminUser: {
		name: string;
		userUID: string;
		email: string;
		admin: boolean;
		id : string;
	};
	id: string;
	companyAccept: boolean;
	companyCIIU: 0;
	companyCity: string;
	companyName: string;
	companyNit: number;
	companyPhone: number;
	companyVerDigit: number;
	isReady: boolean;
	ProfileImageURL: string;
}

/*  Initialization of the "CompanyInit" object */
export const ICompanyInit: ICompany = {
	adminUser: {
		name: '',
		userUID: '',
		email: '',
		admin: false,
		id : ''
	},
	id: '',
	companyAccept: false,
	companyCIIU: 0,
	companyCity: '',
	companyName: '',
	companyNit: 0,
	companyPhone: 0,
	companyVerDigit: 0,
	isReady: false,
	ProfileImageURL: '',
};
