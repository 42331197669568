//#region Dependencias
import LayoutDeleteAccount from '../Components/LayoutDeleteAccount';
//#endregion

const DeleteAccountScreen = () => {
    //#region Render
    return (
        <LayoutDeleteAccount />
    )
    //#endregion
}

export default DeleteAccountScreen;