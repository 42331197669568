import { Dispatch, SetStateAction } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ImgAlert from '@planeo-media/Modal/Img_Alerta.svg';

import './styles.scss';

type ModalCredentialsErrorProps = {
	visible: boolean;
	setVisible: Dispatch<SetStateAction<boolean>>;
};

export const ModalCredentialsError = (props: ModalCredentialsErrorProps): JSX.Element => {
	return (
		<Modal
			show={props.visible}
			centered
            style={{zIndex:1000000}}
			className='modal-alert-message'
			onHide={() => {
				props.setVisible(false);
			}}>
			<Modal.Body>
				<div>
					<img src={ImgAlert} alt='Mail Logo' />
				</div>

				<div>
					<strong className='title'>Credenciales erróneas</strong>
					<p>El nombre de usuario y/o contraseña son incorrectos. Por favor intenta nuevamente.</p>
				</div>

				<div className='d-flex justify-content-center align-items-center '>
					<Button
						variant='outline-primary'
						type='button'
						className='btn Button-Primary'
						onClick={() => {
							props.setVisible(false);
						}}>
						CERRAR
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};
