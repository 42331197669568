export enum TabTypeEnum {
	company = 'empresa',
	employee = 'empleado',
}

export enum TypeFolder {
	global = 2,
	companyEmployee = 3,
	shared = 4,
}

export enum TypeCategoryTabs {
	employee = 1,
	folder = 2,
	user = 3
}

export const FnTraduccionTipoDoc = (tipoDoc ?: string) => {
	switch(Number(tipoDoc)){
		case 11:
			return 'RC';
		case 12:
			return 'TI';
		case 13:
			return 'CC';
		case 21:
			return 'TE';
		case 22:
			return 'CE';
		case 31:
			return 'NIT';
		case 41:
			return 'PT';
		case 47:
			return 'PEP';
		case 91:
			return 'NUIP';
		default:
			return 'CC';
	}
}

export enum ConversionTipoDoc {
	CC = 13
}

export const FnColorBurbuja = (tipo ?: number) => {
	switch(tipo){
		case 2:
		return "#343BA7";
		case 3:
		return "#379AF4";
		case 4:
		return "#27DEBF";
		default:
		return '#27DEBF';
	}
}