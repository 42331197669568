import fbase from 'firebase/app';
import { getMaxLoginRetries } from './get-max-login-retries';

export interface SettingsLogic {
	getMaxLoginRetries: () => Promise<number>;
}

export const useSettingsLogic = (firebase: fbase.app.App): SettingsLogic => {
	return {
		getMaxLoginRetries: getMaxLoginRetries(firebase),
	};
};
