import { ReactNode, useEffect, useState } from 'react';
import { AlertDialogProps } from '.';

export const useHook = (props: AlertDialogProps) => {
	const [visible, setVisible] = useState<boolean>(false);

	const [icon, setIcon] = useState<string>('');
	const [title, setTitle] = useState<string>('');
	const [message, setMessage] = useState<string | ReactNode>('');
	const [footer, setFooter] = useState<string | ReactNode>('');
	const [isConfirm, setIsConfirm] = useState<boolean>();

	useEffect(() => {
		const serviceSubs = props.service.subscribe((value) => {
			setVisible(value.visible);

			value.icon ? setIcon(value.icon) : setIcon('');
			value.title ? setTitle(value.title) : setTitle('');
			value.message ? setMessage(value.message) : setMessage('');
			value.footer ? setFooter(value.footer) : setFooter('');
			value.isConfirm ? setIsConfirm(value.isConfirm) : setIsConfirm(false);
		});

		return () => {
			serviceSubs.unsubscribe();
		};
	}, []);

	return {
		visible,
		icon,
		title,
		message,
		footer,
		isConfirm,
		setVisible,
	};
};
