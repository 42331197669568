//#region Dependencias
import { ModalVisorNotificationsFilesDto, NotificationsDto } from '@planeo-services/api/models/notificationsInterface';
import TextoAlternativoNegrilla from './TextoAlternativoNegrilla';
import NotificationsAPI from '@planeo-services/api/functions/NotificationsAPI';
import { useStateValue } from '@planeo-providers/Context/store';
import { useCompanyContext } from '@planeo-providers/company-provider';
import { useFirebaseApp } from 'reactfire';
import { useHistory } from 'react-router-dom';
//#endregion

export interface CardNotificacionProps {
    item : NotificationsDto
}

const CardNotificacion = (props: CardNotificacionProps) => {
    //#region Props
    const {id, view, textOptional, optionalNote, stateFile, date, urlData, urlFilenameData, detailsApp} = props?.item;
    //#endregion

    //#region Reducers
    const {dispatch} = useStateValue();
    const { company } = useCompanyContext();
    //#endregion

    //#region Hooks
	const firebase = useFirebaseApp();
	const history = useHistory();
	//#endregion

    //#region Funciones
    const FnVerNotificacion = async () => {
        if(!view){
            const resultado = await NotificationsAPI.Fn_ActivarVisualizacion(company.id, id, firebase, history, company.adminUser.email);
            if(resultado){
                NotificationsAPI.Fn_ListarNotificaciones(company.id, 20, 1, dispatch, firebase, history, company.adminUser.email);
                NotificationsAPI.Fn_ListarNotificacionesSinVer(company.id, dispatch, firebase, history, company.adminUser.email);
            }
        }

        if(urlData){
            //#region Ver archivo
            let body : ModalVisorNotificationsFilesDto = {
                abrir : true,
                fileName : urlFilenameData,
                urlFile : urlData,
                fileId : detailsApp?.fileId,
                optionalNote,
                stateFile
            }

            NotificationsAPI.Fn_AbrirVisorArchivos(body, dispatch);
            //#endregion
        }
    }
    //#endregion

    //#region Render
    return (
        <div
            key={id}
            style={{
                borderBottomWidth: 2,
                borderBottomColor: 'red',
                paddingBottom: 15,
                paddingLeft: 8,
                paddingRight: 8,
                marginBottom: 1,
                backgroundColor: view ? '#f5f5f5': '#D6E9F9',
                borderRadius: 2,
                paddingTop: 15,
                cursor: 'pointer'
            }}
            onClick={()=>FnVerNotificacion()}
        >
            <TextoAlternativoNegrilla view={view} viewGlobal={false}>{textOptional?.split('Atentamente')[0]}</TextoAlternativoNegrilla>
            <label style={{color: '#868686', cursor: 'pointer', marginLeft: 5, fontSize: 13, marginTop: 9}}>{date?.substring(0, 10)?.replace('T',' ')}</label>
        </div>
    )
    //#endregion
}

export default CardNotificacion