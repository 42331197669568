import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useFirebaseApp } from 'reactfire';
import CompanyNotVerifiedPage from '@planeo-features/shared-pages/company-not-verified';
import { PlaneoLoader } from '@planeo-components/loader';

import ImgAlert from '@planeo-media/Modal/Img_Alerta.svg';
import ImgSuccess from '@planeo-media/Modal/Img_Success.png';

export const VerifyEmailPartial = (): JSX.Element => {
	const firebase = useFirebaseApp();
	const { search } = useLocation();
	const values = queryString.parse(search);
	const code = values.oobCode != null ? values.oobCode.toString() : '';

	const [verifySuccess, setVerifySuccess] = useState<boolean | null>(null);

	useEffect(() => {
		firebase
			.auth()
			.applyActionCode(code)
			.then(() => {
				setVerifySuccess(true);
			})
			.catch((e) => {
				setVerifySuccess(false);
			});
	}, []);

	return (
		<>
			{verifySuccess === null && <PlaneoLoader />}

			{verifySuccess === true && (
				<CompanyNotVerifiedPage
					image={ImgSuccess}
					title='Tu correo ha sido verificado'
					text='Ya hemos verificado tu cuenta, ya puedes ingresar con tu clave y correo.'
					buttonText='VOLVER A PLANEO'
				/>
			)}

			{verifySuccess === false && (
				<CompanyNotVerifiedPage
					image={ImgAlert}
					title='Tu correo no pudo ser verificado'
					text='Ocurrió un error mientras verificabamos tu correo, comunicate con el administrador.'
					buttonText='VOLVER A PLANEO'
				/>
			)}
		</>
	);
};
