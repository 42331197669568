import { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
const usePasswordToggle = () => {
	const [visible, setVisible] = useState(false);

	const Icon = visible ? (
		<VisibilityOff className={'PassWord-hide-icon'} onClick={() => setVisible(!visible)} />
	) : (
		<Visibility className={'PassWord-Revele-icon'} onClick={() => setVisible(!visible)} />
	);

	const InputType = visible ? 'text' : 'password';
	return [InputType, Icon];
};

export default usePasswordToggle;
