import fbase from 'firebase/app';

const MAX_LOGIN_RETRIES = 2;

export const getMaxLoginRetries = (firebase: fbase.app.App) => {
	return async (): Promise<number> => {
		try {
			const snap = await firebase.firestore().collection('settings').doc('0').get();
			return snap.data()?.loginRetries ?? MAX_LOGIN_RETRIES;
		} catch {
			return MAX_LOGIN_RETRIES;
		}
	};
};
