import { useHistory, useLocation } from 'react-router-dom';

export const useHook = () => {
	const history = useHistory();
	const location = useLocation();

	const goToLink = (link: string) => {
		history.push(link);
	};

	return {
		goToLink,
		location,
	};
};
