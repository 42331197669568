import { useRef } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import environments from '@planeo-environments';
import { Controller } from 'react-hook-form';
import usePasswordToggle from '@planeo-components/usePassWordToggle';

import { useHook } from './hook';

import './styles.scss';
import { ModalCredentialsError } from '../modal-credentials-error';

export type LoginFormProps = {
	onNeedMailValidation: () => void;
	onPasswordForgot: () => void;
};

export const LoginForm = ({ onNeedMailValidation, onPasswordForgot }: LoginFormProps): JSX.Element => {
	const captcha = useRef<ReCAPTCHA>(null);
	const hook = useHook({ onNeedMailValidation });
	const [InputType, IconType] = usePasswordToggle();

	return (
		<>
			<Form onSubmit={hook.handleSubmit(hook.onSubmit)} id='planeo-login-form'>
				<Form.Group>
					<Form.Label>Correo electrónico *</Form.Label>
					<Form.Control {...hook.register('email')} className={hook.errors.email && 'validation-error'} type='text' placeholder='' />
					<Form.Text>{hook.errors.email?.message}</Form.Text>
				</Form.Group>

				<Form.Group className='input-icon'>
					<Form.Label>Contraseña *</Form.Label>
					<Form.Control
						{...hook.register('password')}
						className={hook.errors.password && 'validation-error'}
						type={String(InputType)}
						placeholder=''
						autoComplete={'false'}
					/>
					<span className={'icon'}>{IconType}</span>
					<Form.Text>{hook.errors.password?.message}</Form.Text>
				</Form.Group>

				<div className='lineal-form-control'>
					<Form.Group>
						<Form.Check type='checkbox' placeholder=''>
							<Form.Check.Input type='checkbox' {...hook.register('rememberMe')} />
							<Form.Check.Label>Recuérdame</Form.Check.Label>
						</Form.Check>
						<Form.Text>{hook.errors.rememberMe?.message}</Form.Text>
					</Form.Group>

					<span className='link' onClick={onPasswordForgot}>
						Olvidé mi contraseña
					</span>
				</div>

				<Form.Group>
					<Controller
						control={hook.control}
						name='captcha'
						render={({ field: { onChange } }) => (
							<ReCAPTCHA className='captcha' ref={captcha} sitekey={environments.firebaseConfig.captcha} onChange={onChange} />
						)}
					/>
					<Form.Text>{hook.errors.captcha?.message}</Form.Text>
				</Form.Group>

				<div className='form-submit-btn'>
					<Button className='btn-outline' type='submit' disabled={!hook.isValid}>
						{hook.isSubmiting ? <Spinner className='spinner' animation='border' role='status' /> : 'INGRESAR'}
					</Button>
					<Form.Text>{hook.authError}</Form.Text>
				</div>
			</Form>
			<ModalCredentialsError visible={hook.visible} setVisible={hook.setVisible} />
		</>
	);
};
