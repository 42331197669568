import { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { useFirebaseApp, useUser } from 'reactfire';
import { ICompany, ICompanyInit, CompanyContextProps } from './company-context.interface';

const CompanyContext = createContext({} as CompanyContextProps);

export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyDataProvider = ({ children }: { children: ReactNode }) => {
	const firebase = useFirebaseApp();
	const { data: user } = useUser();
	const [data, setData] = useState<ICompany>(ICompanyInit);

	const fetchCompanyData = async () => {
		try {
			if (user.email) {
				const userData = await firebase
					.firestore()
					.collection('users')
					.where('email', '==', user.email)
					.get()
					.then((snapshot) => {
						return snapshot.docs[0];
					});

				const userDataId = userData.id;
				const userDataInfo = userData.data();

				const companyObject2 = await userDataInfo.company
					.get()
					.then((snapshot) => {
						return [snapshot.data(), snapshot.id];
					})
					.then(async ([dataComp, companyId]) => {
						let companyObject: ICompany = ICompanyInit;

						if (dataComp) {
							companyObject = {
								adminUser: {
									name: userDataInfo.name,
									userUID: userDataInfo.uid,
									email: userDataInfo.email,
									admin: userDataInfo.admin,
									id : userDataId
								},
								id: companyId,
								companyAccept: dataComp.companyAccept,
								companyCIIU: dataComp.companyCIIU,
								companyCity: dataComp.companyCity,
								companyName: dataComp.companyName,
								companyNit: dataComp.companyNit,
								companyPhone: dataComp.companyPhone,
								companyVerDigit: dataComp.DV,
								isReady: false,
								ProfileImageURL: dataComp.ProfileImageURL,
							};
						}
						return companyObject;
					});

				setData({ ...companyObject2, isReady: true });
			}
		} catch (e: unknown) {
			console.log(e);
			alert('Error al obtener información de la empresa: ' + JSON.stringify(e));
		}
	};

	useEffect(() => {
		fetchCompanyData();
	}, []);

	return (
		<CompanyContext.Provider
			value={{
				company: data,
				fetchCompanyData,
			}}>
			{children}
		</CompanyContext.Provider>
	);
};
