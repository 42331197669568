import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useHook } from './hook';

import { Suspense } from 'react';

import { PlaneoDashboard } from '@planeo-layouts/Dashboard';
import { CompanyDataProvider } from '@planeo-providers/company-provider';

import { NotFoundPage } from '@planeo-features/shared-pages/page-not-found';

import { AuthRoutes } from '@planeo-features/auth';
import { AppRouter } from './router';

import './styles.scss';
import { PlaneoLoader } from '@planeo-components/loader';
import { StateProvider } from '@planeo-providers/Context/store';

const App = () => {
	const hook = useHook();

	return (
		<Router>
			<Switch>
				<Route path={'/404'} component={NotFoundPage} />

				<Route path='/'>
					<div className='App'>
						{hook.user === undefined && <PlaneoLoader />}

						{hook.user !== undefined && (
							<Suspense fallback={<PlaneoLoader />}>
								{(!hook.user || !hook.user.emailVerified) && <AuthRoutes />}
								{hook.user && hook.user.emailVerified && (
									<CompanyDataProvider>
										<StateProvider>
											<PlaneoDashboard>
												<AppRouter />
											</PlaneoDashboard>
										</StateProvider>
									</CompanyDataProvider>
								)}
							</Suspense>
						)}
					</div>
				</Route>
			</Switch>
		</Router>
	);
};

export default App;
