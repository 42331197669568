//#region Dependencias
import {Col} from 'react-bootstrap';
import background from '@planeo-media/not-verify-icons/fullpage.svg';
import { useHistory } from "react-router-dom";
import ButtonDesign from './ButtonDesign';
//#endregion

const BannerIzquierdoRegistrarEmpresa = () => {
    //#region Hooks
    let history = useHistory();
    //#endregion

    //#region Render
    return (
        <Col
            sm={12}
            md={12} 
            lg={12}
            xl={7}
            xs={12}
            style={{
                backgroundImage: "url(" + background + ")",
                backgroundPosition: 'top',
                backgroundPositionY: 'top',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                color: '#FFF',
                textAlign: 'center',
                display: 'flex',
                height: '100vh',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Col
                xs={10}
                sm={10}
                md={8}
                lg={6}
                xl={6}
            >
                <h2 style={{
                    textAlign: 'center',
                    font: 'normal normal bold 36px Nunito',
                    letterSpacing: '0px',
                    color: '#FFFFFF',
                    textTransform: 'uppercase',
                    opacity: 1,
                    fontWeight: '800',
                    fontSize: 36,
                    marginTop: 1
                    }}
                >
                    REGISTRA TU EMPRESA
                </h2>

                <p className='tituloLogin'>
                Entiende y administra la relación salarial con tus empleados a partir de servicios basados en información confiable, actualizada y segura.
                </p>

                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <ButtonDesign
                        titulo={'REGISTRAR'}
                        variant='primary'
                        onClick={()=>history.push('/SignUp')}
                        size='16px'
                        cargando={false}
                    />
                </div>
            </Col>
        </Col>
    )
    //#endregion
}

export default BannerIzquierdoRegistrarEmpresa;