//#region Dependencias
import { ContadorNotificacionesDto, ContadorNotificacionesTotalDto, LoadingNotificationsDto, ModalNotificacionesDto, ModalVisorNotificationsFilesDto, NotificationsList } from "@planeo-services/api/models/notificationsInterface";
//#endregion

export const modalNotificacionesDto :ModalNotificacionesDto = {
    visible : false
}

export const contadorNotificacionesDto : ContadorNotificacionesDto = {
    countNotifications : 0
}

export const contadorNotificacionesTotalDto : ContadorNotificacionesTotalDto = {
    countNotificationsTotal : 0
}

export const loadingNotificationsDto : LoadingNotificationsDto = {
    loadingNotifications : false
}

export const modalVisorNotificationsFilesDto : ModalVisorNotificationsFilesDto = {
    abrir : false,
    fileName : '',
    urlFile : '',
    fileId : '',
    optionalNote : '',
    stateFile : 0
}

export const loadingNotificationsHistoryDto : LoadingNotificationsDto = {
    loadingNotifications : false
}

export const notifications : NotificationsList = {
    modalNotificaciones : modalNotificacionesDto,
    listNotifications : [],
    countNotifications : contadorNotificacionesDto,
    countNotificationsTotal : contadorNotificacionesTotalDto,
    loadingNotifications : loadingNotificationsDto,
    modalVisorArchivosNotifications : modalVisorNotificationsFilesDto,
    loadingNotificationsHistory : loadingNotificationsHistoryDto,
    listNotificationsHistory : []
}