//#region Dependencias
import LogoWhite from '@planeo-media/not-verify-icons/logo_planeo.svg';
//#endregion

const LogoLogin = () => {
    //#region Render
    return (
        <img src={LogoWhite} 
            style={{
                marginTop: 20,
                alignSelf: 'center',
                height: '180px'
            }}
            alt='Logo Login'
        />
    )
    //#endregion
}

export default LogoLogin