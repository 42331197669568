import { IStorageService } from '../interfaces/storage.interface';

export class BaseStorageService implements IStorageService {
	upload(path: string, file: File, bucketName?: string): Promise<void> {
		throw new Error('Method not implmented');
	}

	delete(path: string, bucketName?: string): Promise<void> {
		throw new Error('Method not implmented');
	}
}
