//#region Dependencias
import { useState, Dispatch, SetStateAction, useRef, useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import TextBoxDesign from "./TextBoxDesign";
import { LoginInterfaceModel } from '../Interfaces/LoginInterface';
import TextBoxPassword from './TextBoxPassword';
import CheckBoxDesign from './CheckBoxDesign';
import ButtonDesign from './ButtonDesign';
import { useFirebaseApp } from 'reactfire';
import environments from '@planeo-environments';
import { init, setUserId, track } from '@amplitude/analytics-browser';
import { useSettingsLogic } from '@planeo-services/logics/settings';
import { useUsersLogic } from '@planeo-services/logics/users';
import { LoginModalRetries } from '@planeo-features/auth/signup/modals/login/partials/modal-retries';
import {Col, Form} from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoginFormModel } from '@planeo-features/auth/signup/modals/login/partials/form/form.model';
//#endregion

init(environments.firebaseConfig.amplitude_api_key);

export type FormEmpresaLoginProps = {
    setrecuperarContrasenaScreen : Dispatch<SetStateAction<boolean>>;
}

const FormEmpresaLogin = (props: FormEmpresaLoginProps) => {
    //#region Hooks
    const firebase = useFirebaseApp();
	const usersLogic = useUsersLogic(firebase);
	const settingsLogic = useSettingsLogic(firebase);
    const captcha = useRef<ReCAPTCHA>(null);
    //#endregion

    //#region Validación datos
    const validationSchemaSignIn : yup.SchemaOf<LoginInterfaceModel> = yup.object({
        email: yup.string()
            .required('Campo obligatorio')
            .email('Ingresa un correo v\u00E1lido')
            .typeError('Ingresa un correo v\u00E1lido'),
        password: yup.string()
            .required('La contrase\u00F1a es requerida')
            .typeError('La contrase\u00F1a no es v\u00E1lida'),
        rememberMe : yup.boolean()
            .required('El check recuérdame es requerido')
            .typeError('Valida el check de recuerdame'),
        captcha: yup.string().trim().required('El captcha requerido').nullable(true)
    });
    //#endregion

    let userLocalStorage = localStorage.getItem('user') ?? '';

    //#region Formik
    const formik: FormikProps<LoginFormModel> = useFormik<LoginFormModel>({
        initialValues: {
            email: userLocalStorage,
            password: '',
            rememberMe : userLocalStorage !== '',
            captcha : null
        },
        validationSchema: validationSchemaSignIn,
        validateOnChange: false,
        onSubmit: async (formikValue, {setSubmitting}) => {
            const {email, password, rememberMe} = formikValue;
            setSubmitting(true);
            track('INICIO SESION');
		    setUserId(email);

            const maxLoginRetries = await settingsLogic.getMaxLoginRetries();
            let userRetries = await usersLogic.getUserLoginRetries(email);

            if (userRetries >= maxLoginRetries) {
                setVisibleModal(true);
                setTituloModal('Tu cuenta ha sido bloqueada');
                setSubtituloModal('Por seguridad hemos bloqueado tu cuenta después de varios intentos fallidos de iniciar sesión. Recupera tu contraseña para poder ingresar.')
                setTituloboton('Recuperar contraseña');
                setSubmitting(false);
                return;
            }
            
            const resp = await firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .catch(async (e) => {
                    setSubmitting(false);
                    setVisibleModal(true);
                    setTituloModal('Verifica tu información');
                    setTituloboton('Aceptar');
                    if (e.code === 'auth/wrong-password') {
                        setSubtituloModal('Las credenciales ingresadas son incorrectas');
                        userRetries = userRetries + 1;
                        await usersLogic.setUserLoginRetries(email, userRetries);
                    } else if (e.code === 'auth/too-many-requests') {
                        setSubtituloModal('Demasiados intentos fallidos');
                        userRetries = maxLoginRetries;
                        await usersLogic.setUserLoginRetries(email, maxLoginRetries);
                    } else if ( e.code === 'auth/user-not-found' ) {
                        setSubtituloModal('Verifica tu usuario y/o contraseña');
                    }
                });

            if (resp) {
                let tokenAuth = await resp.user?.getIdToken();
                if(tokenAuth){
                    localStorage.setItem('TokenAPI', 'Bearer '+tokenAuth);
                }

                await usersLogic.setUserLoginRetries(email, 0);
                if (!resp.user!.emailVerified) {
                    setVisibleModal(true);
                    setTituloModal('Verifica tu información');
                    setTituloboton('Aceptar');
                    setSubtituloModal('El correo no ha sido confirmado');
                    await firebase.auth().signOut();
                    setSubmitting(false);
                } else {
                    localStorage.setItem('rememberMe', String(rememberMe));
                    localStorage.setItem('user', rememberMe ? email : '');
                }
            }
        }
    });
    //#endregion

    //#region Funciones
    //#region Fn_OlvideClave
    const Fn_OlvideClave = () => {
        props.setrecuperarContrasenaScreen(true)
    }
    //#endregion

    //#region Fn_Accion
    const Fn_Accion = () => {
        setVisibleModal(false);
    }
    //#endregion
    //#endregion

    //#region useState
    const [visibleModal, setVisibleModal] = useState(false);
    const [tituloModal, setTituloModal] = useState('');
    const [subtituloModal, setSubtituloModal] = useState('');
    const [tituloboton, setTituloboton] = useState('');
    //#endregion

    useEffect(() => {
		if (localStorage.getItem('sessionTime')) {
			localStorage.removeItem('sessionTime');
		}

		localStorage.setItem('sessionTime', '20');
	}, []);

    //#region Render
    return (
        <>
            <Col
                xs={9}
                sm={9}
                md={7}
                lg={7}
                xl={7}
            >
                <h5 style={{fontWeight:'bold',fontSize:18,marginBottom:10}}>Ingresa los datos de tu empresa</h5>

                <TextBoxDesign
                    id='email'
                    cantidad={140}
                    titulo='Correo empresarial *'
                    key={'email'}
                    tipo='text'
                    error={formik.errors.email}
                    onChange={formik.handleChange}
                    defaultValue={userLocalStorage}
                />

                <TextBoxPassword
                    id='password'
                    cantidad={25}
                    titulo='Contraseña *'
                    key={'password'}
                    tipo='password'
                    error={formik.errors.password}
                    onChange={formik.handleChange}
                />

                <div style={{display:'flex',justifyContent:'space-between', marginTop: 15}}>
                    <div>
                        <CheckBoxDesign
                            id='rememberMe'
                            titulo='Recuérdame'
                            onChange={formik.handleChange}
                            valor={localStorage.getItem('user') ? true : formik.values.rememberMe}
                            tamanio='16px'
                        />
                    </div>
                    <div>
                        <h5 style={{color: '#379AF4', fontSize: 15, cursor: 'pointer', marginTop: 5}} onClick={()=>Fn_OlvideClave()}>Olvidé mi contraseña</h5>
                    </div>
                </div>

                <div
                    style={{
                        textAlign: 'center',
                        alignSelf: 'center',
                        width: '120%',
                        marginLeft: '-10%'
                    }}
                >
                    <Form.Group
                        style={{
                            marginTop: 28,
                            textAlign: 'center'
                        }}
                    >
                        <ReCAPTCHA className='g-recaptcha' id='captcha' ref={captcha} sitekey={environments.firebaseConfig.captcha} onChange={(campo: string)=>formik.setFieldValue('captcha', campo)} />
                        {
                            formik.errors.captcha
                            &&
                            <>
                                <br />
                                <Form.Text className='text-danger'>{formik.errors.captcha}</Form.Text>
                            </>
                        }
                    </Form.Group>
                    
                    <ButtonDesign
                        titulo={'INGRESAR'}
                        variant='outline-primary'
                        onClick={()=> formik.handleSubmit()}
                        cargando={formik.isSubmitting}
                        size='16px'
                    />
                </div>
            </Col>

            <LoginModalRetries
                visible={visibleModal}
                setVisible={setVisibleModal}
                titulo={tituloModal}
                subtitulo={subtituloModal}
                boton={tituloboton}
                accion={()=>Fn_Accion()}
            />
        </>
    )
    //#endregion
}

export default FormEmpresaLogin;