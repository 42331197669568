import { ReactNode } from 'react';
import { file_load_single_svg } from '@planeo-assets';
import { UploaderFilesInfoBase } from '../base';

import './styles.scss';
import { IUpLoadableFile } from '@planeo-components/file-uploader/defs';

export class UploaderFilesInfoList extends UploaderFilesInfoBase {
	getFileSize(size: number): string {
		if (size / 1024 > 1024) {
			return Math.round(size / 1024 / 1024) + ' Mb';
		}

		return Math.round(size / 1024) + ' Kb';
	}

	getFileHasError(file: IUpLoadableFile): boolean {
		return file.errors.length !== 0;
	}

	getFileError(file: IUpLoadableFile): string {
		if (!this.getFileHasError(file)) return '';

		if (file.errors[0].code === 'file-invalid-type') return 'Formato de archivo erroneo';
		if (file.errors[0].code === 'file-too-large') return 'El archivo supera el tamaño limite';
		return file.errors[0].code;
	}

	onDeleteFile(file: IUpLoadableFile) {
		if (this.props.onDeleteFile) this.props.onDeleteFile(file);
	}

	render(): ReactNode {
		return (
			<div className='file-uploader-info-list'>
				{(this.props.files ?? []).map((file, index) => (
					<div className='file-card' key={index}>
						<img src={file_load_single_svg} alt='Load file icon' />
						<div className='file-card-content'>
							<span className={`file-name ${this.getFileHasError(file) ? 'text-error' : ''}`}>{file.file.name}</span>
							<span className='file-size'>{this.getFileSize(file?.file?.size ?? 0)}</span>
							{this.getFileHasError(file) && <span className={this.getFileHasError(file) ? 'text-error ' : ''}>{this.getFileError(file)}</span>}
						</div>
						<div>
							<span>
								<i className='bi bi-x close-button' onClick={() => this.onDeleteFile(file)} />
							</span>
						</div>
					</div>
				))}
			</div>
		);
	}
}
