//#region Dependencias
import { ActionsGeneral } from "../ActionTypes"
import {NewTypes} from '../ActionTypes/Actions_Interfaces';
import { Action_Documents } from "../ActionTypes/DocumentsActions";
import { Action_Notifications } from "../ActionTypes/NotificacionesActions";
import sesionDocumentsReducer from "./sesionDocumentsReducer";
import sesionNotificationsReducer from "./sesionNotificationsReducer";
//#endregion

export const mainReducer = ({
    sesionDocuments,
    sesionNotifications
}: NewTypes, action: ActionsGeneral) => {
    return {
        sesionDocuments : sesionDocumentsReducer(sesionDocuments, action as Action_Documents),
        sesionNotifications : sesionNotificationsReducer(sesionNotifications, action as Action_Notifications)
    }
}