import { Collapse } from 'react-bootstrap';
import { AlertToastProps } from '../defs/props';
import { useHook } from './hook';

import './styles.scss';

export const AlertToast = (props: AlertToastProps): JSX.Element => {
	const hook = useHook(props);

	return (
		<Collapse in={hook.visible}>
			<div className={`planeo-alert-toast-component ${hook.color}`}>
				<div className='alert-message'>
					<span>{hook.message}</span>
				</div>
				<div className='close-button' onClick={hook.handleCloseClicked}>
					<i className='bi bi-x' />
				</div>
			</div>
		</Collapse>
	);
};
