//#region Dependencias
import LogoLogin from '@planeo-features/auth/login/Components/LogoLogin';
import { Col, Row } from 'react-bootstrap';
import BannerDerechoRegistro from './BannerDerechoRegistro';
import BtnVolver from './BtnVolver';
import FormPersonaEliminarCuenta from './FormPersonaEliminarCuenta';
//#endregion

const LayoutDeleteAccount = () => {
    //#region Render
    return (
        <Row>
                <Col
                    md={8}
                    style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#FFF'
                    }}
                >
                    <BtnVolver />
                    <LogoLogin />
                    <FormPersonaEliminarCuenta />
                </Col>
                <BannerDerechoRegistro />
            </Row>
    )
    //#endregion
}

export default LayoutDeleteAccount;