import { useHistory } from 'react-router-dom';
import { useFirebaseApp } from 'reactfire';
import { PlaneoNavbarSubmenuProps } from './index';

export const useHook = (props: PlaneoNavbarSubmenuProps) => {
	const history = useHistory();
	const firebase = useFirebaseApp();

	const handleGoToProfile = () => {
		history.push('/config_cuenta');
		props.setVisible(false);
	};

	const handleShowPolitics = () => {
		props.setVisible(false);
	};

	const handleShowConditions = () => {
		props.setVisible(false);
	};

	const handleShowHelp = () => {
		props.setVisible(false);
	};

	const handleLogout = async () => {
		await firebase.auth().signOut();
		localStorage.removeItem('verificationNotify');
		localStorage.removeItem('TokenAPI');
		history.push('/');
	};

	return {
		handleGoToProfile,
		handleShowConditions,
		handleShowHelp,
		handleShowPolitics,
		handleLogout,
	};
};
