//#region Dependencias
import environments from '@planeo-environments';
import { HttpClienteConsumoAPI_Get, HttpClienteConsumoAPI_Post } from "../HttpClient";
import { CategoryByCompanyDto, ResponseGenericDto, TagsAsignarCategoriesModel, TagsAsignarUsuarioModel, TagsByCategoriesCompanyListModel, TagsByCategoriesDto, TagsSearchModel } from '../models/documentsModelsInterface';
//#endregion

export const GetListCategoriesByCompany_Get = (id : string) => HttpClienteConsumoAPI_Get<CategoryByCompanyDto[]>(environments.firebaseConfig.urlPlaneoApiTags + 'listcategoriesbycompany?id=' + id);

//#region Folders
export const GetAssignTagByFolder_Post = (body : TagsAsignarCategoriesModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiTags + 'assigntagbyfolder', body);
export const GetUnassignTagByFolder_Post = (body : TagsAsignarCategoriesModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiTags + 'unassigntagbyfolder', body);
export const GetListTagsByCategoriesAndCompany_Post = (body : TagsByCategoriesCompanyListModel) => HttpClienteConsumoAPI_Post<TagsByCategoriesDto[]>(environments.firebaseConfig.urlPlaneoApiTags + 'listtagsbycategories', body);
//#endregion

//#region Users
export const GetListTagsByUser_Post = (body : TagsSearchModel) => HttpClienteConsumoAPI_Post<TagsByCategoriesDto[]>(environments.firebaseConfig.urlPlaneoApiTags + 'gettagsbyuser', body);
export const GetAssignTagByUser_Post = (body : TagsAsignarUsuarioModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiTags + 'assigntagbyuser', body);
export const GetUnassignTagByUser_Post = (body : TagsAsignarUsuarioModel) => HttpClienteConsumoAPI_Post<ResponseGenericDto>(environments.firebaseConfig.urlPlaneoApiTags + 'unassigntagbyuser', body);
export const GetListTagsByCategoriesAndCompanyUsers_Post = (body : TagsByCategoriesCompanyListModel) => HttpClienteConsumoAPI_Post<TagsByCategoriesDto[]>(environments.firebaseConfig.urlPlaneoApiTags + 'gettagsbyuser', body);
//#endregion