//#region Dependencias
import { Button } from 'react-bootstrap';
import { logo_white } from '@planeo-assets';
import MainBackgroud from '@planeo-media/backgrounds/fullpage.svg';
import './styles.scss';
import {useHistory} from 'react-router-dom';
//#endregion

export type BannerSectionProps = {
	onLoginClicked: () => void;
};

export const BannerSection = (props: BannerSectionProps): JSX.Element => {
	//#region Hooks
	const history = useHistory();
	//#endregion
	
	return (
		<div className='planeo-signup-page-banner' style={{ backgroundImage: `url(${MainBackgroud})` }}>
			<img className='main-logo' src={logo_white} alt='planeo logo' />
			<p>Entiende y administra la relación salarial con tus empleados a partir de servicios basados en información confiable, actualizada y segura.</p>
			<Button className='btn-outline-contrast' onClick={()=>history.push('/')}>
				INICIAR SESIÓN
			</Button>
		</div>
	);
};
