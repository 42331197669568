import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { logo_white_small } from '@planeo-assets';
import backgroundImage from '@planeo-media/backgrounds/fullpage.svg';
import './styles.scss';

export const NotFoundPage = (): JSX.Element => {
	return (
		<div className='planeo-not-found-page'>
			<div className='background-image' style={{ backgroundImage: `url(${backgroundImage})` }}></div>
			<div></div>
			<div className='page-info'>
				<div>
					<div className='error-number'>
						<span>404</span>
					</div>
					<div className='error-message'>
						<span>Parece que esta página no existe</span>
					</div>
				</div>

				<Link to={'/'}>
					<Button>IR AL HOME</Button>
				</Link>
			</div>

			<div className='logo-footer'>
				<img src={logo_white_small} alt='logo mareigua' />
			</div>
		</div>
	);
};
