import { Subject } from 'rxjs';
import { alertType } from '../defs/alert-types';
import { defaultAlertToastProps } from '../defs/props';

export type AlertData = {
	message: string;
	timeout?: number;
	visible: boolean;
	color?: 'error' | 'warn' | 'info' | 'success';
};

type AlertComponent = {
	id: string;
	onChange: Subject<AlertData>;
};

export class AlertService {
	private static components: AlertComponent[] = [];

	static registerAlertComponent = (id?: string) => {
		const _id = id ?? defaultAlertToastProps.id;

		const instance: AlertComponent = {
			id: _id,
			onChange: new Subject(),
		};

		this.components.push(instance);

		return instance.onChange.asObservable();
	};

	static unregisterAlertComponent = (id?: string) => {
		const instance = this.getInstace(id);
		instance?.onChange.complete();
		this.components = [...this.components.filter((x) => x.id !== instance?.id)];
	};

	private static getInstace(id?: string): AlertComponent | undefined {
		const _id = id ?? defaultAlertToastProps.id;
		return this.components.find((x) => x.id === _id);
	}

	static success(message: string, options) {
		this.alert({ ...options, type: alertType.success, message, color: 'success' });
	}

	static error(message: string, options) {
		this.alert({ ...options, type: alertType.error, message, color: 'error' });
	}

	static info(message: string, options) {
		this.alert({ ...options, type: alertType.info, message, color: 'info' });
	}

	static warn(message: string, options) {
		this.alert({ ...options, type: alertType.warn, message, color: 'warn' });
	}

	private static alert(alert) {
		alert.visible = true;
		const instance = this.getInstace(alert.id);
		instance?.onChange.next(alert);
	}

	static clear(id?: string) {
		const instance = this.getInstace(id);
		instance?.onChange.next({ message: '', visible: false });
	}
}
