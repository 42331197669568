import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useHook = () => {
	const { search } = useLocation();
	const values = queryString.parse(search);

	const [viewMode, setViewMode] = useState<'verify' | 'recovery' | null>(null);

	useEffect(() => {
		if (values.mode === 'verifyEmail') {
			setViewMode('verify');
		} else {
			setViewMode('recovery');
		}
	}, []);

	return {
		viewMode,
	};
};
