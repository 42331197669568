//#region Dependencias
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useGlobalState } from 'state-pool';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdMenu } from 'react-icons/md';
import Avatar from 'react-avatar';
import { PlaneoNavbarSubmenu } from '../navbar-submenu';
import { useHook } from './hook';
import { useCompanyContext } from '@planeo-providers/company-provider';
import LogoBlack from '@planeo-media/Logo_black_Small.svg';
import Campana from '@planeo-media/campana.png';
import CampanaRoja from '@planeo-media/campanarojo.png';
import './styles.scss';
import { useStateValue } from '@planeo-providers/Context/store';
import ModalNotificacion from './Modales/ModalNotificacion';
import NotificationsAPI from '@planeo-services/api/functions/NotificationsAPI';
import { useFirebaseApp } from 'reactfire';
import { useHistory } from 'react-router-dom';
import ModalVisorArchivos from './Modales/ModalVisorArchivos';
//#endregion

//#region Props
export type PlaneoDashboardNavbarProps = {
	isMenuIconVisible: boolean;
	isSubmenuVisible: boolean;
	setIsSubmenuVisible: Dispatch<SetStateAction<boolean>>;
	onMenuIconClicked: () => void;
};
//#endregion

export const PlaneoDashboardNavbar = (props: PlaneoDashboardNavbarProps): JSX.Element => {
	const { company } = useCompanyContext();
	const firebase = useFirebaseApp();
	const history = useHistory();
	//#region Reducers
	const {state, dispatch} = useStateValue();
	const {modalNotificaciones, countNotifications} = state?.sesionNotifications || {countNotifications: {countNotifications: 0}, modalNotificaciones: {visible: false}};
	//#endregion
	const [avatar] = useGlobalState('avatar');

	const hook = useHook();

	//#region Refs
	const bellButtonRef = useRef<HTMLImageElement>(null);
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
        if (modalNotificaciones.visible) {
            const bellRect =bellButtonRef.current?.getBoundingClientRect();
			modalRef.current!.style.display = 'none';
            modalRef.current!.style.right = `${window.innerWidth - bellRect!.right}px`;

			modalRef.current!.style.transitionDelay = '5s, 4ms';
			modalRef.current!.style.display = 'block';
        }
    }, [modalNotificaciones.visible]);
	//#endregion

	const [carga, setcarga] = useState(true);

	return (
		<>
			<nav className='planeo-dashboard-navbar'>
				<div className='navbar-container'>
					{props.isMenuIconVisible && (
						<div className='md-menu'>
							<MdMenu onClick={props.onMenuIconClicked} />
						</div>
					)}

					<div className='navbar-brand' onClick={hook.handleGoToHome}>
						<img src={LogoBlack} alt='Planeo Logo' />
					</div>

					<div className='navbar-profile'>
						<img
							ref={bellButtonRef}
							className='bell-icon'
							src={countNotifications.countNotifications > 0 ? CampanaRoja : Campana }
							alt='Campana'
							style={{marginRight: 10, height: 24, width: 24}}
							onClick={()=>{
								setcarga(!carga);
								NotificationsAPI.Fn_AbrirModalNotificaciones(carga, company?.id, dispatch, firebase, history, ()=>props.setIsSubmenuVisible(false), company.adminUser.email);
							}}
						/>
						<div className='navbar-profile' onClick={() =>{ 
							props.setIsSubmenuVisible(!props.isSubmenuVisible);
							dispatch({
								type: 'MODAL_NOTIFICATIONS',
								payload : {
									visible : false
								}
							});
						}}>
							<Avatar name={company.companyName} size='38' round={true} src={avatar} />
							<span>{company.companyName}</span>
							<div className='md-keyboard-arrow-down'>
								{props.isSubmenuVisible && <MdKeyboardArrowUp />}
								{!props.isSubmenuVisible && <MdKeyboardArrowDown />}
							</div>
						</div>
					</div>
				</div>

				<PlaneoNavbarSubmenu visible={props.isSubmenuVisible} setVisible={props.setIsSubmenuVisible} />
				<ModalNotificacion modalRef={modalRef} carga={carga} setcarga={setcarga} />
				<ModalVisorArchivos />
			</nav>		
		</>
	);
};
