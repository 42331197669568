//#region Dependencias
import { DocumentsInfoSelection, DocumentsList, DocumentsListFoldersAndFilesDto, DocumentsListFoldersAndFilesSubfoldersMiniDto, DocumentsListFoldersAndFilessubfoldersDto, DocumentsListarDetinatariosModal, DocumentsLoading, DocumentsSeccionTabEscogidoModel, DocumentsTiposOrdenamientoModel, ModalReportDto, ModalTagsByFolder, TagsByCategoriesListDto } from "@planeo-services/api/models/documentsModelsInterface";
import { ModalUserManagementCreateModel } from "@planeo-services/api/models/usersManagementInterface";
//#endregion

export const documentsListFoldersAndFilessubfoldersDto : DocumentsListFoldersAndFilessubfoldersDto = {
    routeElement : '',
    subFolders : [],
    referenciaPeople : '',
    files : []
}

export const documentsListFoldersAndFilesDto : DocumentsListFoldersAndFilesDto = {
    blocked : false,
    classification : 0,
    companyActionCreate : false,
    companyActionDelete :false,
    companyId : '',
    createdAt : '',
    delete : false,
    deleteDate : '',
    id : '',
    isDefault : false,
    name : '',
    parentId : '',
    peopleActionCreate : false,
    peopleActionDelete : false,
    peopleId : '',
    shared : false,
    subfolders : documentsListFoldersAndFilessubfoldersDto
}

export const documentsLoading : DocumentsLoading = {
    loading : false
}

export const documentsInfoSelection : DocumentsInfoSelection = {
    document : '',
    documentType : 0,
    id : '',
    name : ''
}

export const documentsListarDetinatariosModal : DocumentsListarDetinatariosModal = {
    abrir : false,
    listado : [],
    folderId : ''
}

export const documentsReportViewerFilesEmployeesModal : ModalReportDto = {
    dataInfo : [],
    folder : documentsListFoldersAndFilesDto,
    open : false
}

export const modalTagsByFolder : ModalTagsByFolder = {
    open : false,
    folderId : '',
    folderName : '',
    tipo : 0
}

export const tagsByCategoriesListDto : TagsByCategoriesListDto = {
    items : [],
    name : ''
}

export const modalUserManagementCreateModel : ModalUserManagementCreateModel = {
    admin : false,
    collaboratorId : '',
    company : '',
    email : '',
    name : '',
    open : false
}

export const documentsListFoldersAndFilesSubfoldersMiniDto : DocumentsListFoldersAndFilesSubfoldersMiniDto = {
    blocked : false,
    classification : 0,
    companyActionCreate : false,
    companyActionDelete : false,
    companyId : '',
    createdAt : '',
    delete : false,
    deleteDate : '',
    id : '',
    isDefault : false,
    name : '',
    parentId : '',
    peopleActionCreate : false,
    peopleActionDelete : false,
    peopleId : '',
    shared : false
}

export const documentsTiposOrdenamientoModel : DocumentsTiposOrdenamientoModel = {
    tipoOrdenamiento : 1
}

export const documentsSeccionTabEscogidoModel : DocumentsSeccionTabEscogidoModel = {
    vista : 1
}

export const documents : DocumentsList = {
    listFoldersFiles : documentsListFoldersAndFilesDto,
    listPeopleMailbox : [],
    loading : documentsLoading,
    listFoldersPrincipal : [],
    listSideBar : [],
    loadingSideBar : documentsLoading,
    employeeSelection : documentsInfoSelection,
    listFoldersFilesEmpresarial : documentsListFoldersAndFilesDto,
    listReceiversModal : documentsListarDetinatariosModal,
    listUsers : [],
    modalReportUsers : documentsReportViewerFilesEmployeesModal,
    listEmployees : [],
    listCategoriesByCompany : [],
    listTagsByCategory : tagsByCategoriesListDto,
    modalTagsByFolder,
    listCollaboratorsByCompany : [],
    modalCollaboratorCreate : modalUserManagementCreateModel,
    listBellsFolders : [],
    tipoOrdenamiento : documentsTiposOrdenamientoModel,
    tabSeleccion : documentsSeccionTabEscogidoModel
}