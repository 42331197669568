//#region Dependencias
import {Button, Spinner} from 'react-bootstrap';
//#endregion

const ButtonDesign = ({
    titulo,
    variant,
    onClick,
    size,
    cargando
}) => {
    //#region Fn_Color
    const Fn_Color = () => {
        switch (variant) {
            case 'primary':
                return '#379AF4'
            case 'purple':
                return '#343BA7';
            case 'info':
                return '#FFFFFF';
            default:
                return '#FFFFFF';
        }
    }
    //#endregion

    //#region Render
    return (
        <Button
            variant={variant}
            style={{
                fontWeight: 'bold',
                fontSize: size || "14px",
                height: '42px',
                marginTop: 20,
                marginBottom: 20,
                textAlign: 'center',
                minWidth: '50%',
                backgroundColor: Fn_Color(),
                color: (variant === 'primary' || variant === 'purple') ? '#FFF' : 'currentcolor'
            }}
            onClick={onClick}
        >
            {
                cargando
                ?
                <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{marginRight: 10}}
                    />
                    <span>Cargando...</span>
                </>
                :
                titulo
            }
        </Button>
    )
    //#endregion
}

export default ButtonDesign;