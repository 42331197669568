import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const PopOverInfo = (message) => {
  const renderToolTip = <Tooltip id="Info-tooltip ">{message}</Tooltip>;

  const overlayTrigger = (
    <OverlayTrigger
      className="tooltip d-flex justify-content-center align-items-center"
      placement="bottom"
      delay={{ show: 250, hide: 350 }}
      overlay={renderToolTip}
    >
      <i className="bi bi-info-circle" />
    </OverlayTrigger>
  );

  return [overlayTrigger];
};

export default PopOverInfo;
