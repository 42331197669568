//#region Dependencias
import { ActionsGeneral } from "@planeo-providers/Context/ActionTypes";
import { GetListNotificationsByIdentification_Post, GetViwNotificationsById_Post } from "../EndPoints/DocumentsEndPoints"
import { Dispatch } from "react";
import { ModalVisorNotificationsFilesDto, NotificationsFindByIdentification } from "../models/notificationsInterface";
//#endregion

export const AppsGenericas = {
    PlaneoApp : 'PlaneoApp'
}

let NotificationsAPI = {
    //#region Fn_ListarNotificaciones
    Fn_ListarNotificaciones : (identification: string, pageSize: number, pageNumber: number, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any, emailAnalisis: string, historial: boolean = false) => {
        dispatch({
            type: !historial ? 'LOADING_NOTIFICATIONS' : 'LOADING_NOTIFICATIONS_HISTORY',
            payload : {
                loadingNotifications : true
            }
        });

        let body : NotificationsFindByIdentification = {
            platform: AppsGenericas.PlaneoApp,
            identification,
            all: true,
            view: false,
            pageSize,
            pageNumber,
            companyId : identification,
            email : emailAnalisis
        }
        
        GetListNotificationsByIdentification_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                dispatch({
                    type: !historial ? 'LIST_NOTIFICATIONS' : 'LIST_NOTIFICATIONS_HISTORY',
                    payload: data
                });

                dispatch({
                    type: !historial ? 'LOADING_NOTIFICATIONS' : 'LOADING_NOTIFICATIONS_HISTORY',
                    payload : {
                        loadingNotifications : false
                    }
                });
            }
        })
        .catch(async (err) => {
            dispatch({
                type: !historial ? 'LIST_NOTIFICATIONS' : 'LIST_NOTIFICATIONS_HISTORY',
                payload: []
            });

            dispatch({
                type: !historial ? 'LOADING_NOTIFICATIONS' : 'LOADING_NOTIFICATIONS_HISTORY',
                payload : {
                    loadingNotifications : false
                }
            });

            if(err.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }
        })
    },
    //#endregion
    //#region Fn_ListarNotificacionesSinVer
    Fn_ListarNotificacionesSinVer : (identification: string, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any, emailAnalisis: string) => {
        let body : NotificationsFindByIdentification = {
            platform: AppsGenericas.PlaneoApp,
            identification,
            all: false,
            view: false,
            pageSize: 10000,
            pageNumber: 1,
            companyId: identification,
            email : emailAnalisis
        }
        
        GetListNotificationsByIdentification_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                dispatch({
                    type: 'COUNT_NOTIFICATIONS',
                    payload : {
                        countNotifications : data.length
                    }
                });
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'COUNT_NOTIFICATIONS',
                payload : {
                    countNotifications : 0
                }
            });

            if(err.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }
        })
    },
    //#endregion
    //#region Fn_ListarNotificacionesGeneral
    Fn_ListarNotificacionesGeneral : (identification: string, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any, emailAnalisis: string) => {
        let body : NotificationsFindByIdentification = {
            platform: AppsGenericas.PlaneoApp,
            identification,
            all: true,
            view: false,
            pageSize: 10000,
            pageNumber: 1,
            companyId : identification,
            email : emailAnalisis
        }
        
        GetListNotificationsByIdentification_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                dispatch({
                    type: 'COUNT_NOTIFICATIONS_TOTAL',
                    payload : {
                        countNotificationsTotal : data.length
                    }
                });
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'COUNT_NOTIFICATIONS_TOTAL',
                payload: {
                    countNotificationsTotal : 0
                }
            });

            if(err.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }
        })
    },
    //#endregion
    //#region Fn_ActivarVisualizacion
    Fn_ActivarVisualizacion : async (identification : string, id : string, firebase: any, history: any, emailAnalisis : string) => {
        let body = {
            identification,
            id,
            all : id ? false : true,
            platform : AppsGenericas.PlaneoApp,
            companyId : identification,
            email : emailAnalisis
        }

        return await GetViwNotificationsById_Post(body)
        .then(() => {
            return true;
        })
        .catch(async (err) => {
            if(err.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }

            return false;
        })
    },
    //#endregion
    //#region Fn_AbrirModalNotificaciones
    Fn_AbrirModalNotificaciones : (abrir: boolean, companyId: string, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any, setIsSubmenuVisible: ()=>void, emailAnalisis: string) => {
        setIsSubmenuVisible();

        dispatch({
            type: 'MODAL_NOTIFICATIONS',
            payload : {
                visible : abrir
            }
        });

        if(abrir){
            NotificationsAPI.Fn_ListarNotificaciones(companyId, 20, 1, dispatch, firebase, history, emailAnalisis);
        }
    },
    //#endregion
    //#region Fn_AbrirVisorArchivos
    Fn_AbrirVisorArchivos : (body : ModalVisorNotificationsFilesDto, dispatch: Dispatch<ActionsGeneral>) => {
        dispatch({
            type: 'MODAL_FILES_VISOR_NOTIFICATIONS',
            payload: body
        });
    }
    //#endregion
}

export default NotificationsAPI;