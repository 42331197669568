import { yupResolver } from '@hookform/resolvers/yup';
import { AlertDialogService } from '@planeo-components/alerts-dialog';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFirebaseApp } from 'reactfire';
import { v4 as guid } from 'uuid';
import { SignUpFormModel, SignUpFormSchema } from './form.model';
import { DefaultCompanyFolders } from '@planeo-services/logics/constants';
import { createCompanyFolder } from '@planeo-services/logics/documents/create-folder';
import { StorageService } from '@planeo-services/storage';
import { track, setUserId } from '@amplitude/analytics-browser';
import ImgMail from '@planeo-media/Img_mail.svg';

const alertFooter = (
	<>
		<strong>¿No conoces este registro?</strong> <br /> Comunícate al correo omunoz@mareigua.co.
	</>
);

export const useHook = () => {
	const firebase = useFirebaseApp();

	const {
		register,
		handleSubmit,
		watch,
		reset,
		control,
		clearErrors,
		formState: { errors, isValid },
	} = useForm<SignUpFormModel>({
		resolver: yupResolver(SignUpFormSchema),
		mode: 'onChange',
	});

	const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

	const exitsCompanyByNit = (nit: number, dv: number): Promise<boolean> => {
		return firebase
			.firestore()
			.collection('companies')
			.where('companyNit', '==', Number(nit))
			.get()
			.then((snapshot) => {
				return snapshot.docs.some((x) => Number(x.data().DV) === Number(dv));
			});
	};

	const exitsCompanyByEmail = async (email: string) => {
		return firebase
			.firestore()
			.collection('users')
			.where('email', '==', email)
			.get()
			.then((snapshot) => {
				return !snapshot.empty;
			});
	};

	const handleUploadFile = async (companyId: string) => {
		const validFiles = watch().files.filter((x) => x.errors.length === 0);

		for (const file of validFiles) {
			await StorageService.upload(`empresas/${companyId}/idVerificationFiles/${file.file.name}`, file.file);
		}
	};

	var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
	
	const onSubmit = async () => {
		setIsSubmiting(true);

		try {
			const formData = watch();
			const normalizedEmail = formData.companyEmail ? formData.companyEmail.toLowerCase().trim() : '';

			if (await exitsCompanyByNit(formData.nit, formData.dv)) {
				await AlertDialogService.alert({
					title: 'Usuario registrado',
					message: (
						<>
							El NIT{' '}
							<span className='highlight'>
								{formData.nit}-{formData.dv}
							</span>{' '}
							ya se encuentra registrado en nuestro portal.
						</>
					),
					footer: alertFooter,
				});

				setIsSubmiting(false);
				return;
			}

			if (await exitsCompanyByEmail(normalizedEmail)) {
				await AlertDialogService.alert({
					title: 'Usuario registrado',
					message: (
						<>
							El correo <span className='highlight'>{normalizedEmail}</span> ya se encuentra registrado en nuestro portal.
						</>
					),
					footer: alertFooter,
				});

				setIsSubmiting(false);
				return;
			}

			const createResult = await firebase.auth().createUserWithEmailAndPassword(normalizedEmail, formData.password);

			if (createResult) {
				const currUser = firebase.auth().currentUser;
				await currUser!.updateProfile({ displayName: formData.companyName });

				const userId = guid();
				const companyId = guid();

				await firebase
					.firestore()
					.collection('users')
					.doc(userId)
					.set({
						uid: createResult?.user?.uid,
						name: 'Admin',
						email: normalizedEmail,
						company: firebase.firestore().doc(`companies/${companyId}`),
					});

				await firebase
					.firestore()
					.collection('companies')
					.doc(companyId)
					.set({
						companyName: formData.companyName,
						companyNit: Number(formData.nit),
						DV: Number(formData.dv),
						companyPhone: Number(formData.phoneNumber),
						companyCity: 'Bogota',
						companyCIIU: parseInt('0'),
						companyAccept: false,
						ProfileImageURL: '',
						termsAndCondsAccepted: formData.termsAndConds,
						habeasDataAccepted: formData.habeasData,
						acceptedTermsAndHabeasDate: localISOTime
					});

				let iterator: number;
				for (iterator = 0; iterator < DefaultCompanyFolders.length; iterator++) {
					const folder = DefaultCompanyFolders[iterator];
					await createCompanyFolder(firebase, companyId)(folder.name, folder.type, null, null, folder.isDefault);
				}

				await handleUploadFile(companyId);
				await currUser!.sendEmailVerification();
				track('REGISTRO DE EMPRESA');
				setUserId(normalizedEmail);
				await firebase.auth().signOut();

				await AlertDialogService.open({
					icon: ImgMail,
					title: 'Verifica tu correo',
					message: (
						<>
							Para crear tu cuenta necesitamos validar tu correo, para esto hemos enviado un mensaje a{' '}
							<span className='highlight'>{normalizedEmail}</span>
						</>
					),
				});

				reset();
				setTimeout(() => clearErrors('files'));
			}
		} catch (ex: unknown) {
			await AlertDialogService.alert({
				title: 'Error en registro',
				message: `Ha ocurrido un error inesperado: ${ex}`,
			});
		}

		setIsSubmiting(false);
	};

	useEffect(() => {
		setTimeout(() => clearErrors('files'));
	}, []);

	return {
		register,
		control,
		handleSubmit,
		errors,
		isValid,
		onSubmit,
		isSubmiting,
		watch,
	};
};
