import { useCompanyContext } from '@planeo-providers/company-provider';
import { useEffect, useState } from 'react';
import { useGlobalState, store } from 'state-pool';

import DefaultAvatarImg from '@planeo-media/Default-Brand-Logo.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useFirebaseApp } from 'reactfire';
import NotificationsAPI from '@planeo-services/api/functions/NotificationsAPI';
import { useStateValue } from '@planeo-providers/Context/store';
store.setState('avatar', DefaultAvatarImg);

export const useHook = () => {
	//#region Hooks
	const firebase = useFirebaseApp();
    const history = useHistory();
    //#endregion

	//#region Reducers
	const {dispatch} = useStateValue();
	//#endregion

	const location = useLocation();

	const { company } = useCompanyContext();
	const [avatar, setAvatar] = useGlobalState('avatar');
	setAvatar(avatar);

	const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
	const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(true);
	const [submenuVisible, setSubmenuVisible] = useState<boolean>(false);

	const toogleSubmenu = () => {
		setSubmenuVisible(!submenuVisible);
	};

	const toogleSidemenu = () => {
		setIsMenuExpanded(!isMenuExpanded);
	};

	useEffect(() => {
		setIsMenuVisible(location.pathname !== '/');
	}, [location]);

	useEffect(() => {
		if (company?.ProfileImageURL) setAvatar(company.ProfileImageURL);
	}, [company, setAvatar]);

	useEffect(() => {
		document.title = 'Planeo';
	});

	useEffect(() => {
		if(company.id){
			NotificationsAPI.Fn_ListarNotificacionesSinVer(company.id, dispatch, firebase, history, company.adminUser.email);
		}
	}, [company.id]);
	

	return {
		avatar,
		submenuVisible,
		setSubmenuVisible,
		toogleSubmenu,
		isMenuExpanded,
		toogleSidemenu,
		isMenuVisible,
	};
};
