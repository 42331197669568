const AlternadorEmpresasPersonas = () => {
    //#region Render
    return (
        <div
            style={{
                marginTop: 10,
                marginBottom: 25,
                width: '80%',
                alignSelf: 'center'
            }}
        >
            <div style={{display: 'flex',justifyContent: 'space-around'}}>
                <div style={{borderBottom: '4px solid #379AF4', cursor: 'pointer'}}>
                    <h6 style={{fontWeight:'bold'}}>
                        EMPRESAS
                    </h6>
                </div>
            </div>
            <hr style={{height: '1px', border: '1px solid #707070',opacity: 0.41,marginTop:-3}} />
        </div>
    )
    //#endregion
}

export default AlternadorEmpresasPersonas;