//#region Dependencias
import ButtonDesign from '@planeo-features/auth/login/Components/ButtonDesign';
import { Col } from 'react-bootstrap';
import background from '@planeo-media/backgrounds/fullpage.svg';
import { useHistory } from 'react-router-dom';
//#endregion

const BannerDerechoRegistro = () => {
    //#region Hooks
    const history = useHistory();
    //#endregion

    //#region Render
    return (
        <Col
            md={4} 
            style={{
                backgroundImage: "url(" + background + ")",
                backgroundPosition: 'top',
                backgroundPositionY: 'top',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                color: '#FFF',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start'
            }}
        >
            <div style={{
                width: '70%',
                alignSelf: 'center'
            }}>
                <h2 style={{
                    textAlign: 'center',
                    font: 'normal normal bold 36px Nunito',
                    letterSpacing: '0px',
                    color: '#FFFFFF',
                    textTransform: 'uppercase',
                    opacity: 1,
                    fontWeight: '800',
                    fontSize: 36,
                    marginTop: 70
                    }}
                >
                    BIENVENIDO
                </h2>

                <p className='tituloLogin'>
                    Entiende y administra la relación salarial con tus empleados a partir de servicios basados en información confiable, actualizada y segura.
                </p>
                <div style={{marginTop: 30}}>
                    <ButtonDesign
                        titulo={'INICIAR SESIÓN'}
                        variant='info'
                        onClick={()=>history.push('/')}
                        size='16px'
                        cargando={false}
                    />
                </div>
            </div>
        </Col>
    )
    //#endregion
}

export default BannerDerechoRegistro;