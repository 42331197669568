import { useGlobalState } from 'state-pool';
import { Dispatch, SetStateAction, useState } from 'react';
import {} from 'react-bootstrap';
import Avatar from 'react-avatar';
import { Collapse } from '@material-ui/core';

import { useCompanyContext } from '@planeo-providers/company-provider';
import { useHook } from './hook';

import './styles.scss';
import { ModalTermsAndConds } from '@planeo-components/modal-terms-and-conds';
import { ModalHabeasData } from '@planeo-components/modal-habeas-data';

export type PlaneoNavbarSubmenuProps = {
	visible: boolean;
	setVisible: Dispatch<SetStateAction<boolean>>;
};

export const PlaneoNavbarSubmenu = (props: PlaneoNavbarSubmenuProps): JSX.Element => {
	const { company } = useCompanyContext();
	const [avatar] = useGlobalState('avatar');

	const [termsAndCondsVisible, setTermsAndCondsVisible] = useState<boolean>(false);
	const [habeasDataVisible, setHabeasDataVisible] = useState<boolean>(false);

	const hook = useHook(props);

	return (
		<>
			<ModalTermsAndConds visible={termsAndCondsVisible} onHide={() => setTermsAndCondsVisible(false)} />
			<ModalHabeasData visible={habeasDataVisible} onHide={() => setHabeasDataVisible(false)} />

			<div className='navbar-submenu'>
				<Collapse in={props.visible}>
					<menu>
						<div className='navbar-submenu__section navbar-submenu__info'>
							<Avatar src={avatar} round size='80'></Avatar>

							<div className='company-data'>
								<span className='company-name'>{company.companyName}</span>
								<span className='user-email'>{company.adminUser.email}</span>
								<span className='account-config' onClick={hook.handleGoToProfile}>
									Configuración de empresa
								</span>
							</div>
						</div>

						<div className='divider'></div>

						<div className='navbar-submenu__section'>
							<div
								className='button'
								onClick={() => {
									hook.handleShowPolitics();
									setHabeasDataVisible(true);
								}}>
								Tratamiento de datos personales
							</div>
							<div
								className='button'
								onClick={() => {
									hook.handleShowConditions();
									setTermsAndCondsVisible(true);
								}}>
								Términos y Condiciones
							</div>
							<div className='button' onClick={hook.handleShowHelp}>
								Ayuda
							</div>
						</div>

						<div className='divider'></div>
						<div className='navbar-submenu__section'>
							<div className='button' onClick={hook.handleLogout}>
								Cerrar sesión
							</div>
						</div>
					</menu>
				</Collapse>
			</div>
		</>
	);
};
