import { ReactNode } from 'react';
import { check_icon_png, error_icon_png } from '@planeo-assets';
import { UploaderFilesInfoBase, UploaderFilesProps } from '../base';
import convertToCsv from 'convert-array-to-csv';

import './styles.scss';

export class UploaderFilesInfoShort extends UploaderFilesInfoBase {
	constructor(props: UploaderFilesProps | Readonly<UploaderFilesProps>) {
		super(props);

		this.state = {
			totalAccepted: 0,
			totalRejected: 0,
		};
	}

	downloadErrors(props: UploaderFilesProps) {
		const json = props.files
			?.filter((x) => x.errors?.length > 0)
			.map((x) => {
				return {
					name: x.file.name,
					error: x.errors[0].message,
				};
			});
		const content = convertToCsv(json);

		const a = document.createElement('a');
		const blob = new Blob([content], { type: 'text/csv' });
		const url = URL.createObjectURL(blob);
		a.setAttribute('href', url);
		a.setAttribute('download', 'errors.csv');
		a.click();
	}

	static getDerivedStateFromProps(props: UploaderFilesProps, state) {
		return {
			...state,
			totalAccepted: props.files?.length! < 501 ? props.files?.filter((x) => x.errors.length === 0)?.length ?? 0 : 0,
			totalRejected: props.files?.length! < 501 ? props.files?.filter((x) => x.errors.length > 0)?.length ?? 0 : 0,
		};
	}

	render(): ReactNode {
		return (
			<div className='file-uploader-info-short'>
				{this.props.title && (
					<div className='title'>
						<b>{this.props.title}</b>
					</div>
				)}

				{this.state.totalAccepted > 0 && (
					<div className='info-span'>
						<img src={check_icon_png} alt='check icon' className='icon' />
						<span>
							Se validaron <b>{this.state.totalAccepted} archivos</b> exitosamente
						</span>
					</div>
				)}

				{this.state.totalRejected > 0 && (
					<div className='info-span'>
						<img src={error_icon_png} alt='check icon' className='icon' />
						<span>
							Se generó un error en la validación de <b>{this.state.totalRejected} archivos</b>{' '}
							<span className='download-link' onClick={() => this.downloadErrors(this.props)}>
								Descargar listado
							</span>
						</span>
					</div>
				)}
			</div>
		);
	}
}
