import fbase from 'firebase/app';

export const getUserLoginRetries = (firebase: fbase.app.App) => {
	return async (userEmail: string): Promise<number> => {
		const userSnapshot = await firebase.firestore().collection('users').where('email', '==', userEmail).get();

		if (userSnapshot.size === 0) return 0;

		const loginRetries: number = userSnapshot.docs[0].data()?.loginRetries ?? 0;
		return loginRetries;
	};
};
