import { FolderTypeEnum } from '../enums/folder-type.enum';
import { CompanyFolder } from '../models';

import fbase from 'firebase/app';

export const DefaultCompanyFolders: Omit<CompanyFolder, 'id'>[] = [
	{
		name: 'Desprendibles de nómina',
		type: FolderTypeEnum.employee,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
	{
		name: 'Certificados de ingresos y retenciones',
		type: FolderTypeEnum.employee,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
	{
		name: 'Contrato',
		type: FolderTypeEnum.employee,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
	{
		name: 'Manuales corporativos',
		type: FolderTypeEnum.general,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
	{
		name: 'Reglamento de trabajo',
		type: FolderTypeEnum.general,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
];
