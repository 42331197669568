//#region Dependencias
import { DeleteAccountModel } from "@planeo-features/auth/deleteAccount/Interfaces/DeleteAccountInterface";
import { GetDeleteAccountGoogle_Post } from "../EndPoints/UsersEndPoints";
import { AlertDialogService } from "@planeo-components/alerts-dialog";
import { FormikProps } from "formik";
//#endregion

const UsersAPI = {
    //#region FnEliminarCuentaFormGoogle
    FnEliminarCuentaFormGoogle : (body: DeleteAccountModel, formik: FormikProps<DeleteAccountModel>) => {
        GetDeleteAccountGoogle_Post(body)
        .then(async (respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                await AlertDialogService.alert({
                    title: 'Notificación del sistema',
                    message: data.Mensaje
                });

                formik.resetForm({
                    values: {
                        document : '',
                        documentType : 0,
                        email : '',
                        password : ''
                    },
                    errors : {
                        document : '',
                        documentType : '',
                        email : '',
                        password : ''
                    }
                });
            }
        })
        .catch(async (error) => {
            if(error?.data?.Mensaje){
                await AlertDialogService.alert({
                    title: 'Notificación del sistema',
                    message: error?.data?.Mensaje
                });
            }
            else{
                await AlertDialogService.alert({
                    title: 'Notificación del sistema',
                    message: 'Hubo un error al eliminar tu cuenta, inténtalo nuevamente por favor'
                });
            }
        });
    }
    //#endregion
}

export default UsersAPI;