import { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { FileUploaderProps, IUpLoadableFile } from '../defs';

export const useHook = (props: FileUploaderProps) => {
	const [value, setValue] = useState<IUpLoadableFile[]>([]);

	const setFieldValue = useCallback((files: IUpLoadableFile[]) => {
		setValue(files);
	}, []);

	const onDropFiles = useCallback(
		(acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
			acceptedFiles = acceptedFiles.filter((x) => !value.some((y) => JSON.stringify(y.file) === JSON.stringify(x)));
			rejectedFiles = rejectedFiles.filter((x) => !value.some((y) => JSON.stringify(y.file) === JSON.stringify(x.file)));

			const mappedAcc = acceptedFiles.map((file) => ({ file, errors: [] }));
			setFieldValue([...value, ...mappedAcc, ...rejectedFiles]);
		},
		[value, setFieldValue]
	);

	const { getRootProps: getDropZoneRootProps, getInputProps: getDropZoneInputProps } = useDropzone({
		onDrop: onDropFiles,
		multiple: props.multiple,
		accept: props.filesType,
		maxSize: props.filesMaxSize ?? 2500 * 1024,
		maxFiles: props.maxFiles ?? undefined,
	});

	const deleteFile = (file: IUpLoadableFile) => {
		setFieldValue(value.filter((fw) => fw.file !== file.file));
	};

	const deleteAllFiles = () => {
		setFieldValue([]);
	};

	useEffect(() => {
		if (props.onValueChange) props.onValueChange(value);
	}, [value]);

	useEffect(() => {
		setFieldValue(props.default ?? []);
	}, [props.default]);

	useEffect(() => {
	  if(value.length > 400){
		setFieldValue([]);
	  }
	}, [value])
	

	return {
		getDropZoneRootProps,
		getDropZoneInputProps,
		files: value,
		deleteFile,
		deleteAllFiles,
	};
};
