import { ReactNode } from 'react';
import { Subject } from 'rxjs';
import { render } from 'react-dom';
import { AlertDialog } from './../component';

import ImgAlert from '@planeo-media/Modal/Img_Alerta.svg';
import ImgSuccess from '@planeo-media/Modal/Img_Success.png';

export type AlertDialogConfig = {
	icon?: string;
	title?: string;
	message?: string | ReactNode;
	footer?: string | ReactNode;
	isConfirm?: boolean;
};

export type AlertDialogModalEvent = AlertDialogConfig & { visible: boolean };

export class AlertDialogService {
	private static events: Subject<AlertDialogModalEvent> = new Subject<AlertDialogModalEvent>();

	private static getSliderContainer(): HTMLElement {
		let el = document.getElementById('slide-panel-component-root');

		if (el === null) {
			el = document.createElement('div');
			el.id = 'slide-panel-component-root';
			document.getElementById('root')?.prepend(el);
		}

		return el;
	}

	static open(options: AlertDialogConfig): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const container = this.getSliderContainer();

			render(
				<AlertDialog service={this.events.asObservable()} onAcceptClicked={() => resolve(true)} onCancelClicked={() => resolve(false)}></AlertDialog>,
				container
			);
			this.events.next({ ...options, visible: true, isConfirm: options.isConfirm ?? false });
		});
	}

	static success(options?: Omit<AlertDialogConfig, 'icon' | 'isConfirm'>): Promise<boolean> {
		return this.open({ ...options, icon: ImgSuccess });
	}

	static alert(options?: Omit<AlertDialogConfig, 'icon' | 'isConfirm'>): Promise<boolean> {
		return this.open({ ...options, icon: ImgAlert });
	}

	static confirm(options?: Omit<AlertDialogConfig, 'icon' | 'isConfirm'>): Promise<boolean> {
		return this.open({ ...options, icon: ImgAlert, isConfirm: true });
	}

	static close() {
		this.events.next({ visible: false });
	}
}
