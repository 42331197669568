//#region Dependencias
import { DocumentsList } from "@planeo-services/api/models/documentsModelsInterface";
import { Action_Documents } from "../ActionTypes/DocumentsActions";
//#endregion

const sesionDocumentsReducer = (state: DocumentsList, action: Action_Documents): DocumentsList => {
    switch(action.type){
        case 'LIST_FOLDERS_FILES':
            return {
                ...state,
                listFoldersFiles : action.payload
            };
        case 'LIST_FOLDERS_FILES_EMPRESARIAL':
            return {
                ...state,
                listFoldersFilesEmpresarial : action.payload
            };
        case 'LIST_FOLDERS_PRINCIPAL':
            return {
                ...state,
                listFoldersPrincipal : action.payload
            };
        case 'LIST_FOLDERS_SIDEBAR':
            return {
                ...state,
                listSideBar : action.payload
            };
        case 'LIST_PEOPLE_MAILBOX':
            return {
                ...state,
                listPeopleMailbox : action.payload
            };
        case 'LOADING':
            return {
                ...state,
                loading : action.payload
            };
        case 'LOADING_SIDEBAR':
            return {
                ...state,
                loadingSideBar : action.payload
            };
        case 'EMPLOYEE_SELECTION':
            return {
                ...state,
                employeeSelection : action.payload
            };
        case 'RECEIVERS_LIST_MODAL':
            return {
                ...state,
                listReceiversModal : action.payload
            };
        case 'USERS_LIST_ADMINS':
            return {
                ...state,
                listUsers : action.payload
            };
        case 'OPEN_MODAL_REPORTE_VISUALIZACIONES':
            return {
                ...state,
                modalReportUsers : action.payload
            };
        case 'LIST_EMPLOYEE_FILTER':
            return {
                ...state,
                listEmployees : action.payload
            };
        case 'LIST_CATEGORIES_BY_COMPANY':
            return {
                ...state,
                listCategoriesByCompany : action.payload
            };
        case 'LIST_TAGS_BY_CATEGORY':
            return {
                ...state,
                listTagsByCategory : action.payload
            };
        case 'OPEN_MODAL_CATEGORIAS_TAGS':
            return {
                ...state,
                modalTagsByFolder : action.payload
            };
        case 'COLLABORATORS_LIST_BY_COMPANY':
            return {
                ...state,
                listCollaboratorsByCompany : action.payload
            };
        case 'OPEN_MODAL_CREAR_COLABORADOR':
            return {
                ...state,
                modalCollaboratorCreate : action.payload
            };
        case 'LIST_BELLS_FOLDERS':
            return {
                ...state,
                listBellsFolders : action.payload
            };
        case 'TIPO_ORDENAMIENTO':
            return {
                ...state,
                tipoOrdenamiento : action.payload
            };
        case 'SELECCION_TAB_VISUALIZAR':
            return {
                ...state,
                tabSeleccion : action.payload
            };
        default:
            return state;
    }
}

export default sesionDocumentsReducer;