import { useHook } from './hook';

//media
import home from '@planeo-media/SideBarIcons/home.svg';
import profits from '@planeo-media/SideBarIcons/profits.svg';
import Config_Doc from '@planeo-media/SideBarIcons/Config_Doc.svg';
import adelanto_Nomina from '@planeo-media/SideBarIcons/adelanto_Nomina.svg';
import headhunter from '@planeo-media/SideBarIcons/headhunter.svg';
import Gear from '@planeo-media/SideBarIcons/Gear.svg';
import Payroll from '@planeo-media/SideBarIcons/payroll.svg';
import Employees from '@planeo-media/SideBarIcons/employees.svg';

import './styles.scss';
import { useCompanyContext } from '@planeo-providers/company-provider';

type MenuItemProps = {
	isExpanded: boolean;
	icon: string;
	name: string;
	link: string;
	ignoreActive?: boolean;
};

const MenuItem = (props: MenuItemProps): JSX.Element => {
	const hook = useHook();

	return (
		<li
			className={`sidemenu-item ${!props.ignoreActive && hook.location.pathname.startsWith(props.link) ? 'active' : ''}`}
			onClick={() => hook.goToLink(props.link)}>
			<img src={props.icon} alt={props.name} />
			{props.isExpanded && <span> {props.name} </span>}
		</li>
	);
};

export type PlaneoDashboardSideMenuProps = {
	isExpanded: boolean;
	isHidden: boolean;
};

export const PlaneoDashboardSideMenu = (props: PlaneoDashboardSideMenuProps): JSX.Element => {
	const { company } = useCompanyContext();

	return (
		<div id='planeo-dashboard-sidemenu' className={props.isHidden ? 'hidden' : ''}>
			<div className='sidemenu-content'>
				<ul>
					<MenuItem icon={home} name='Inicio' isExpanded={props.isExpanded} link='/' ignoreActive={true} />
					<MenuItem icon={Payroll} name='Nómina electrónica' isExpanded={props.isExpanded} link='/nomina' />
					<MenuItem icon={Gear} name='Config. empresa' isExpanded={props.isExpanded} link='/config_cuenta' />
					<hr />
					<MenuItem icon={headhunter} name='Nueva posición' isExpanded={props.isExpanded} link='/nueva_posicion' />
					<MenuItem icon={profits} name='Análisis de mercado' isExpanded={props.isExpanded} link='/analisis_mercado' />
					<MenuItem icon={Config_Doc} name='Documentos empresariales' isExpanded={props.isExpanded} link='/documentos' />
					{
						company.companyNit !== Number('860527350')
						&&
						<MenuItem icon={Employees} name='Admin. empleados' isExpanded={props.isExpanded} link='/empleados' />
					}
					<MenuItem icon={adelanto_Nomina} name='Adelanto nómina' isExpanded={props.isExpanded} link='/adelanto_nomina' />
				</ul>
			</div>
		</div>
	);
};
