import * as yup from 'yup';

export interface PasswordRecoveryForm {
	password: string;
	passwordConfirm: string;
}

export const PasswordRecoveryFormSchema = yup.object({
	password: yup
		.string()
		.required('Campo obligatorio')
		.min(8, 'La contraseña es demasiado corta')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, 'la contraseña no es válida'),

	passwordConfirm: yup.string().test('passwords-match', 'Las contraseñas no coinciden', function (value) {
		return this.parent.password === value;
	}),
});
