import { useEffect, lazy } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { useCompanyContext } from '@planeo-providers/company-provider';

//Styles
import { AlertToast } from '@planeo-components/alerts';
import { ErrorService, useErrorHandling } from '@planeo-services/error-handler';
import { PlaneoLoader } from '@planeo-components/loader';
import CollaboratorsRoutes from '@planeo-features/collaborators';
import CategoriasTagsModal from '@planeo-features/documents/documents-manager-new/Modales/CategoriasTagsModal';
import NotificationsRoutes from '@planeo-features/notificationsHistory';

const CompanyNotVerifiedPage = lazy(() => import('@planeo-features/shared-pages/company-not-verified'));

const HomePage = lazy(() => import('@planeo-features/shared-pages'));
const DocumentsRoutes = lazy(() => import('@planeo-features/documents'));
const CompanyPayrollRoutes = lazy(() => import('@planeo-features/company-payroll'));
const UserProfile = lazy(() => import('@planeo-features/User'));
const MarketAnalysisRoutes = lazy(() => import('@planeo-features/market-analysis'));
const NewPositionRoutes = lazy(() => import('@planeo-features/new-position'));
const EmployeesRoutes = lazy(() => import('@planeo-features/employees'));

export const AppRouter = () => {
	const { company } = useCompanyContext();
	const { path } = useRouteMatch();

	const triggerError = useErrorHandling();

	useEffect(() => {
		try {
			if (company.companyName !== '' && !company.companyAccept) {
				throw new Error('Aún estamos verificando tu cuenta… Espera noticias nuestras en los próximos días en tu correo');
			}
		} catch (error) {
			ErrorService.errorException(error as Error);
		}
	}, [company, triggerError]);

	return (
		<>
			<AlertToast />

			{!company.isReady && <PlaneoLoader />}

			{company.isReady && (
				<Switch>
					<Route exact path={`${path}`}>
						<HomePage />
					</Route>

					<Route path={`${path}nomina`}>
						<CompanyPayrollRoutes company={company} />
					</Route>

					<Route path={`${path}nueva_posicion`}>
						<NewPositionRoutes company={company} />
					</Route>

					<Route path={`${path}analisis_mercado`}>
						<MarketAnalysisRoutes company={company} />
					</Route>
					<Route path={`${path}config_cuenta`}>
						<UserProfile company={company} />
					</Route>

					<Route path={`${path}documentos`}>
						<DocumentsRoutes company={company} />
					</Route>

					{
						company.companyNit !== Number('860527350')
						&&
						<Route path={`${path}empleados`}>
							<EmployeesRoutes company={company} />
						</Route>
					}

					<Route exact path='/adelanto_nomina'>
						{company.companyAccept && (
							<CompanyNotVerifiedPage title='Adelanto de nómina' text='Esta funcionalidad estará disponible próximamente.' />
						)}

						{!company.companyAccept && (
							<CompanyNotVerifiedPage title='Adelanto de nómina' text='Esta funcionalidad estará disponible próximamente.' />
						)}
					</Route>

					<Route path={`${path}usuarios`}>
						<CollaboratorsRoutes company={company} />
					</Route>

					<Route path={`${path}notificaciones`}>
						<NotificationsRoutes company={company} />
					</Route>

					<Redirect to='/404' />
				</Switch>
			)}

			<CategoriasTagsModal />
		</>
	);
};
