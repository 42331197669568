import { Button, Modal } from 'react-bootstrap';

import './styles.scss';

export type ModalTermsAndCondsProps = {
	visible: boolean;
	onHide: () => void;
};

export const ModalTermsAndConds = (props: ModalTermsAndCondsProps): JSX.Element => {
	return (
		<Modal id='planeo-terms-and-conds-modal' show={props.visible} centered size='lg' onHide={props.onHide}>
			<Modal.Header>
				<Modal.Title>Términos y condiciones</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Zajana S.A.S (en adelante “Planeo”) sociedad legalmente constituida bajo las leyes de la República de Colombia, identificada con NIT. # 901.503.100-6, domiciliada en la ciudad de Bogotá D.C., con dirección Calle 98 No. 22-64 Oficina 1016.
				</p>
				<br></br>
				<span>Naturaleza Jurídica</span>
				<p>
					Los presentes términos y condiciones de uso regulan la relación contractual de carácter comercial que une a los usuarios que acceden a la Plataforma Virtual Planeo, tanto en su sitio web (planeo.mareigua.co) como en sus versiones de aplicación móvil (planeoapp.mareigua.co) y plataforma para empresas (planeoweb.mareigua.co). Además de todos los subdominios asociados.
				</p>
				<p>
					<ul>
						<li>
							Cookies: Cadenas de texto enviadas virtualmente que son almacenadas por Planeo por el uso de la Plataforma Virtual. Son utilizadas para la consulta de las actividades y preferencias de los usuarios.
						</li>
						<li>
							Datos personales: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables tal como se define en la Ley 1581 de 2012 y sus normas complementarias o como se defina en las normas que lo reemplacen.
						</li>
						<li>
							Mayor de edad: Persona natural mayor de dieciocho (18) años.
						</li>
						<li> Mensajes de datos: La información generada, enviada, recibida, almacenada o comunicada por medios electrónicos, ópticos o similares, como pudieran ser, entre otros, el Intercambio Electrónico de Datos (EDI), Internet, el correo electrónico, el telegrama, el télex o el telefax (Ley 527 de 1999 art. 2 lit. a).
						</li>
						<li>Plataforma Virtual o Plataforma: Aplicativos web y móviles administrado por Planeo.</li>
						<li>El término "sitio", "sitio web", “plataforma”, “plataforma virtual”, “APP” o “aplicación móvil” hace referencia al sitio donde se prestan todos los servicios que Planeo ofrece, sin tener en cuenta posibles terceros relacionados con Planeo.</li>
						<li>El término "Aplicación móvil" hace referencia al software que ofrece Planeo como servicio a en su sitio planeoapp.mareigua.co</li>
						<li>Producto: Bien de consumo exhibido a través de la Plataforma.</li>
						<li>
							Publicidad: Es toda forma de comunicación realizada por Planeo, con el fin de brindar información sobre productos, actividades
							comerciales y comunicar estrategias o campañas publicitarias o de mercadeo, propias o de terceros.
						</li>
						<li>Servicio: Servicios exhibidos a través de la Plataforma.</li>
						<li>
							Usuarios: se referirá tanto a los usuarios-empresa como usuarios-empleado
						</li>
						<li>
							Usuario-empleado: Toda persona natural que, como destinatario final, use la Plataforma Virtual para incluir su información financiera, verificar recomendaciones o con el fin de consumir o adquirir los servicios que se ofrecen en la misma.
						</li>
						<li>
							El término "titular de la cuenta" hace referencia al representante legal de la persona jurídica a nombre de quien está registra la cuenta en Planeo.
						</li>
						<li>
							El término "usuario-empresa" hace referencia a la persona que visita y usa el sitio web de Planeo, sea o no "titular de cuenta"
						</li>
					</ul>
				</p>
				<span>Objeto</span>
				<p>
					Los presentes términos y condiciones regulan la autorización de uso que otorga Planeo a los diferentes usuarios, para que éstos ingresen a la plataforma virtual, incluyan su información personal salarial y financiera, usen las herramientas disponibles y puedan solicitar los servicios adicionales ofrecidos.
				</p>
				<p>
					Planeo, a través de la Plataforma, realiza las siguientes acciones: i) permite el uso de herramientas de monitoreo salarial y planeación financiera, ii) recopila la información financiera y salarial en la aplicación para visibilidad del Usuario, iii) sujeto a la autorización de Usuario, comparte la información financiera y salarial con terceros, iv) sirve de medio de envío de comunicaciones entre los Usuarios y terceros que ofrecen Productos y Servicios, v) ofrece Servicios al Usuario, v)  realiza informes animizados de mercado sobre datos agregados extraídos de promedios y medidas estadísticas de situación salarial, vi) permite el envío de comunicaciones entre los Usuarios empresariales y usuarios empleados, vii) permite el almacenamiento de documentos personales tanto para los usuarios empresariales como para los usuarios empleados.
				</p>
				<br></br>
				<span>Condiciones de Uso</span>
				<p>
					Estas Condiciones del Usuario serán aplicables a su visita y a su uso del sitio web en [planeo.mareigua.co] (el «Sitio web») y la aplicación Planeo (planeoapp.mareigua.co), que se puede descargar en dispositivos móviles por medio de tiendas virtuales de aplicaciones de Apple (App Store) y de Google (Google Play) (la “Aplicación”).
				</p>
				<p>
					Por favor, lea estas Condiciones del Usuario detenidamente antes de acceder al Sitio web o de descargar la Aplicación. Al acceder al Sitio web, descargar la Aplicación, instalarla, utilizarla o utilizar el Servicio, usted acepta estas condiciones de uso y se obliga a cumplirlas. En caso de que usted no esté de acuerdo con lo aquí consignado, por favor, no acceda al Sitio web ni descargue la Aplicación ni use el Servicio.
				</p>
				<p>El Sitio web y la Aplicación son operados por Planeo, con domicilio en la ciudad de Bogotá D.C., y con dirección Calle 98 No. 22-64 Oficina 1016.</p>
				<br></br>
				<span>¿Qué servicios presta Planeo?</span>
				<p>
					Planeo ofrece, a través de la página Web y de la Aplicación, un servicio de recopilación de información salarial y financiera de los Usuarios. Así mismo, aporta para cada perfil de usuarios herramientas para el análisis de la información recopilada, a la vez, que proporciona un medio de envío de documentos.
				</p>
				<p>
					Para los usuarios empleados, la Plataforma Virtual presenta ciertos resultados y recomendaciones las cuales son únicamente recomendaciones de la Plataforma y que cada Usuario debe considerar de acuerdo con su propia planeación financiera, tributaria y comercial. Planeo no asegura ningún resultado en relación con las medidas recomendadas.
				</p>
				<p>
					Adicionalmente, la Plataforma Virtual permite Publicidad y ofrecimiento de Productos y Servicios de terceros. Así mismo, Planeo permite la comunicación de Usuarios con terceros y ofrece ciertos Servicios para que los Usuarios puedan determinar si alguno de ellos es de su interés.
				</p>
				<p>
					De igual manera, la Plataforma Virtual permite a los usuarios empresariales revisar informes estadísticos sobre salarios y crear un canal de envío de documentos con los usuarios empleados registrados en la plataforma.
				</p>
				<p>
					Planeo, finalmente, pone a disposición de sus usuarios un repositorio documental exclusivo para información relacionada con sus datos personales y aquella que emane de la relación laboral.
				</p>
				<p>
					Entre Planeo y los Usuarios y entre Planeo y los terceros no existe relación laboral ni subordinación o dependencia de ningún tipo. Mediante el sistema, Planeo permite que el Usuario se registre, verifique su situación financiera y tome de forma autónoma las decisiones que considere pertinentes.
				</p>
				<br></br>
				<span>Contrato entre la Empresa Usuaria y Planeo</span>
				<p>
					Al utilizar la Aplicación o el Servicio, se entenderá que el Usuario celebra un contrato con Planeo (el «Contrato»). Para poder utilizar la Aplicación o el Servicio, en primer lugar, deberá registrarse en Planeo
				</p>
				<p>
					El Usuario deberá, en caso de ser persona jurídica, estar debidamente constituida y tener las facultades para representar a la Empresa para entrar en este contrato, capacidad la cual declara tener, y, para personas naturales, el usuario declara tener 18 años o más para usar el Servicio o la Aplicación. Si reside en una jurisdicción que restringe el uso del Servicio o la Aplicación por motivos de edad o restringe la capacidad de suscribir contratos como el presente por motivos de edad, deberá respetar estos límites de edad y abstenerse de utilizar el Servicio y la Aplicación.
				</p>
				<p>
					El usuario declara que, de ser una persona natural, es mayor de edad para formalizar un contrato vinculante o que si se registra en nombre de una persona jurídica está autorizado para formalizar y vincular a la misma a estas Condiciones del Uso y registrarse para el Servicio y la Aplicación.
				</p>
				<p>
					Al comprar cualquier Servicio, el Usuario acepta que: (i) es responsable de leer el listado completo del Servicio antes de comprometerse a comprarlo: (ii) celebra un contrato legalmente vinculante para comprar un servicio. Los precios que cobramos por usar nuestros Servicios se enumeran en la Plataforma y en la Aplicación. Nos reservamos el derecho de cambiar nuestros precios para los Servicios que se muestran en cualquier momento y de corregir los errores de precios que pueden ocurrir inadvertidamente. Información adicional sobre precios e impuestos sobre las ventas está disponible en la página de pagos.
				</p>
				<br></br>
				<span>Registro</span>
				<p>
					Para poder utilizar los Servicios de la Plataforma Virtual, cada Usuario deberá registrarse como usuario en la página web de Planeo o en la aplicación. Al registrarse, estará obligado a facilitar a Planeo su información personal, su número de teléfono, y todos los datos adicionales requeridos para llenar el formulario, además, en caso de ser persona jurídica, proporcionará los documentos que garanticen su existencia (documentos que serán descritos dentro del formulario de registro).
				</p>
				<p>
					Tras terminar adecuadamente el registro, Planeo le facilitará una cuenta personal a la que podrá acceder con la contraseña que elija, habilitándole ciertas funcionalidades, pero el uso total de las funcionalidades de Planeo estará condicionado a la aceptación del registro por parte de Planeo. La aceptación, subsanación de documentos o negación del registro se le informará al usuario a través de la plataforma
				</p>
				<p>
					Una vez se ha realizado el registro empresarial, el Usuario empresa está en la obligación de crear a sus empleados en la plataforma para que ellos puedan completar el registro. Los usuarios-empleado estarán obligados a facilitar a Planeo información personal adicional para terminar adecuadamente el registro y empezar a usar las funcionalidades de la plataforma.
				</p>
				<p>
					El registro se someterá a estas condiciones:
				</p>
				<p>
					<ol>
						<li>
							El usuario deberá garantizar que la información proporcionada a Planeo es precisa y completa. Planeo no tiene la obligación de supervisar o controlar la información, pero tendrá derecho, en todo momento, a comprobar la información facilitada y a denegar el Servicio o el uso de la Aplicación o de la página Web, sin explicar sus motivos y sin que ello otorgue al Usuario derecho alguno a indemnización o compensación.
						</li>
						<li>
							Solo las personas que tienen la capacidad legal necesaria para obligarse de acuerdo con los presentes términos están autorizadas para hacer uso del Servicio prestado por Planeo. Las personas que no cuenten con esta capacidad, entre ellos los menores de edad, deben ser asistidas por sus representantes legales.
						</li>
						<li>
							Planeo puede rehusarse o cancelar la cuenta del Usuario en cualquier momento, ya sea por quejas o por políticas internas.
						</li>
						<li>
							Planeo se reserva el derecho de usar cualquier acción legal posible para identificar a los usuarios, así como requerir, en cualquier momento, documentación extra que se considere apropiada para verificar la información personal del Usuario.
						</li>
						<li>
							Planeo no se responsabiliza por cualquier daño como resultado de la pérdida o mal uso de la clave por parte de terceros. El Usuario es el único responsable por ello.
						</li>
						<li>No se puede transferir por ningún motivo, el registro del Usuario a terceras personas.</li>
					</ol>
				</p>
				<span>¿Cómo usar la app?</span>
				<p>
					La plataforma virtual tiene dos tipos de usuario: Usuario-empresarial y Usuario-empleado. Para cada uno de ellos se tienen una forma de herramientas y servicios diferenciados.
				</p>
				<p>
					La plataforma virtual le permitirá al Usuario-Empresarial cargar los archivos de nómina electrónica de sus empleados y demás documentos que emanan de la relación laboral. A la vez, que les da acceso a herramientas de analítica del mercado laboral.
				</p>
				<p>
					La Plataforma Virtual le permitirá al Usuario-empleado consultar información y documentos que surgen de la relación laboral con el usuario-empresa, cargar documentos personales relacionados con tramites e incluir su información financiera
				</p>
				<p>
					La Plataforma organizará su información de acuerdo con:
				</p>
				<p>
					<ol>
						<li>
							las relaciones laborales que se establezcan entre los usuarios-empresa y usuarios-empleados
						</li>
						<li>
							aquella información de los usuarios-empleados que no requiera compartirse
						</li>
					</ol>
				</p>
				<p>
					Los resultados de la información ingresada permitirán el ofrecimiento de ciertas alternativas recomendadas que, en cualquier caso, cada Usuario debe revisar y confirmar de acuerdo con sus estrategias financieras, tributarias y comerciales. Las sugerencias de Planeo son basadas en la información registrada y son generales, por lo cual, cada Usuario es responsable de su uso, liberando de toda responsabilidad a Planeo por cualquier pérdida o daño sufrido con base en las recomendaciones proveídas a través de la Plataforma Virtual.
				</p>
				<p>
					Planeo hará cuanto sea posible razonablemente para ponerle en contacto con terceros que presten servicios que puedan ser del interés del Usuario.
				</p>
				<p>
					Solo podrá acceder al servicio utilizando medios autorizados. Es responsabilidad del Usuario asegurarse de que descarga la Aplicación correcta para su dispositivo. Planeo no será responsable si no tiene un dispositivo móvil compatible o si descarga la versión errónea de la Aplicación para su dispositivo móvil. Planeo se reserva el derecho a finalizar el servicio y el uso de la Aplicación en caso de que utilice el la Aplicación con un dispositivo incompatible o no autorizado.
				</p>
				<p>
					Al utilizar la plataforma, el Usuario acuerda, asimismo, que:
				</p>
				<p>
					<ol>
						<li>Solo utilizará o descargará la Aplicación exclusivamente para su uso institucional y no la revenderá a un tercero.</li>
						<li>No autorizará a otros a usar su cuenta.</li>
						<li>No cederá ni transferirá de otro modo su cuenta a ninguna otra persona o entidad jurídica (persona jurídica).</li>
						<li>No utilizará una cuenta que esté sujeta a cualquier derecho de una persona que no sea la propia sin la autorización adecuada.</li>
						<li>
							No utilizará la Aplicación o la página Web con fines ilícitos, incluyendo, sin limitación, para enviar o almacenar ningún material
							ilegal o con fines fraudulentos.
						</li>
						<li>No utilizará la Aplicación para causar molestias, trastornos o inconvenientes.</li>
						<li>No perjudicará el funcionamiento adecuado de la red.</li>
						<li>No tratará de dañar la Aplicación de ningún modo.</li>
						<li>No copiará ni distribuirá la Aplicación ni ningún contenido de Planeo sin el permiso escrito de Planeo</li>
						<li>
							Guardará de forma segura y confidencial la contraseña de su cuenta y cualquier identificación facilitada para permitirle acceder a la
							Aplicación.
						</li>
						<li>Contará con todas las autorizaciones necesarias para la transferencia y carga de cualquier dato personal que comparta con Planeo.</li>
						<li>Facilitará a Planeo todas las pruebas de identidad que le sean razonablemente solicitadas.</li>
						<li>Solo utilizará un punto de acceso o cuenta de datos 3G (AP) que esté autorizado a usar.</li>
						<li>No utilizará el servicio o la Aplicación con un dispositivo incompatible o no autorizado.</li>
						<li>
							Cumplirá con toda la legislación aplicable de su país de residencia y del país, estado o ciudad en el que se encuentre al usar la
							Aplicación o el Servicio.
						</li>
					</ol>
				</p>
				<p>
					Planeo se reserva el derecho a terminar inmediatamente el servicio y el uso de la Plataforma Virtual en caso de que incumpla alguna de las
					reglas y obligaciones anteriores.
				</p>
				<br></br>
				<span>Pago</span>
				<p>
					Planeo le cobrará al Usuario por los servicios prestados por Planeo directamente. El Usuario acuerda pagar todos los servicios que adquiera de Planeo y que Planeo podrá cobrar, en la cuenta de la tarjeta de crédito que facilitó al registrarse para el Servicio, de ser el caso (incluyendo cualquier impuesto o tasas posteriores, según sea de aplicación) que correspondan o estén asociados a su cuenta. El Usuario será responsable del pago puntual de todas las tasas y de facilitar a Planeo una cuenta de su tarjeta de crédito para el pago de todas las tasas en todo momento. Los pagos realizados no son reembolsables.
				</p>
				<p>
					Planeo utiliza el procesador de pago de un tercero (el «Procesador de Pago») para vincular su cuenta de la tarjeta de crédito a la Aplicación y al Servicio. El procesamiento de pagos o créditos, según sea de aplicación, con respecto al uso que haga de la Aplicación y el Servicio estará sujeto a las condiciones y políticas de privacidad del Procesador del Pago y el emisor de su tarjeta de crédito además de a estos Términos y Condiciones.  Planeo no será responsable de ningún error del Procesador de Pago. En relación con su uso de los Servicios, Planeo obtendrá determinados datos de la transacción, que Planeo utilizará únicamente con arreglo a su Privacidad y a su Aviso de Cookies.
				</p>
				<br></br>
				<span>Indemnización</span>
				<p>
					Los Usuarios acuerdan eximir de responsabilidad a Planeo de cualquier demanda, pérdida, responsabilidad, reclamación o gasto (incluidos los honorarios de abogados) que terceros realicen en contra de Planeo como consecuencia de, o derivado de, o en relación con el uso de la plataforma o cualquiera de los servicios ofrecidos en la página web o aplicación.
				</p>
				<p>
					Particularmente, los Usuarios serán la única responsable de contar con las autorizaciones requeridas por las leyes de habeas data y estar autorizada para la transferencia de la información que considere a Planeo. En este sentido, los Usuarios acuerdan indemnizar y eximir de responsabilidad a Planeo de cualquier demanda, pérdida, responsabilidad, reclamación o gasto (incluidos los honorarios de abogados) que terceros realicen en relación con los datos personales de terceros suministrados a la Plataforma
				</p>
				<br></br>
				<span>Limitación de Responsabilidad</span>
				<p>
					Al aceptar estos Términos y Condiciones y al utilizar la Aplicación o el Servicio, el Usuario acuerda que defenderá, indemnizará y mantendrá indemne a Planeo, sus filiales, licenciatario, directivos, directores, otros usuarios, trabajadores, abogados y agentes ante cualquier reclamación, costes, daños, pérdidas, responsabilidades y gastos (incluyendo honorarios y gastos de abogados) derivados de o en relación con:
				</p>
				<p>
					<ol>
						<li>
							Su violación o incumplimiento de cualquier condición de estos Términos y Condiciones o cualquier ley o reglamento aplicable, se haga o
							no referencia al mismo en el presente; y/o
						</li>
						<li>Su violación de cualquier derecho de cualquier tercero, incluyendo el personal que presta el servicio, y/o</li>
						<li>Su uso correcto o incorrecto de la Aplicación o el Servicio; y/o</li>
						<li>Cualquier responsabilidad por la violación del régimen de datos personales por los suministrados de su nómina y de sus empleados.</li>
					</ol>
				</p>
				<span>Modificación</span>
				<p>
					Planeo podrá modificar autónomamente y en cualquier momento en aspectos formales, procedimentales o sustanciales los presentes Términos y Condiciones de uso de la Plataforma Virtual, los cuales serán actualizados y puestos a disposición de los Usuarios en la Plataforma Virtual, siendo la última versión publicada la que regulará las relaciones comerciales que se generen al momento de realizarse la transacción. Así mismo, cuenta con plena autonomía para modificar los usos de la Plataforma Virtual permitidos a los Usuarios, con el único deber de informarlo por un medio virtual que permita su publicación y comunicación al público.
				</p>
				<br></br>
				<span>Alcance y nivel de servicio </span>
				<p>
					Planeo adelantará las gestiones que a su juicio estime conducentes, para que la plataforma esté disponible para los usuarios, pero no garantiza lo anterior, por cuanto pueden existir eventos como daños en las comunicaciones, actos de terceros, mantenimiento o reestructuración de la aplicación, entre otros, que escapen al control y responsabilidad de Planeo. No obstante, Planeo garantiza a los usuarios que la aplicación estará disponible en circunstancias normales siempre y cuando el usuario recuerde las credenciales de su cuenta y las condiciones de prestación del servicio de conexión a Internet por parte de cada proveedor, lo permitan.
				</p>
				<p>
					De manera tal que, si no se pudiera acceder por el tipo de conexión o por el deficiente servicio del mencionado proveedor, ello no implica en ningún momento incumplimiento de la prestación del servicio prestado por parte de Planeo.
				</p>
				<br></br>
				<span>Manejo de información</span>
				<p>
					La información recolectada por Planeo para el uso de la Plataforma Virtual, es suministrada por los Usuarios de forma libre y voluntaria, para que esta sea administrada por Planeo o por quien éste designe para el cumplimiento de los deberes adquiridos, lo que implica su recolección; almacenamiento en servidores o repositorios de Planeo o de terceros; circulación de los mismos dentro de la organización de Planeo; comunicación a los Usuarios de información comercial, publicitaria y de mercadeo relacionada con su actividad comercial.
				</p>
				<p>
					Así mismo, los datos recolectados serán objeto de análisis para fines de mejorar la estrategia de negocios del portal web, apoyada en herramientas de inteligencia de negocios y minería de datos, que permiten adquirir conocimientos prospectivos para fines de predicción, clasificación y segmentación.
				</p>
				<p>
					Los Usuarios podrán ejercer su derecho de conocer, actualizar, modificar y suprimir los datos personales existentes en las bases de datos asociadas a la Plataforma. Para ello deberá realizar la solicitud de consulta, reclamo o supresión a la dirección electrónica {' '}
					<a href='mailto:habeasdata@mareigua.co' target='_blank' rel='noreferrer'>
						habeasdata@mareigua.co
					</a>{' '}
					detallando las modificaciones a realizar y aportando los documentos que lo soportan.
				</p>
				<p>
					Planeo es responsable del tratamiento de la información personal recolectada a través del portal web, responsabilidad que podrá delegar en un tercero, en calidad de responsable o encargado de la información, asegurando contractualmente adecuado tratamiento de la misma. La política de datos personales podrá ser consultada en los enlaces destinado para tal fin dentro de la plataforma al momento de registrarse o en la sección de empleado una vez estén registrados los usuarios.
				</p>
				<p>
					Con la aceptación de los presentes Términos y Condiciones de Uso de la Plataforma, el Usuario autoriza expresamente a Planeo a (i) consultar la información de la Empresa Usuaria en centrales de información financiera o burós de crédito; (ii) consultar la información de la Empresa Usuaria en entidades operadoras de información que administren información laboral, financiera y de seguridad social de empleados y/o empleadores; y (iii) en caso que los empleados lo acepten, consultar información sobre los aportes y saldos de los empleados de la Empresa Usuaria a fondos de pensiones y/o cesantías.
				</p>
				<br></br>
				<span>Propiedad Intelectual</span>
				<p>
					El Servicio y todos los materiales incluidos o transferidos, incluyendo, sin limitación, software, imágenes, texto, gráficos, logotipos, patentes, marcas registradas, marcas de servicio, derechos de autor, fotografías, audio, videos, música y todos los Derechos de Propiedad Intelectual relacionados con ellos, son la propiedad exclusiva de Planeo. Salvo que se indique explícitamente en este documento, no se considerará que nada en estos Términos crea una licencia en o bajo ninguno de dichos Derechos de Propiedad Intelectual, y la Empresa Usuaria acepta no vender, licenciar, alquilar, modificar, distribuir, copiar, reproducir, transmitir, exhibir públicamente, realizar públicamente, publicar, adaptar, editar o crear trabajos derivados de los mismos.
				</p>
				<br></br>
				<span>Domicilio y legislación aplicable</span>
				<p>
					Los presentes Términos y Condiciones de Uso de La Plataforma se acogen en el territorio colombiano, conforme a su normatividad general y sectorial. Su adopción implica el ejercicio de su libre voluntad y que la relación que surge de este documento se regirá en todos sus efectos por su contenido y en su defecto por la ley comercial colombiana.
				</p>
				<br></br>
				<span>Aceptación total de los términos</span>
				<p>
					La Empresa Usuaria manifiesta expresamente tener capacidad legal para usar La Plataforma y para celebrar las transacciones comerciales que se puedan generar. Así mismo, manifiesta haber suministrado información real, veraz y fidedigna; por ende, de forma expresa e inequívoca declara que ha leído, que entiende y que acepta la totalidad de las situaciones reguladas en el presente escrito de Términos y Condiciones de Uso de la Plataforma, por lo que se compromete al cumplimiento total de los deberes, obligaciones, acciones y omisiones aquí expresadas.
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Cerrar</Button>
			</Modal.Footer>
		</Modal>
	);
};
